

.box_container{
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;
    }
    
    .first_box{
        display: flex;
        width: 104px;
        height: 104px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    }
    
    .first_box_img{
    display: flex;
    width: 104px;
    height: 104px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    }
    
    .second_box{
        width: 104px;
        height: 104px;
        border-radius: 2px;
    border: 1px solid var(--Neutral-5, #D9D9D9);
    }
    
    .second_box_img{
        display: flex;
        width: 104px;
        height: 104px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: 1px solid var(--Neutral-5, #D9D9D9);
        }
    .third_box{
        display: flex;
        width: 104px;
        height: 104px;
        padding: 40px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 2px;
    border: 1px dashed var(--Neutral-5, #D9D9D9);
    background: var(--Neutral-2, #FAFAFA);
    }
    .third_box_img img{
    margin-left: 18px;
    
    }
    
    .third_box_img h4{
        color: var(--character-secondary-45, var(--Color-Neutral-Text-colorTextDescription, rgba(0, 0, 0, 0.45)));
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .file-upload-container {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: end;
    }

    .uploaded-files-button {
        padding: 0px;
    }

    .customer-uploaded-files-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 150px;
      }

    .docs-uploaded-container {
        margin-top: 10px;
        color: blue;
    }