.Loan_summary_first_container {
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.Loan_summary_content {
    color: #00000073;
}

.legal_valuation_second_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2 0px;
}


.legal_heading {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;

}

.legalvaluation {
    width: 216px;
    color: #389E0D;
    margin-left: 15px;
    display: flex;
    background: #F6FFED;
    border-radius: 16px;
    align-items: center;
    font-family: 'Roboto', ;
    font-weight: '500';
    word-wrap: 'break-word';
}

.legal_valuation_second_container {
    margin-top: 15px;
}

.loan_summary_second_container_heading_two {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #000000B2;
}


.loan_summray_container {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 36px;

}

.Details_text {
    margin: 0px 0px 10px 20px;
}

.loan_summary_table_data_text {
    margin-bottom: 10px;
    display: flex;
}

.loan_summary_table_one {
    border: 1px solid #b8b3b373;
    /* border: 1px solid red; */
    border-radius: 8px;
    width: 442px;
    min-width: 300px;
    flex: 1;
    /* height: fit-content; */
    /* height: auto; */
}

.Laon_summary_table_two {
    border: 1px solid #b8b3b373;
    border-radius: 8px;
    width: 700px;
    height: auto;
    top: 214px;
    left: 59px;
    min-width: 300px;
    flex: 1;

    gap: 0px;

}




.loan_summary_one_heading {
    border-bottom: 1px solid #b8b3b373;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    margin: 10px 0px 0px 0px;

}

.loan_summary_table_data {
    border-bottom: 1px solid #b8b3b373;
    margin: 10px 0px 10px 0px;

}

.loan_summary_table_data_two {
    display: flex;
    margin-bottom: 10px;

}

.coapplicant_customer_loan_summary {
    margin-bottom: 10px;
    display: flex;
    border-top: 1px solid #b8b3b373;
}

.rcu_guarantor_loan_summary {
    margin-bottom: 10px;
    display: flex;
}

.rcu_coapplicant_block {
    border-top: 1px solid #b8b3b373;
    margin-bottom: 10px;
}

.rcu_customer_loan_summary {
    margin-bottom: 10px;
    display: flex;
    border-top: 1px solid #b8b3b373;
}

.rcu_customer_loan_summary:first-child {
    border-top: none;
    /* Remove the border-top for the first element */
}

.doubleLeftOutlined_icon {
    color: blue;
    font-size: 15px;
}

.loan_summary_table_mail_data_value {
    word-break: break-word;
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000B2;
    margin-left: 100px;

}



.loan_summary_table_data_heading {
    /* border: 1px solid red; */
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000080;
    margin-left: 20px;
}

.loan_summary_table_data_value {
    /* border: 1px solid red; */
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000B2;
    margin-left: 100px;

}

.loan_summary_img_table_data {
    /* border-bottom: 1px solid #b8b3b373; */
    /* border: 1px solid red; */
    margin: 10px 0px 10px 0px;

}

.loan_summary_img_data_value {
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000B2;
    margin-left: 100px;

    display: flex;
    gap: 10px;
    flex-wrap: wrap;

}

.loan_summary_pdf_img {
    border: none;
    cursor: pointer;
}

.img_loan_summary_block {
    cursor: pointer;
    margin-right: 10px;
}

.loan_summary_no_image {
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    text-align: center;
    line-height: 50px;
    border-radius: 4px;
}

.loan_summary_attachment_img {
    cursor: pointer;
    font-size: 24px;
    line-height: 50px;
    padding: 0 10px;
    color: #000;
}

.loan_summary_img_data_heading {
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000080;
    margin-left: 20px;
}


/* ***************************************
 */

.loan_summary_heading_block {
    margin-left: 3px;
}

.loan_summary_technical_valuation_report {
    font-family: Roboto;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
}