.pdc_details_table {
    padding: 10px 10px 10px 10px;
}

.pdc_container {
    border: 1px solid #e4e7ec;
}

.pdc_detail_heading {
    background: #E6F7FF;
    height: 46px;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.pdc_detail_heading.no-border {
  border-bottom: none;
}



.pdc_description_data {
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

.table_content {
  border: 1px solid #E4E7EC;
  border-top: none;
  border-bottom: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table_content .ant-table {
    border-radius: 10px !important;
  }

  .table_content .ant-table-thead > tr > th {
    border-radius: 0 !important;
  }
  
  .no-radius-table .ant-table-container {
    border-radius: 10px !important;
  }



  .nach_detail_heading{

    /* background: #E6F7FF; */
    /* border: 1px solid #E4E7EC; */
    height: 46px;
    display: flex;
}

  .nach_heading{
    margin-left: 5rem;
    margin-top: 17px;
  }

  .application_serch{
    margin-left: 3rem;
    margin-top: 10px;
  }
  .search_button{
    margin-left: 1rem;
    margin-top: 10px;
  }

  .view_button{

    margin-left:1rem;
    margin-top: 10px;
  }

  .active-account-row {
    background-color: #f8d8d8; /* Adjust color as needed */
  }

  .bank-account-container {
    margin-top: 24px;
  }

  .nach-select-customer {
    width: 300px;
  }

  .nach-change-account-container {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-right: 15px;
  }

  .nach-other-account-button {
    background: orange;
    color: white;
    margin-left: 10px;
  }

  .nach-form-button {
    background: hsla(204, 57%, 36%, 1) !important;
    color: white !important;
  }

  .nach-document-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px !important;
  }

  .nach-form-row-container {
    margin-bottom: 16px;
  }

  .nach-upload-button {
    width: 176% !important;
  }

  .nach-images-container {
    margin: 10px 10px 10px 10px 
  }

  .nach-submit-container {
    display: flex;
    flex-direction: row-reverse;
  }
  
  .nach-document {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }

  .nach-delete-document {
    color: #E5233DB2;
    cursor: pointer;
    text-align: center;
    margin-top: 10px,
  }
  
  .nach-document-image-container {
     margin-right: 15px;
     position: relative;
  }

  .nach-document-wrapper {
    display: flex;
    flex-wrap: wrap;
  }