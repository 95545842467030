/* Layout styles */
.layout {
    padding: 10px;
    background-color: #f0f5ff !important;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  /* Header styles */
  .header {
    background: #FFFFFF;
    padding: 16px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    width: 101%;
    text-align: left;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  /* Typography styles */
  .title {
    margin: 0;
    color: #27658F;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: clamp(20px, 2vw, 24px);
    line-height: 1.2;
  }
  
  .text {
    color: #27658F;
    font-family: Poppins, sans-serif;
    font-size: clamp(16px, 1.5vw, 18px);
  }
  
  .sub_text {
    color: #595959;
    font-size: clamp(14px, 1.2vw, 16px);
    line-height: 1.5;
    display: block;
    margin-top: 4px;
  }
  
  /* Image styles */
  .dashboard_image {
    width: clamp(80px, 10vw, 100px);
    height: auto;
    border-radius: 50%;
    margin: 0;
    flex-shrink: 0;
  }
  
  /* Content styles */
  .dashboard_content {
    margin-top: 18px;
    color: rgb(39, 101, 143);
    width: 101%;
  }
  
  /* Card styles */
  .dashboard_card,
  .dashboard_card_schedule {
    color: rgb(39, 101, 143);
    font-family: Poppins, sans-serif;
    font-size: clamp(16px, 1.5vw, 20px);
    font-weight: 400;
    margin-bottom: 16px;
    height: auto;
  }
  
  .dashboard_card_div {
    max-width: 100%;
    overflow: hidden;
    padding: 0 15px;
  }
  
  /* Calendar styles */
  .dashboard_calender {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
  
  .dashboard_calender_text {
    font-size: clamp(14px, 1.2vw, 16px);
  }
  
  /* Table styles */
  .dashboard_task_table {
    width: 100%;
    overflow-x: auto;
  }
  
  .custom-table {
    width: 100%;
  }
  
  .routeToLoanDetail {
    cursor: pointer;
    padding: 8px 0;
  }
  
  /* Media queries for responsive design */
  @media screen and (max-width: 768px) {
    .layout {
      padding: 8px;
    }
    
    .header {
      padding: 12px 16px;
    }
    
    .dashboard_card_div {
      padding: 0 8px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .header {
      flex-direction: column;
      text-align: center;
      padding: 12px;
    }
    
    .dashboard_image {
      margin-top: 12px;
    }
    
    .dashboard_calender {
      justify-content: center;
    }
  }
  
  /* Adding smooth transitions */
  .header,
  .dashboard_image,
  .title,
  .text,
  .sub_text {
    transition: all 0.3s ease-in-out;
  }