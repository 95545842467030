.full-screen-loader-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
