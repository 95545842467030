.rv_view_container {
    margin: 10px;
}

.fi_description_heading {
    /* border-bottom: 1px solid #E4E7EC; */

    background: #E6F7FF;
    height: 46px;
    display: flex;
    justify-content: space-between;
}
.fi_description_heading_two {
    border: 1px solid #E4E7EC;

    background: #E6F7FF;
    height: 46px;
    display: flex;
    justify-content: space-between;
}
.asset_conatianr{
    margin: 10px;
    border: 1px solid #e4e7ec;
}
.ov_views_edit_button {
    display: flex;
    align-items: center;
  

}
.ov_views_collapse_icon {
 margin-top: 12px;
 margin-right: 14px;
 margin-left: 12px;
    cursor: pointer;
    
}

.asset_conatianr_seocnd{
    display: flex;
  
}
.fi_description_data {
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}


.fi_description_basic_card_container {
    display: flex;
    /* border: 1px solid #e4e7ec; */
}

.fi_description_basic_card {
    width: 30%;
    margin-bottom: 30px;

    flex-wrap: wrap;
}

.fi_description_basic_card_label {
    color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}


.fi_description_basic_card_data {
    margin-left: 12px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;


}

.fi_description_basic_card {
    width: 30%;
    margin-bottom: 30px;
}

.occupation_ov {
    padding: 10px;
}

.occupation_div_container {
    border: 1px solid #E4E7EC;
}

.edit_button_ov {
    display: flex;
    align-items: center;
}

.ov_toggele {
    margin: 10px;
}
.status-text {
    margin-left: 5px;
  }
  
  .status-text.posi {
    color: #389E0D; /* Positive color */
  }
  
  .status-text.nega {
    color: #FF4D4F; /* Negative color */
  }
  