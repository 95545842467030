.document_input_container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;

}

.document_input_container_two {

  flex: 1 1 400px;
  max-width: 100%;
   border: 1px solid #E4E7EC;
   border-radius: 5px;
}
.document_input_container_vehicle {
  background: #E6F7FF;
  /* border: 1px solid #E4E7EC; */
  height: 46px;
  display: flex;
  justify-content: space-between;
}



.identification_documnent_add {
  display: flex;
}

@media (max-width: 850px) {
  .document_input_container {
    flex-direction: column;
    flex-wrap: wrap;
  }
}


.positive {
  text-align: center;
  font-family: Roboto;
  color: #389E0D;
  /* background-color: #F2F9F2; */
  border-radius: 16px;
  width: 100px;
  height: 22px;
  top: 12px;
  margin-left: 3px !important;
}

.edit_iccon_fi_documnet {
  color: #4880FF;
}

.delet_icon_fi_document {
  color: #E5233DB2;
}

.fi_document_form_coantiner {
  margin-top: 20px;

}

.negative {
  text-align: center;
  font-family: Roboto;
  color: #B32318;
  background-color: #FEF3F2;
  border-radius: 16px;
  width: 100px;
  height: 22px;
  top: 12px;
  left: 25px;

}

.fi_document_toggle_input {
  margin: 10px;
}


.identification_documnet_table {
  /* border: 1px solid #E4E7EC; */
  max-width: 100%;
  overflow-x: auto;

}