.main_part_disbursal_details_container{
 margin-left: 0.5rem;
}

.main_disbursal{
    border: 1px solid rgb(228, 231, 236);
    border-top: none;
    border-radius: 5px;
}
.part_disbursal_tabs{
    border: 1px solid rgb(228, 231, 236);
    display: flex;
    height: 46px;
}

.part_disbursal_container{
    display: flex;
    justify-content: space-between;
}

.part_disbursal_card1{
    gap: 12px;
    height: auto;
    margin-top: 9px;
    width: 32%
}

.part_disbursal_image{
    display: flex;
}

.part_disbursal_image {
    display: flex;
    flex-wrap: wrap; /* This will allow wrapping of images if they exceed the container width */
    gap: 10px; /* 3px gap between images */
}

.part_disbursal_card1_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.part_disbursal_card1_image img {
    margin-bottom: 3px; /* Margin between images */
}

.tab_container{
    padding: 16px 16px;
}

/* ________________________ */
.part_disbursed_tabs_content{
    margin-top: 1rem;
    margin-left: 1rem;
}

.part_disbursed_tabs_last_content{
    margin-bottom: 2rem;
}


.default-box {
    width: 85px;
    height: 100px;
    cursor: pointer;
}

.thumb-box {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.part_disbursment_tables_content{
    padding-top: 20px;
}