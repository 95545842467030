/* common css */
.email_div_container {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  margin-top: 20px;
}

.email_div_container {

  width: 100%;
  margin-bottom: 20px;
  /* Optional, for spacing between containers */
}

.select_field {
  width: 100%;

}

.fi_button_inition {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.upload_button {
  border: 0;
  background: none;
}

.checkbox_content {
  margin: 5px;
}


.content_block {
  display: flex;
  flex-direction: row;
}

.email_heading {
  width: 23%;
  padding: 10px;
  /* color: #000000B2; */
  border-right: 1px solid #D9D9D9;
}

.email_content {
  padding: 10px 10px 0px 10px;
  width: 77%;
}

.initiation_form_table_one {
  margin-top: 10px;
  border: 1px solid #b8b3b373;
  border-radius: 8px;
}

.button_inition_form {
  display: flex;
  flex-direction: row-reverse;
}


.initiation_form_table_data {
  border-bottom: 1px solid #b8b3b373;
  display: flex;
  flex-direction: row;
  /* margin:10px 0px 10px 0px ; */
}

.initiation_form_table_data_heading {
  width: 35%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  text-align: left;
  color: #000000B2;
  margin-left: 8px;
  border-right: 1px solid #D9D9D9;
}

.initiation_form_table_data_value {
  padding: 10px;
  width: 65%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #000000B2;
  margin-left: 8px;

}

.initiation_form_select_width {
  width: 100% !important;
}

.initiate_button {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
.checkbox-button{
  display: flex;
  margin-top: 5px;
}