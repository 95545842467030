.loan_insurance_container {
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    margin: 10px;

}

.Basic_card {
    width: 30%;
    margin-bottom: 20px;

}
.width_select_pincode{
    width: 100% !important;
}

.address_of_property {

    overflow-x: auto;
}

.address_property_technical {
    display: flex;
    justify-content: space-between;
    height: 39px;
    background-color: rgba(241, 241, 241, 1);
}

.address_text_tech {
    color: red;
}

.address_property_technical_text {
    margin-right: 10px;
    margin-top: 10px;
    color: rgba(231, 76, 60, 1);
}

.plot_text_technical {
    text-align: end;
    color: rgba(231, 76, 60, 1);
    margin-top: 10px;

}

.checkbox_address_technical {
    margin-left: 5px;
}

.technical_plan_approval {

    width: 30%;
    margin-bottom: 20px;

}

.loan_details_heading {
    color: #000000B2;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
}


.technical_views_form_Remark


.technical_views_property_remark_block{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;

}

.technical_remark_bolck{
    margin-top: 10px;
    margin-bottom: 10px;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    padding: 10px;
}
.property_remark_bolck{

    padding: 10px;
}



.adddres_technical_form_row {
    margin-bottom: 16px;
    margin-top: 20px;
}

.addres_technical_docket_text {
    display: flex;
    justify-content: space-between;
    height: 39px;
    background-color: rgba(241, 241, 241, 1);
    margin-top: 3%;
}

.address_tech_actual_site {
    margin-left: 10px;
    margin-top: 10px;
}

.nominee_details {

    display: flex;
    flex-direction: row;

}


.technical_plan_approval_card_content {
    gap: 8px;
    height: auto;
    margin-top: 9px;


}


.loan_details_heading {
    border-top: 1px solid #E4E7EC;
    border-bottom: 1px solid #E4E7EC;
    background: #E6F7FF;
    border-right: none;
    font-weight: 600;
    padding: 10px;
}

.insurance_table {
    border-radius: none !important;
    overflow-x: auto;
}

.insurance_table td {
    color: #585858 !important;
}

.insurance_table thead th {
    color: #667085 !important;
    font-size: 14px;
    border-radius: 0px !important;
}

.insurance_btn {
    border: 1px solid #1677FF;
    background-color: white;
    color: #1677FF;
}

.tab_form_charges_main_data_table {
    width: 360px;
    padding: 12px;
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    background: #FFF;
    border-bottom: 1px solid #E4E7EC;

}




.Basic_details_container {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #E4E7EC; */
    /* margin: 0 16px 8px; */
    border-top: none;
}

.loan_insurance_details_container {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #E4E7EC; */
    /* margin: 0 16px 8px; */
    border-top: none;

}

.Basic_card {
    width: 30%;
    margin-bottom: 20px;
}

.basic_card-content {
    gap: 8px;
    height: auto;
    margin-top: 9px;
}

.tab_from_heading_edit_btn {
    width: 60px;
    height: 22px;
    padding: 2px 8px;
    border: 1px solid rgb(187, 186, 186);
    border-top: none;
}




.gray_cell {

    background: rgba(241, 241, 241, 1);




}

.remark {
    background: rgba(241, 241, 241, 1);
    margin-top: 3%;
}
.prop_remark {
    background: rgba(241, 241, 241, 1);
    margin-top: 1%;
}



.remark_cell {
    display: flex;
    justify-content: space-between;
}

.property_details {
    display: flex;
    justify-content: space-between;
}

.tab_form_sub_map {
    display: flex;
    width: 57px;
    padding: 2px var(--Size-Padding-paddingXS, 8px) 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 16px;
    background: rgba(230, 247, 255, 1);
    margin-left: 20px;
}


.tab_form_sub_map img {
    height: 14px;
    flex-shrink: 0;
    stroke-width: 1px;
    color: #667085;
}

.tab_form_sub_map span {
    color: #626C80;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.tab_form_map_map_button {
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin: -5px;
}



.declaration {
    margin-left: 0.5%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
}

.flextabs {
    border-bottom: 1px solid #e4e7ec !important;
}

.location_Property_details {

    display: flex;
    /* justify-content: space-between; */
    flex-wrap: nowrap;
    gap: 16px;
    /* Space between cards */

}

.selct_field_technical_road {
    margin-top: 125px;
}

.selct_field_technical_roads {
    margin-top: 25px;
}

.technical-property-high-tension {
    margin-top: 22px;
}

.technical-form-locality-type {
    margin-top: 65px;
}

.location_Property_card {
    width: 25%;
}

.technical-sitauated-canal {
    margin-top: 20px;
}

.address {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #FAAD14;
    margin-left: 5px;
}

/* Data Not found */
.no_data_found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}

.div {
    display: 'flex';
    justify-content: space-between;
}

.technical_lable {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
}

.technical_lable_remark {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
   margin-top: 12px;
}


.technical_lable_two {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    min-height: 100px;
}

.technical_lable_three {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    min-height: 60px;
}

.technical_lable_one {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    min-height: 63px;
}

.property_details_select {
    width: 100%;
}

.text_propert_technical {
    resize: none !important;
}

.cancel_button_property_technical {
    display: flex;
    flex-direction: row-reverse;
}

.technical_data {
    color: #000000b3;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0;
}

.cancel_plan_button {
    display: flex;
    flex-direction: row-reverse;
}

.empty_data_div_technical {
    margin-left: 30rem;
}

.boundries_details_container{
    border-bottom: none;
}