.dependent_main_container {
  overflow-x: auto;
  max-width: 100%;
}

.dependent-row td {
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  background: #e6f7ff;
  border-right: none;
  font-weight: 600;

}
.dependent_deatils_heading{
  display: flex;
  justify-content: flex-end;

}
.verify_img_depedenet{
  margin-left: 5px;
}

.dependent_form_fields_width{
  width: 100%;
}

.dependent_form_country_code
{
  width: 20% !important;
  text-align: center !important;
  background: #f1f1f1 !important;
}

.dependent_form_number_field{
  width: 80% !important; 
}