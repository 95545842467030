.reference-row td {
  background:#E6F7FF;
  border-right: none;
  font-weight: 600;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
}

.reference-pd-row td {
  background: #f9fafb;
  border-right: none;
  font-weight: 600;
}

.second_reference_div {
  width: 360px;
}

.second_reference_heading {
  width: 220px;
}
.refernace_deatils_heading{
  display: flex;
  justify-content: flex-end;

}
.verify_img_reference{
  margin-left: 5px;
}


.reference_highlighted_header {
  background-color: #ffffee;
}
.select_refernce{
  width: 100%;
}


.ref_country_code_block {
  width: 20% !important;
  text-align: center !important;
  background: #f1f1f1 !important;
}

.ref_mobile_number_field {
  width: 80% !important;
}