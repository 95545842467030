.dedupe_main_container {
    border: 1px solid #E4E7EC;
    border-radius: 8px;
    background-color: #FFFFFF;
    top: 1017px;
    left: 58PX;
    gap: 8px;
    margin-bottom: 20px;
    margin: 10px;
}

.dedupe_heading {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    margin-left: 15px;
    margin-top: 10px;

}

.dedupe_heading_button_one {
    background-color: #4880FF;
    border-radius: 6px;
    height: 35px;
    color: #FFF;
    margin-right: 10px;
}

.dedupe_heading_button_two {
    border-radius: 6px;
    color: #4880FF;
    height: 35px;

}

.dedupe_card_container {
    display: flex;
    flex-wrap: wrap;
    /* gap: 10px; */
    margin-left: 15px;
}
.flex_container_dedupe {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin :10px 12px 0px 0px;
}
.loan_application_progress_dedupe {
    /* border: 1px solid #E4E7EC; */
    margin-bottom: 20px;
    /* background: #FFF; */
    border-radius: 6px;
}
.loan_summary_heading {
    color: #667085;
    margin: 13px 0px 13px 9px ;
    font-size: 17px;
    font-family: 'Roboto';
    font-weight: 500;
    word-wrap: break-word;
}
