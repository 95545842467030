.fraud_highlighted-row td {
    width: 360px;
    background: #E6F7FF;
    border-right: none;
    /* color: var(--character-primary-85, rgba(0, 0, 0, 0.85)); */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    border-top: 1px solid #E4E7EC;
    border-bottom: 1px solid #E4E7EC;
}


.froud-row {
    border-top: 1px solid #e4e7ec;
    border-bottom: 1px solid #e4e7ec;
    background-color: red;
    border-right: none;
    font-weight: 600;
}


.fraud_highlighted-row .tab_form_left_heading {
    width: 219px;
    background: #E6F7FF;
    border-right: none;
    /* color: var(--character-primary-85, rgba(0, 0, 0, 0.85)); */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}

.froud_tab_form_left_heading {
    width: 263px !important;
    min-width: 263px !important;
    max-width: 263px !important;
    position: sticky;
    left: 0;
    border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
    background: hsla(210, 20%, 98%, 1);
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    z-index: 1;
    /* border-right: 1px solid rgb(245, 239, 239); */
}

.verify_img_fraud_check {
    margin-left: 5px;
}

.fraud_check_icon {
    color: #71797E !important;
}