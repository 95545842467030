.tabs-container {
    width: 90%;
    max-width: 900px;
    margin: 50px auto; 
    margin-top: 0px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    border: 1px solid #e0e0e0;
  }
  
  .tabs-container .ant-tabs {
    margin-bottom: 0;
  }
  
  .tabs-container .ant-tabs-tab {
    font-weight: 500;
  }
  
  .tabs-container .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1890ff;
  }
  
  .tabs-container .ant-tabs-ink-bar {
    background-color: #1890ff;
  }
  