/* Legal Input Details */
.loan_summary_legal_valuation_report{
    font-family: Roboto;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
 }
.legal_input_valuation_report{
    display: flex;
    justify-content: space-between;
}

.legal_input_space{
    display: flex;
    justify-content: space-between;
}
.postdisbursement_input{
   
    width: 100% !important;
}
.more_button_postdisburment{
    margin-top: 20px;

}
.button_submit_cancle_legal_input{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}
.deleteDisabled {
    color: #ccc;
    margin-bottom: 25px;
    cursor: not-allowed;
  }
  
  .deleteEnabled {
    color: #E5233DB2;
    margin-bottom: 25px;
    cursor: pointer;
  }
  

.legal_input_valuation_report_heading{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 15px;
    padding-top: 8px;
}

.legal_input_valuation_report_divider{
    margin: 0px;
}

.legal_input_valuation_report_tab{
    margin: 20px;
}

/* Legal Valuation Input Form css */
/* .legal_input_details_container{
    margin: 10px;
    border:1px solid green;
} */


.legal_input_heading{
    background: #E6F7FF;
    border: 1px solid #E4E7EC;
    height: 46px;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid green; */
}

.legal_views_property_heading_block{
    background: #E6F7FF;
    border-left: 1px solid #E4E7EC;
    border-right: 1px solid #E4E7EC;
    border-top: 1px solid #E4E7EC;
    height: 46px;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid green; */
}


.legal_views_property_picture{
    background: #E6F7FF;
    /* border-left: 1px solid #E4E7EC; */
    /* border-right: 1px solid #E4E7EC; */
    border-top: 1px solid #E4E7EC;
    height: 46px;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid green; */
}


.legal_views_property_block{
border-bottom: none !;
}

.legal_input_heading_name{
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}
.legal_input_button_space{
    display: flex;
    /* border: 1px solid green */
}
.legal_input_edit_button{
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.legal_input_collapse_icon{
    margin:12px 10px 10px 1px ;
    cursor: pointer;
}

.legal_input_add_button{
    width: 130px;
    height: 30px;
    margin: 7px 8px 0px 100px;
    padding: 4px 15px 4px 15px;
    gap: 8px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background: #F0AD4E;
    color: #fff;
    cursor: pointer;
}

/* ************************************* */
.legal_input_property_description_container{
    border: 1px solid #e4e7ec;
    border-radius: 5px;
  
  }
.legal_input_blue_heading_block{
    background: #E6F7FF;
    height: 46px;
    display: flex;
    justify-content: space-between;
 
}

.legal_input_blue_heading_block{
    background: #E6F7FF;
    height: 46px;
    display: flex;
    justify-content: space-between;
}

/* ******************************** */

.legal_initiate_input_main_container{
    border:1px solid #E4E7EC;
    margin-top: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
}


.legal_initiat_input_blue_heading_block_description_heading{
    background-color: rgb(230, 247, 255) !important;
    height: 46px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}


.legal_initiat_input_document_detail_heading{
    background: #E6F7FF;
    /* height: 46px; */
    display: flex;
    justify-content: space-between;
}
