.main_property_details_container {
    display: flex;
    flex-direction: column;

    /* width: 97.3%; */
}

.property_deatails_container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E4E7EC;
    flex-wrap: wrap;
    /* margin: 0 15px 9px; */
    border-top: none;

}
.image_propert_tab{
    margin: 10px;
}
.row_form_property{
    margin-bottom: 16px;
    /* border: 1px solid red; */
}
.property_tab_img{
    border: none;
}
.proprty_documnet{
    width: 100px;
    height: 100px;

}
.proprty_documnet{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 60%;
    gap: 10px;

}
.delect_property_tab{
    cursor: pointer;
    display: flex;
    align-items: center;
   
}
.slect_property_field{
    width: 100% !important;
}
.property_image_img{
    margin-right: 10px;
}
.picture_heaindg_name{
    color: #1890FF !important;
    margin: 0;
}

.row_form_property_document{
    margin-bottom: 16px;
    margin-top: 16px;

}
.property_details_img {
    width: 100px;
    height: 100px;
    cursor: pointer;

}

.property_div_img {
    flex: 0 0 11.1%;
    box-sizing: border-box;
    padding: 5px;
 

}
.property_details_form_row{
    /* border: 1px solid red; */
    margin-bottom: 16px;
}
.property_button{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row-reverse;
}
.documnet_heading_div{
    margin-top: 10px;
    margin-left: 10px;
  
    padding: 3px;
}
.image_property_resposnsive{
    display: flex;
    flex-wrap: wrap;
}
.property_image_ppoasition{
    margin-right: 15px;
    position: relative;
}
.propert_button_onnext{
    height: 30px;
    background-color: #F1F1F1;
}
.delete_property_icon{
    color: #E5233DB2;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}
.property_documnet_img{
    width: 100px;
    height: 100px;
    border: none;
 

}
.document_picture_heading{
    text-align: center;

    color:#1890FF;
}
.property_thumb_img{
    text-align: center;
    width: 100px;
    margin-top: 10px;
}

.property_edit_container {
    display: flex;
    justify-content: flex-end;
    margin-right: 7px;
    margin-top: 8px;
}

.proprty_details_container {
    display: flex;
    justify-content: space-between;
    border-top: none;
    flex-wrap: wrap;
    /* Allows the cards to wrap to the next line */

}

.Property_card {
    flex: 1 1 20%;
    /* Flex-grow: 1, Flex-shrink: 1, Basis: 30% */
    margin-bottom: 20px;
    box-sizing: border-box;
    /* Ensure padding/margin is included in width */
    min-width: 220px;
    /* Set a minimum width to prevent cards from getting too small */
}

.property_card_content {
    gap: 8px;
    height: auto;
    margin-top: 9px;
}

.property_details_label {
    color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.property_details_data {
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.property_images {
    display: flex;
    flex-wrap: wrap;


}




.document {
    margin-top: 10px !important;

}