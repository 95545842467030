.receipt_image_view {
    width: 50px;
    height: 60px;
    overflow: hidden;
}

.receipt_image_pointer {
    width: 100%;
    cursor: pointer;
    object-fit: cover;
}

.status-completed {
    color: green;
}

.status-pending {
    color: orange;
}

.status-rejected {
    color: red;
}

.receipt_heading {
    display: flex;
    justify-content: space-between;
}


/* Receipt form */
.upload_button {
    /* margin-top: 5px; */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.image_view_form {
    text-align: left;
    margin-bottom: 50px;

}

.image_view_form_pointer {
    width: 200px;
    height: auto;
    cursor: pointer;
}

.datepicker_field {
    width: 100%;
}

.receipt_form_list {
    position: relative;
    margin-bottom: 16px;
}

.receipt_delete_button {
    color: red;
    position: absolute;
    right: 201px;
    z-index: 1;
}

.receipt_form_list_div {
    display: flex;
}

.receipt_form_list_gap {
    margin-bottom: 0;
}

.receipt_form_list_charges_amount {
    margin-left: 18px;
    margin-bottom: 0;
}

.receipt_file_list_error {
    color: red;
}

.receipt_add_charges_button {
    margin-top: 18px !important;
    margin-bottom: 20px;
}

.receipt_submit_buttons{
    display: flex;
    flex-direction: row-reverse;
}