.full_container {
    height: 100vh;
    display: flex;
    justify-content: space-between;
}

.section1 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box_content {
    width: 592px;
    height: 288px;
    margin: 48px;
    padding: 40px 14px 30px 70px;
}

.heading_section {
    width: 560px;
    height: 172px;
  
}

.headings h3 {
    width: 560px;
    height: 24px;
    color:#1890FF;
    text-decoration: none;
}

.headings h1 {
    margin-top: 10px;
    width: 660px;
    height: 53px;
    font-weight: bold;
}

.paragraph {
    margin-top: 10px;
    width: 450px;
    height: 60px;
}

.refresh_section {
    width: 99px;
    height: 48px;
    margin-top: 16px;
}

.section2 {
    flex: 1;
    display: flex;
    align-items: center;
}

.section2 img {
    width: 514px;
    height: 164px;
}
