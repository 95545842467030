.tab_form_container {
  /* border: 1px solid #e2e1e1; */
  /* margin: 0px 15px 15px 14px; */
  height: auto;
}

.tab_form_main_container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.tab_form_main_table {
  width: 100%;
  border-collapse: collapse;
}

.tab_form_main_table th,
.tab_form_main_table td {
  padding: 12px;
}

.tab_form_key_parameters {
  position: sticky;
  left: 0;
  width: 263px !important;
  min-width: 263px !important;
  max-width: 263px !important;
  height: 39px;
  background: hsla(210, 20%, 98%, 1);
  color: #667085;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-right: 1px solid #e2e1e1;
  border-bottom:   1px solid #E4E7EC;

  z-index: 99;
}

.tab_form_key_parameters_persoanl{
  position: sticky;
  border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  left: 0;
  width: 263px !important;
  min-width: 263px !important;
  max-width: 263px !important;
  height: 39px;
  background: hsla(210, 20%, 98%, 1);
  color: #667085;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-right: 1px solid #e2e1e1;


  z-index: 99;
}
.bure_cibil_heading {
  text-align: center;
}

.key_parameneter_buro {
  flex: 1;
}

.tab_form_top_heading {
  min-width: 380px !important;
  height: 39px;
  background: hsla(210, 20%, 98%, 1);
  color: #667085;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border-right: 1px solid #e2e1e1;
}

.add_button_alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab_form_left_heading {
  width: 263px !important;
  min-width: 263px !important;
  max-width: 263px !important;
  position: sticky;
  left: 0;
  border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  background: hsla(210, 20%, 98%, 1);
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  z-index: 99;
  /* border-right: 1px solid rgb(245, 239, 239); */
}

.tab_form_main_data_table {
  min-width: 380px !important;
  padding: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background: #fff;
  border-top: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
}

.tab_form_main_data_two_entries {
  width: 50%;
}

.tab_form_main_data_three_entries {
  width: 380px;
}

.tab_form_main_data_four_entries {
  width: 380px;
}

/* Edit button css */
.tab_form_sub_edit {
  display: flex;
  width: 33px;
  /* padding: 2px var(--Size-Padding-paddingXS, 8px) 2px 6px; */
  justify-content: center;
  align-items: center;
  /* gap: 6px; */
  border-radius: 16px;
  /* background: var(--Color-Neutral-Text-colorTextSolid, #fff); */
  cursor: pointer;
}


.tab_form_sub_edit img {
  width: 25px;
  /* border: 1px solid red; */
  /* height: 14px; */
  /* flex-shrink: 0;
  stroke-width: 1px; */
  /* color: #667085; */

}

.verify_img {
  margin-left: 5px;
}

.documnet_div {
  cursor: pointer;
}

.tab_form_sub_edit span {
  color: #626c80;
  /* text-align: center; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 18px; */
}

/* verify icon */
.verify_icon {
  margin-left: 5px;
}

/* Data Not found */
.no_data_found_message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.no_data_container {
  position: relative;
}

.no_data_container_heading {
  overflow-x: auto;
  white-space: nowrap;
}

.no_data_table_height {
  height: 400px;
}

.no_data_heading_bottom_border {
  border-bottom: 1px solid #e4e7ec;
}

.no_data_heading_content {
  display: flex;
  justify-content: space-between;
}

.no_data_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/*verify icon css */

.tab_form_check_btn {
  margin-left: 10px;
  /* font-size: 10px; */
  /* margin-top: 3px; */
}

/* Blue row css */

.tab_form_highlighted_row_blue {
  /* width: 219px; */
  border-right: none;
  border-top: 1px solid var(--Neutral-5, #d9d9d9);
  border-bottom: 1px solid var(--Neutral-5, #d9d9d9);
  background: #e6f7ff;
  position: sticky;
  left: 0;
  height: 39px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border-top: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
}

/* Gray row css */

/* .tab_form_gray_highlighted_row {
  background: #f9fafb;
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-right: none;
} */

/* Orange row css */

/* .tab_form_highlighted_row_orange {
  border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  background: #ffe;
} */

.customer_tabs_form_buttons {
  display: flex;
  flex-direction: row-reverse;
  /* border: 1px solid red; */
}

.select_field_tab_width {
  width: 100% !important;
}