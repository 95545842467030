.part_disbursed_initiate_status{
    background-color:#ECFFDC ;
    border-radius: 9px;
    padding: 5px 10px;
    display: inline-block;
    color: #40B5AD;
   
}

.part_disbursed_initiate_view{
    font-size: 18px;
    color: black;
}

.part_disbursed_initiate_heading{
    display: flex;
    justify-content: space-between;
}

.part_disbursed_initiate_filter_box{
    display: flex;
    justify-content: center;
    align-items: center;
}

.part_disbursed_initiate_filter_button{
    margin-left: 1rem;
}