
.decition_container {
    border: 1px solid #e4e7ec;
    display: flex;
    flex-direction: column;
    height: 50%;
    /* margin: 15px; */
  }

  .decition_container_manual_deviation{
    border-bottom: none;
  }

  .decition_container_technical_block {
    border: 1px solid #e4e7ec;
    display: flex;
    flex-direction: column;
    height: 50%;
    border-bottom: none;
 margin-top: 15px;
  }

.decision_heading {
    background-color: #E6F7FF;
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.text{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    /* margin-top: 7px; */
    margin-left: 10px;
   /* color: rgb(100, 101, 102); */
   color: #27658f;
    font-family: Poppins;
   }

   .auto_deviation_text{
      margin-top: 8px;
   }

   .disbursed-container_red {
    text-align: center;
    font-family: Roboto;
    color: rgba(180, 35, 24, 1);
    background-color: #FEF3F2;
    font-size: 16px;
    margin-bottom: 5px;
    background: #F6FFED;
    border-radius: 16px; 
    width:100px;
    height:22px;
    top: 12px;
    left: 25px;
}


.Success{
    color: green;
    font-size: 16px;
    margin-bottom: 5px;
}

.decision_table{
    overflow-x: auto;
}

.technical_decision_table_status{
    width:16px;
    padding: 3px 5px 8px 0px;
}
.technical_decision_auto_deviation_heading{
    background-color: #E6F7FF;
    border-bottom: 1px solid #E4E7EC;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    height: 45px;
}

.technical_views_decision_form_main_container{
    border: 1px solid #e4e7ec;
    display: flex;
    flex-direction: column;
    height: 50%;
    /* margin: 15px; */
    border-radius: 5px;
  }

.technical_decision_auto_deviation_heading_collapse{
    margin-top: 10px;
    margin-right: 10px;
}

.technical_decision_deviation_heading_block{
    background-color: #E6F7FF;
    border-top: 1px solid #E4E7EC;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    height: 45px;
}

.technical_decision_container_technical_block {
    border: 1px solid #e4e7ec;
    display: flex;
    flex-direction: column;
    height: 50%;
    border-bottom: none;
 margin-top: 15px;
 border-radius: 5px;
  }


  .technical_decision_table{
    background-color: #E6F7FF;
    /* border-bottom: 1px solid #E4E7EC; */
    /* border-top:none !important; */
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    height: 45px;
}