.address-row td {
  background: #E6F7FF;
  border-right: none;
  font-weight: 600;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;

}

.address-pd-row td {
  background: #F9FAFB;
  border-right: none;
  font-weight: 600;
}

.current_adddres_div{
  display: flex;
  justify-content: flex-end;
}
.address_type_form{
  
  margin-bottom: 16px;
}
.address_button{
  display: flex;
  flex-direction: row-reverse;
}


.status_false_flage{
  color: red;
  margin-left: 10px;
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.status_true_flage{
  color: green;
  margin-left: 10px;
  font-size: 15px;
  margin-right: 10px;
  cursor: pointer;
}