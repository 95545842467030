.Basic_details_container {
    display: flex;
    justify-content: space-between;
    border-top: none;
    flex-wrap: wrap;
}

.Basic_details_main_container {
    display: flex;
    justify-content: space-between;
    border-top: none;
    flex-wrap: wrap;
    gap: 20px;
}

.Basic_basic_card {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; 
    width: 100%;
}

.basic_basic_card_content,
.basic_basic_basic_card_content,
.basic_basic_basic_card_div_content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 9px;
    min-width: 220px; 
}

.basic_basic_details_label {
    color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.basic_basic_details_data {
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .Basic_basic_card {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media (max-width: 768px) {
    .Basic_basic_card {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 480px) {
    .Basic_basic_card {
        grid-template-columns: 1fr; 
    }
}
