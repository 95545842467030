.status_column_loan_list {
    background-color: #ECFFDC;
    border-radius: 9px;
    padding: 5px 10px;
    display: inline-block;
    color: #40B5AD;
}

.switch_toggle_button {
    margin-right: 16px;
    margin-top: 4px;


}

.switch_toggle_button_container {
    display: flex;

}


.view_button_loan_list {
    font-size: 18px;
    color: black;
}

.loan_list_heading {
    display: flex;
    justify-content: space-between;
}

.loan_list_more_filter {
    display: flex;
    flex-direction: row-reverse;
}