.legal_property_heading {
    margin-right: 10px;
    Font: Roboto;
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
    width: 10%;
   
}
.legal_property_data{
    margin-left: -30px;

}
.property_check{
    color: red;
    margin-left: 10px;
}

.legal_note {
    margin-left: 107px;
    color: #FF4D4F;
}

.all_required_sign {
    color: rgba(255, 77, 79, 0.70);
    margin-right: 3px;
}




