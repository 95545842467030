.part_disbursed_main_container {
    height: auto;
    top: 2002px;
    left: 58px;
    border-radius: 8px;
    border: 1px;
    gap: 12px;
    border: 1px solid #e4e7ec;
    /* margin-top: 48px; */
  }
  .part_disbursed_main_heading {
    border-bottom: 1px solid #e4e7ec;
    display: flex;
    justify-content: space-between;
  }
  .customer_headding {
    height: 32px;
    margin-top: 0.8rem;
  }
  
  .customer_details_heading {
    color: hsla(0, 0%, 9%, 1);
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
  .customer_title {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 1rem;
  }
  .part_disbursed_icon {
    /* width: 24px; */
    height: 34px;
  
    padding: 3px;
    margin-top: 1rem;
    margin-right: 1rem;
  }
  
  .customer_tab_content {
    margin-bottom: 20px;
  }
  

  .part_disbursed_label{
    color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
  
  .part_disbursed_data{
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
   
    
  }

  .part_disbursed_heading_data{
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 12px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;  
  }

  /* ******************************* */

  .part_disbursed_table_collapse{
    cursor: pointer;
  }
.no-bottom-border {
    border-bottom: none;
}

.show-customer-details {
  display: block;
}

.hide-customer-details {
  display: none;
}
