.login_form_main_container {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  overflow-y: hidden;
}

.form_control_container {
  flex: 1;
  height: 100%;
}
.sign_button {
  padding: 10px 18px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.submit_buttton {
  padding: 10px 18px;
  height: 44px;
  font-size: 14px;
  font-weight: 500;
}

.form_section {
  width: 100%;
  height: 90%;
  border: 2px solid white;
}

.centered-content {
  display: flex;
  justify-content: space-between;
}

.ant-form {
  line-height: none !important;
}

.ant-form-horizontal {
  line-height: none !important;
}

.centered-content a {
  margin: 7px 0px 0px 32px;
}

.form_content {
  width: 360px;
  height: 80%;
  margin: 40px 150px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header_section {
  height: 20%;
  width: 100%;
  text-align: center;
}

.header_section img {
  width: 37px;
  height: 37px;
  margin-bottom: 20px;
}

.welcome_back_heading {
  color: rgba(102, 112, 133, 1);
  font-size: 16px;
  font-weight: 400;
}

.input_section {
  height: auto;
  width: 100%;
  /* border: 1px solid teal; */
  margin-top: 1rem;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.login_singup_google {
  display: flex;

  flex-direction: column;
}

.form_action_section {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form_action_section img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.sign_with_google_btn {
  border: 1px solid #d0d5dd;
  margin-top: 8px;
  display: flex;

  align-items: center;

  justify-content: center;
}

.sign_with_google_btn img {
  margin-right: 10px;
}

.sign_with_google_btn:hover {
  background-color: transparent;
}

.signup_section {
  margin-top: 5px;
}

.footer_section {
  padding: 17px;
  width: 100%;
  height: 10%;
  font-size: 15px;
}

.footer_section p {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.image_control_container {
  flex: 1;
  width: 100%;
  height: 100%;
}

.image_control_container img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 280px) and (max-width: 768px) {
  .login_form_main_container {
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-y: hidden;
  }

  .form_control_container {
    flex: 1;
    height: 100%;
  }

  .form_section {
    width: 100%;
    height: 90%;
    border: 2px solid white;
  }

  .centered-content {
    display: flex;
    justify-content: space-between;
  }

  .centered-content a {
    margin: 7px 0px 0px 32px;
  }

  .form_content {
    width: 60%;
    height: auto;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;

    overflow-x: hidden;
  }

  .header_section {
    height: 40%;
    width: 100%;

    text-align: center;
  }

  .header_section img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }

  .welcome_back_heading {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-weight: 400;
  }

  .input_section {
    height: auto;
    width: 100%;

    margin-top: 1rem;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .login_singup_google {
    display: flex;

    flex-direction: column;
  }

  .form_action_section {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .form_action_section img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  .sign_with_google_btn {
    border: 1px solid #d0d5dd;
    margin-top: 8px;
    display: flex;

    align-items: center;

    justify-content: center;
  }

  .sign_with_google_btn img {
    margin-right: 10px;
  }

  .sign_with_google_btn:hover {
    background-color: transparent;
  }

  .signup_section {
    margin-top: 5px;
  }

  .footer_section {
    padding: 17px;
    width: 100%;
    height: 10%;
    font-size: 15px;
  }

  .footer_section p {
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  .image_control_container {
    flex: 1;
    width: 90%;
    margin: auto;
    height: 100%;
  }

  .image_control_container img {
    width: 100%;
    height: 100%;
  }
}

.full-screen-loader-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it appears on top of other elements */
}
