.css-dev-only-do-not-override-hgxmkj .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-start-start-radius: 0px;

}

.css-dev-only-do-not-override-hgxmkj .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px;
}

.total_row {
  color: #FFA726;

}

.Income_evaluation_container {
  border: 1px solid #e4e7ec;
}

.Income_detail_heading {
  background: #E6F7FF;
  color: #000000b2;
  height: 46px;
  display: flex;
  justify-content: space-between;

}


.cam_button{
  background-color: #276690 !important;
}



.responsive-table-container {
  border: 1px solid #E4E7EC;
  padding: 10px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  /* This will allow horizontal scrolling on small screens */

  /* Adjust padding and margins for smaller screens */
  @media (max-width: 768px) {
    padding: 5px;
  }

  @media (max-width: 480px) {
    padding: 2px;
  }
}

.ant-table {
  width: 100%;
  min-width: 600px;
  /* Set a minimum width for the table to prevent it from shrinking too much */
}

/* Optional: Adjust table columns' text to be more flexible on smaller screens */
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  white-space: nowrap;
  /* Prevent text from wrapping inside table cells */
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Optional: Adjust the font size and padding for smaller screens */
@media (max-width: 768px) {

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 480px) {

  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td {
    font-size: 12px;
    padding: 6px;
  }
}

.bank_account_details_table {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.cam-row {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 10px;
}

.existing-loan-table {
  overflow-x: auto;
}