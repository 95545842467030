.details_coverage_hdfc {
  background: #F1F1F1;
  Height: 39px;
  /* border-bottom: 1px solid #E1E4E9; */
  Padding: 8px 12px 8px 25px;
  Size: 14px;
  color: #667085;
  /* margin: 10px 0px 10px 0px; */
  font-weight: 600;
}

.statement-list {
  list-style-type: disc;
  padding-left: 20px;
}

.statement-list>li {
  margin-bottom: 8px;
  /* Optional: Add space between list items */
}

.icici_prudential_block {
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #667085;
  padding: 0.5rem;
  /* border-left:1px solid #667085; */
  border-radius: 4px, 4px, 4px, 4px;

}

.insuarnce_row_input {
  margin-bottom: 16px;
}

.detail_heading {
  /* border-left: 1px solid black; */
  margin-left: 2rem;
  padding-left: 4px;
}

.detail_age {
  padding-left: 4px;
}

.insurance_personal_details_heading {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-left: 0px;
}



.insurance_personal_option {
  margin-bottom: 0px;
}

.radio_options {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-left: 10px;
}

.drawer-insurance-space {
  padding: 25px;
}

.detail_magma_view {
  display: 'flex';
  justify-content: 'space-around';
  align-Items: 'center';
  height: '35px';
  background: #F1F1F1;
}

.insurance-item {
  margin-bottom: 1rem;
}

.insurance-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0;
}

.insurance-left {
  flex: 1;
}

.insurance-right {
  flex: 1;
  text-align: right;
}

.insurance-question,
.insurance-answer {
  margin: 0;
}

.insurance-sub-questions {
  list-style-type: none;
  padding-left: 0;
}

.insurance-sub-question-wrapper {
  width: 70%;
}


.sub-question-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.sub-question-items {
  display: flex;
  justify-content: space-between;
  /* border: 3px solid red */
}

.insurance-remark {
  font-style: italic;
  color: #888;
}

.insurancetype-size {
  font-size: 14px;
}

.addtess_line_insurance {
  margin-top: 1rem;
}

.astrick {
  color: red;
  margin-right: 4px;
}

.insurance_field {
  width: 300px;
}

.insurance_error {
  color: red;
}


.render_insurance_form {
  margin-bottom: 20px;
  margin-top: 40px;
}

.save_cncle_insurance {
  display: flex;
  flex-direction: row-reverse;
}

.dlete_insurance {
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.insurance_img_picture {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.inusurance_pdf_img {
  border: none;
}

.aplicantin_pdf_insurance_conatiner {
  display: flex;
  flex-wrap: wrap;
}

.upload_document_insurance {
  width: 176% !important;
}

.select_field_insurance {
  width: 100% !important;
}

.insurance_country_code_block {
  width: 20% !important;
  text-align: center !important;
  background: #f1f1f1 !important;
}

.insurance_mobile_number_field {
  width: 80% !important;
}
.aplicantin_pdf_insurance_conatiner_first{
  margin: 10px;
}
.aplicantin_pdf_insurance_image{
  margin-right: 15px;
  position: relative;
}