.Personal_main_data_table td {
  border-top: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
}

.personal_tab_heading {

  display: flex;
  justify-content: space-between;
  align-items: center;

}
.comp_country_code_block {
  width: 20% !important;
  text-align: center !important;
  background: #f1f1f1 !important;
}
.comp_input_mobile{
  width: 80% !important;
}

.row_compony_applcant{
  margin-bottom: 16px;
}
.personal_tab_icon{

  display: flex;
}
.name_for_persoanl {
  margin-left: 5px;
}

.profile_img {
  height: 100px;
  cursor: pointer;
}

.personal_img {

  margin-left: 5px;
}

.profile_img {
  width: 85px;
}

.profile_img.has-thumb {
  width: 100px;
}

.select_filed_personal {
  width: 100%;

}
.input_filed_personal {
  width: 60%;
  border:"1px solid red"
}



.personal_tab_status_false_flage{
  color: red;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}

.personal_tab_status_true_flage{
  color: green;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}