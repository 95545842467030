.magma-insured-customer-date-of-birth{
  width: 100%;  
}

.occupation-nationality-contactnumber{
    margin-bottom: 16px;
    /* border:2px solid red */

}

.insured-customer-contact-number-91{
    width: 20% !important;
    text-align: center !important;
    background: #f1f1f1 !important;
    /* border:2px solid red */
}

.insured-customer-contact-number{
width: 80% !important;
}

.magma-hdi-insurance-detail{
    margin-bottom: 50px;
}

.magma-hdi-insured-customer{
    margin-left: 20px;

}
.magma-hdi-select-plan{
    padding: 20px;
}

.magma-hdi-select-plan-content{
    width: 200px;
}

.Your-insured-Customer-Plan{
    padding: 20px;
}

.Convalescence-Benefit{
    padding: 20px;
    margin-top: 10px;
}

.convalescence-benefit-and-amount-heading{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 35px;
    background-color: #F1F1F1;
}

.convalescence-benefit-and-amount-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* height: 50px; */
}