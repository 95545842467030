.bure_cibil_heading {
  background: #F1F1F1;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 8px;
  border: '1px solid #0000000F';
  border-top: 1px solid #E4E7EC;
}

.green-text {
  color: #52C41A;
}

.cibil_highmark {
  padding: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background: #fff;
  border-top: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
}

.bureau_td_heading{
  text-align: center !important;
}