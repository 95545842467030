.customer-matching-container {
  max-width: 100%;
  overflow-x: scroll;
}

.custom-table {
  border: 1px solid #ddd;
  padding: 10px;
  border-collapse: collapse;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}

.table_heading {
  /* height: 50px; */
  /* padding: 10px; */
  /* text-align: left;
  background-color: #f2f2f2;
  color: #667085;
  display: flex;
  justify-content: space-between; */
  display: grid;
  grid-template-columns: 0.7fr 1fr 1fr 1.5fr 2fr;
  font-weight: bold;
  background-color: #f0f0f0;
  padding: 10px;
  text-align: left;
}

.dedupe_table_data {
  display: flex;
}

.dedupe_table_data_body {
  /* width: 38%; */
  margin: 20px 0px 20px 0px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #000000B2;
}

.source-column-dedupe {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 510;
  line-height: 22px;
 

}

.dedupe_first_row {
  background-color: #e1f2ff;
  color: #1677FF;
  height: 50px;
  padding: 15px;
}

.dedupe_loan_number {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.customer_linked_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 7px;
  margin-top: 0px;
}

.tab_form_sub_linked {
  display: flex;
  width: 70px;
  padding: 2px 8px 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 16px;
  background: var(--Color-Neutral-Text-colorTextSolid, #fff);
  cursor: pointer;
}

.linked_button {
  border: none;
  background-color: #f4f5f7;
  margin-top: 8px;
  margin-right: 5px;
  color: #626c80;
}

.remark_div {
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 6px;
}

.dedupe_remark {
  /* width: 1157px; */
  height: 50px;
  color: #667085;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  left: 20px;
  padding: 12px;
  background-color: #f1f1f1;
}

.no_match_found {
  /* width: 1170px;   */
  height: 129px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000;
  margin: 5px 0px 0px 10px;
}

.table-header {
  border: 1px solid #ddd;
  height: 50px;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  color: #667085;
}

.customer-column {
  width: 150px;
}

.customer_linked_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 7px;
}

.table-data {
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 18px;
}

.customer_linked_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 7px;
}

.highlight_property {
  color: red;
}



.dedup_link_lan_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 22px;
  border-radius: 16px;
  background: rgba(241, 241, 241, 1);
  cursor: pointer;
}

.dedup_link_lan_button img {
  width: 14.4px;
  height: 14px;
  margin-right: 5px;
}

.dedup_link_lan_button span {
  color: #626c80;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}


.dedupe_match {
  display: flex;
  margin: 4px 0;
}

.dedupe_grid_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dedupe_grid_row {
  display: grid;
  grid-template-columns: 0.7fr 1fr 1fr 1.5fr 2fr;
  border-bottom: 1px solid #ddd;
  text-align: left;
}