.dashboard_lite_todo_div_one {
    padding: 10!important;
    background-color: #fef6f5;
    border: 1px solid #f5222d;
    border-radius: 8,
}
.dashboard_lite_todo_div_two {
    margin-top: 16;
    display: flex;
    align-items: center 
}
.custom-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }