.error-row {
    color: #d80404; 
  }

  .no-vertical-align svg {
    vertical-align: unset !important; /* Removes the vertical-align property */
  }

  .refrence-button-field {
    width: calc(100% - 50px) !important; /* Adjusts the width dynamically */
    border:"2px solid red"
  }

  .file-list-download-icon{
    cursor: pointer;
  }

  .pending-list-header {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-between; 
    align-items: center; 
    gap: 16px; 
    margin-bottom: 16px;
  }

  .pending-list-download-button{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .pending-list-filter-search-button{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }

  .filter-button{
    width: 120px;
  }

  .pending-list-search-button{
    width: 200px;
  }

  .file-list-heading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 30px;
  }

  .file-list-search-button{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .cersai-heading-pending-and-file{
    margin: 0 !important;
  }

  .ant-table-wrapper {
    overflow-x: auto;
  }
  
  .ant-table {
    min-width: 100%;
  }

  @media (max-width: 768px) {
    body, 
    .ant-table,
    .ant-table-title,
    .ant-table-footer,
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-input,
    .ant-select,
    .ant-btn {
      font-size: inherit !important;
    }
  }