.karza-para {
    color: black !important;
    font-size: 15px !important;
} 

:where(.css-dev-only-do-not-override-kn4mev).ant-modal .ant-modal-title {
    margin-left: 2rem;
    font-weight: 400;
}

:where(.css-dev-only-do-not-override-kn4mev).ant-row { 
    margin-top: 12px;
}

.karza_table_container {
    margin-left: 15px;
    margin-right: 25px;
}

.datepicker {
    width: 100%;
}

.submit_space {
    display: flex; 
    flex-direction: row-reverse;    
}

.karza_tab_form_main_container {
    display: flex;
    flex-direction: row-reverse; 
    margin-bottom: 20px;
}