.pd_checkbox_content{
    margin-bottom: 20px;
    margin: 5px;
    
}
.pd_picture_type_name {
    margin-left: 10px;
}
.pd_inintion_checkbox{
    display: flex;
    justify-content: space-between;
    margin: 5px;
}
.pd_inintion_checkbox_second{
    display: flex;
    flex-direction: column;
    width: 100%;
}