.Calendar_details_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
margin-top: 20px;
}

.calendar_card-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
}

.calendar_label {
  font-weight: bold;
  color: #2c6e9b;
}

.calendar_data {
  padding-top: 5px;
 
}