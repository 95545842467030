.legal_valuation_first_container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.legal_valuation_second_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}
.customer_main_heading_fi {
    border-bottom: 1px solid #e4e7ec;
    display: flex;
    justify-content: space-between;
  }
  .customer_main_heading_fi.with-border {
    border-bottom: 1px solid #e4e7ec;; /* Add the border when `showCustomerDetails` is true */
  }
  
  .customer_main_heading_fi.no-border {
    border-bottom: none; /* No border when `showCustomerDetails` is false */
  }
  .detail_fi_input{
    cursor: pointer;
  }
  .fi_tabs{
 
    margin-top: 20px;
    margin-left: 20px;
  }


.legal_heading {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;

}
.FI_heading {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;

}

.legalvaluation {
    width: 216px;
    color: #389E0D;
    margin-left: 15px;
    display: flex;
    background: #F6FFED;
    border-radius: 16px;
    align-items: center;
    font-family: 'Roboto', ;
    font-weight: '500';
    word-wrap: 'break-word';
}

.legal_valuation_second_container {
    margin-top: 15px;
}

.legal_valuation_second_container_heading_two {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #000000B2;
}


.legal_tables_container {

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}

.legal_table_one {
    border: 1px solid #b8b3b373;
    border-radius: 8px;
    width: 442px;
    /* height: 377px; */
    height: auto;
    top: 214px;
    left: 59px;
    gap: 0px;
    min-width: 300px;
    flex: 1;
}

.legal_table_two {
    border: 1px solid #b8b3b373;
    border-radius: 8px;
    width: 700px;
    /* height: 377px; */
    top: 214px;
    left: 59px;
    min-width: 300px;
    flex: 1;

    gap: 0px;

}


@media (max-width: 1000px) {

    .legal_table_one,
    .legal_table_two {
        width: 100%;
        height: auto;
        /* Full width for smaller screens */
        margin-bottom: 20px;
        /* Add margin to separate them vertically */
    }
}

.legal_table_one_heading {
    border-bottom: 1px solid #b8b3b373;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    margin: 10px 0px 0px 0px;

}

.legal_table_data {
    border-bottom: 1px solid #b8b3b373;
    margin: 10px 0px 10px 0px;
}

.legal_img_table_data {

    margin: 10px 0px 10px 0px;

}

.legal_table_data_heading {
    /* border: 1px solid red; */
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000080;
    margin-left: 20px;
}

.legal_table_data_value {
    /* border: 1px solid red; */
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000B2;
    margin-left: 100px;

}

.legal_img_table_data {
    /* border-bottom: 1px solid #b8b3b373; */

    margin: 10px 0px 10px 0px;
    height: auto;
}

.legal_table_img_data_value {
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000B2;
    margin-left: 100px;

    flex-wrap: wrap;

}

.legal_table_img_data_value img {
    width: 55px;
    height: 42px;
}

.legal_table_img_data_heading {
    width: 350px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000080;
    margin-left: 20px;
}

.legal_tabs_container {
    border: 1px solid #E4E7EC;
    border-radius: 8px;
    background-color: #FFFFFF;
    top: 1017px;
    left: 58PX;
    gap: 8px;
    margin: 20px 0px 20px 0px;

}

.legal_tabs_container_heading {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    /* margin-left: 15px; */

}


.flextabs {
    border-bottom: 1px solid #dcdddf;
}

.customer-dfi-etails.visible {
    display: block; /* Show the element when `showCustomerDetails` is true */
  }
  
  .customer-fi-details.hidden {
    display: none; /* Hide the element when `showCustomerDetails` is false */
  }

 .fi-final-submit {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}
  