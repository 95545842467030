/* Styles for the floating chat button */
.floating-chat-button {
  margin-right: 120px;
}

/* Styles for the chat screen container */
.chat-screen-container {
  position: fixed;
  bottom: 100px;
  right: 150px;
  z-index: 1000;
  /* height: calc(100vh - 150px);
  max-height: 800px; 
  min-height: 400px; */
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
}

/* Make sure the ChatBot component takes full height */
.chat-screen-container > * {
  height: 100%;
  width: 100%;
}

/* Responsive adjustments */
@media screen and (max-height: 768px) {
  .chat-screen-container {
    height: calc(100vh - 120px);
    bottom: 80px;
  }
}

@media screen and (max-width: 768px) {
  .chat-screen-container {
    right: 20px;
    left: 20px;
    width: auto;
  }
  
  .floating-chat-button {
    margin-right: 20px;
  }
}