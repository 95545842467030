
.valuation_container {
  border: 1px solid #e4e7ec;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 50%;
  margin: 15px;
}
/* Edit button css */
.underwriting_form_sub_edit {
  display: flex;
  width: 57px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  background: var(--Color-Neutral-Text-colorTextSolid, #fff);
  cursor: pointer;
  height: 20px;
  margin-top: 13px;
}

.underwriting_form_sub_edit img {
  width: 14.396px;
  /* height: 14px; */
  /* flex-shrink: 0;
  stroke-width: 1px; */
  /* color: #667085; */
}

.underwriting_form_sub_edit span {
  color: #626c80;
  /* text-align: center; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 18px; */
}


.valuation_details_data {
  /* border: 1px solid gray; */
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 1);
  border-top: none;
  /* padding:5px 0px 10px 0px; */

}






.technical_view_heading{
  background: #E6F7FF;
  border: 1px solid #E4E7EC;
  height: 46px;
  display: flex;
  justify-content: space-between;
}

.technical_view_heading_name{
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.technical_input_button_space{
  display: flex;
  justify-items: center;
  align-items: center;
}
.technical_view_edit_button{
  display: flex;
  align-items: center;
  /* margin-right: 5px; */
}
.technical_views_collapse_icon{
  margin:18px 10px 10px 5px ;
  cursor: pointer;

}