.part_disbursed_table {
    padding: 10px 10px 10px 10px;
}

.part_disbursed_container {
    border: 1px solid #e4e7ec;
}

.part_disbursed_heading {
    background: #E6F7FF;
    border: 1px solid #E4E7EC;
    height: 46px;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    
}

.part_disbursed_data {
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

/* .table_content {
    border: 1px solid #E4E7EC;
    border-top: none;
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table_content .ant-table {
    border-radius: 10px !important;
}

.table_content .ant-table-thead>tr>th {
    border-radius: 0 !important;
}

.no-radius-table .ant-table-container {
    border-radius: 10px !important;
} */

.part_disbursed_table_heading {

    /* background: #E6F7FF; */
    /* border: 1px solid #E4E7EC; */
    height: 46px;
    display: flex;
    justify-content: flex-end;
}
.part_disbursed_icon {
    /* width: 24px; */
    height: 34px;
    padding: 3px;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .part_disbursed_main_heading {
    border-bottom: 1px solid #e4e7ec;
    display: flex;
    justify-content: space-between;
  }


  



  .table_content {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  .table_content table {
    width: 100%; /* Ensure the table takes full width */
    border-collapse: collapse;
  }
  
  .table_content th, .table_content td {
    padding: 8px 12px;
    text-align: left;
    white-space: nowrap; /* Prevent text wrapping inside the table cells */
  }
  
  @media (max-width: 768px) {
    .table_content table {
      width: 100%; /* Keep the table width responsive */
      min-width: 600px; /* Set a minimum width for proper scrolling */
    }
  }
  