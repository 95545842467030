.construction_timeline_container {
  border: 1px solid rgb(228, 231, 236);
}

.part_disbursal_tabs {
  border: 1px solid rgb(228, 231, 236);
  display: flex;
  height: 46px;
}

.construction_container {
  display: flex;
  justify-content: space-between;
}

.construction_timeline {
  display: flex;
  justify-content: space-between;
}

.construction_timeline_card1 {
  width: 30%;
  height: auto;
  border-bottom: 1px solid rgb(228, 231, 236);
}

.construction_timeline_card2 {
  width: 65%;
  height: 100%;
  border-bottom: 1px solid rgb(228, 231, 236);
}


.disbursal_card {
  gap: 12px;
  height: auto;
  margin-top: 9px;
  width: 35%;
}

.timeline_container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.construction_timeline_card {
  width: 5%;
  height: auto;
  margin-left: 16px;
}

.step_circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  color: blue;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  border: 1px solid blue;
  margin: 0 auto;
}

.line {
  width: 1px;
  height: 92%;
  background-color: blue;
  position: relative;
  margin: 0 auto;
  margin-top: -5px;
}

.carousel {
  position: relative;
  width: 350px;
  margin: auto;
  margin-top: 1.2rem;
}

.carousel-image {
  width: 350px;
  height: 230px;
  cursor: pointer;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-controls button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.carousel-controls button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 768px) {
  .construction_timeline_container {
    padding: 0 10px;
  }

  .construction_container {
    flex-direction: column;
  }

  .construction_timeline_card1,
  .construction_timeline_card2 {
    width: 100%;
  }

  .step_circle {
    width: 24px;
    height: 24px;
    margin-top: 10px;
  }

  .line {
    height: 80%;
  }

  .carousel {
    width: 100%;
    margin-top: 1rem;
  }

  .carousel-image {
    width: 100%;
    height: auto;
  }

  .carousel-controls button {
    padding: 8px;
  }

  .disbursal_card {
    width: 100%;
    margin-bottom: 12px;
  }

  .part_disbursed_label,
  .part_disbursed_data {
    font-size: 14px;
  }

  .table_content table {
    min-width: 300px;
  }
}

@media (max-width: 480px) {
  .construction_timeline_card1,
  .construction_timeline_card2 {
    width: 100%;
  }

  .step_circle {
    width: 20px;
    height: 20px;
    margin-top: 10px;
  }

  .line {
    height: 70%;
  }

  .carousel-image {
    width: 100%;
    height: 150px;
  }

  .disbursal_card {
    width: 100%;
  }
  
  .part_disbursed_label,
  .part_disbursed_data {
    font-size: 12px;
  }

  .carousel-controls button {
    padding: 6px;
  }

  .construction_timeline_card {
    margin-left: 0;
  }
}

/* ******************************* */

.construction_timeline_card_content{
  margin-bottom: 2rem;
}