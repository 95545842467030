.propery_picture_main_form{
    margin-top: 10px;
}

.property_picture_document_name{
    flex: 1;
    margin-right: 8px;
    width: 100%;
}

.property_picture_document_divider{
    margin: 10px 10px 10px 10px ;
}

.property_picture_document_image_viwer{
    display: flex;
    flex-wrap: wrap ;
    cursor: pointer;
}

.property_picture_document_picture{
margin: 10px;
position: relative;
}


.property_picture_document_pdf{
    width: 100px;
    height: 50px;
}

.property_picture_document_path{
    width: 100px;
    height: 50%;
}

.property_picture_document_path_view{
    top:0;
    right: 0;
    color:#E5233DB2 ;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}

.property_picture_document_image_gallery{
    cursor: pointer;
}

.property_picture_document_space_button{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;

}