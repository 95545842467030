.verify_button{
    width: 68px;
    height:38px;
    padding: 4px 15px 4px 15px;
    border:1px solid rgba(245, 245, 245, 1);
    background: rgba(230, 244, 255, 1);
    
}

.documentList{
    width: 16px;
    height:48px;
}

.documentListVerify{
    margin-top: 15px;
}