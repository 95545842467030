.property_description_container {
  /* border: 1px solid #e4e7ec; */
  /* margin: 0 15px 9px; */

}

.property_description_heading {
   height: 46px;
  display: flex;
  justify-content: space-between;
}
.property_description_heading_two {
  background: #E6F7FF;
  height: 46px;
  display: flex;
  justify-content: space-between;
}

.property_description_data {
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.property_description_basic_card_container {
  display: flex;
  width: 100%;
}
.property_description_basic_card_container_second {
  display: flex;
  width: 100%;
}

.legal_views_border_fixed{
  border-top: none;
}

/* prop */
.property_description_basic_card_legal {
  width: 90%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  gap:100px
}

.property_description_basic_card_label {
  color: #00000080;
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.property_description_basic_card_data {
  margin-left: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
  /* width: 325px; */
  overflow: hidden; /* Hides content that overflows the container */
  text-overflow: ellipsis; /* Optional: Adds "..." for overflowed text */
  white-space: normal; /* Allows text to wrap to the next line */
  word-wrap: break-word; /* Breaks long words onto the next line if necessary */
}



.property_description_form{
  background: #e1e4e9;
    height: 46px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.property_description_form_data{
  margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

.property_description_form_space{
  display: flex;
 flex-direction: row-reverse;
}


/* ************************ */

.legal_input_main_heading{
  /* border: 1px solid red; */
   height: 46px;
  display: flex;
  justify-content: space-between;
}


/* ***************************** */

.property_discription_content{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.property_description_card{
  width: 25% !important;
}

.property_description_label{
      color: #00000080;
      padding: 12px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
}

.property_description_content_data{
  margin-left: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
}

.data_align{
margin-bottom: 20px;
}



.property_description_wrap {
  word-wrap: break-word;
  overflow-wrap: break-word; 
  white-space: normal;
  width: 90%;
}

