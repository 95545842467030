.div1 {
    display: flex;
    /* justify-content: space-between; */
    border: 1px solid #F0F2F5;
    border-radius: 5px;
    /* background-color: gray; */
    margin-top: 10px;

}
.cibit_report_no_data{
  
    margin-top: 24px;
  
}
.amt_hiighmark_text_rupess{
 
    margin-left: 5px;
    font-size: 22px;
}
.current_balance_cibil_Current{
    margin-left: 5px;
}
.div1_two {

    width: 35%;
    border-right: 1px solid #F0F2F5;
    margin-left: 10px;
}

.div1_Consumer {

    display: flex;
    /* justify-content: space-between; */
    border: 1px solid #F0F2F5;
    margin-top: 10px;
    border-radius: 5px;
    /* background-color: .heagin_first; */
}

.div1_Consumer_seocond {
    width: 35%;
    margin-left: 10px;
    border-right: 1px solid #F0F2F5;

}

.third_div_account_first {

    width: 96%;
}

.third_div_account_two {
    border: 1px solid #E4E7EC;

    width: 380px;
    height: 257px;
    border-radius: 8px;
    margin: 12px 0px 0px 12px;

}

.All_Account_text {
    font-family: Roboto;
    font-weight: 500;
    margin: 12px 0px 0px 12px;
}

.all_account_div {
    display: flex;
    justify-content: space-between;
}

.all_account_div_firts {

    width: 126px;
    margin: 50px 0px 0px 13px;

}

.all_account_div_firts_heading {

    color: #00000080;
    padding: 5px;
    margin: 0px;

}

.others_cibil {
    margin-left: 5px;
}

.cibil_report_text {
    /* border: 1px solid red; */
    color: #000000D9;
}

.data_cibili_opne {
    display: flex;
    justify-content: space-between;
}

.chart_div {

    width: 250px;
    height: 100px;

}

.chart_div_two {
    width: 100%;
    height: 220px;

}

.Sanction_div_cibil_first {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin: 12px;
}

.Sanction_div_cibil_second {
    width: 188px;
    height: 104px;
    border-radius: 3px;
    background-color: #5030E514;

}


.Sanction_div_cibil_second_third {
    margin: 8px;
}

.Sanction_div_cibil_heading {
    color: #667085;


}

.Sanction_div_highmark_td {
    color: #667085;
    background-color: #FAFAFA;


}

.month_span_highmark {

    transform: rotate(25deg);
    display: inline-block;
    margin-right: 20px;
}

.year_span_highmark {

    transform: rotate(25deg);
    display: inline-block;
    margin-right: 4px;
}

.table_thaed_highmark {
    padding-top: 10px;
    position: relative;
}

.table_thaed_highmark_second {

    transform: translate(0%);
}

.Sanction_div_cibil_heading_Current {
    margin-left: 6px;

}

.Date_cibile {
    margin: 8px 8px;
}

.cibil_div_inuiiries {
    border: 1px solid #E4E7EC;
    width: 400px;
    height: 257px;
    border-radius: 8px;
    margin: 12px;


}
.no_data_div_cibil{
   
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 38%;

   
}

.cibil_div_inuiiries_chart {
    margin: 5px 12px 5px 12px;
}

.cibil_div_inuiiries_chart_two {
    width: 100%;
    height: 200px;
}

.cibil_div_inuiiries_heading {
    font-family: Roboto;
    font-weight: 500;
    margin: 12px 0px 0px 12px;
  
}
.cibil_div_inuiiries_heading_icon{

    margin-left: 5px;
}

.cibil_score_div {
    background-color: #78C25E;
}

.cibil_score_div_secomnd {
    display: flex;
    justify-content: center;
}

.cibil_score_icon {
    height: 160px;
}

.crifiimage {
    display: flex;
    justify-content: flex-end;
}

.divider_cibil {
    margin: 0;
    background-color: white;
}

.divider_cibil_text {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    border-bottom: 20px;
}

.cibil_score_text {

    color: #00000073;

}

.para_heading_first {

    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin: 0px;
    padding-left: 20px;
    padding-top: 10px;


}

.cibil_heading_second {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;
    margin: 0px;
    padding-left: 20px;

}

.heagin_first {

    padding-left: 10px;


}

.heagin_second {

    padding-left: 10px;
    border-bottom: 1px solid #F0F2F5;
    border-top: 1px solid #F0F2F5;

}

.heagin_second_first {
    border-bottom: 1px solid #F0F2F5;
}

.Borrower_Information {
    width: 96%;

}

.para {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #101828;
    margin: 10px;


}

.para_closed_highmark {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #415A05;
    margin: 10px;
    padding-left: 10px;

}

.para_heading_first {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    margin: 0px;
    padding-top: 10px;
    padding-left: 20px;
    margin: 0px;
    padding-left: 20px;
    padding-top: 10px;


}

.main_cibil_container {
    background-color: #F0F2F5;
    height: 77px;
    margin: 0px !important;


}

.separator {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    /* Adjust the width of the separator */
    height: 1px;
    /* Adjust the height of the separator */
    background-color: black;
    /* Adjust the color of the separator */
    transform-origin: top left;
    transform: rotate(-45deg);
    /* Rotate the separator */
}



.main_div_cibil {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: row;
    /* border: 1px solid green; */
    width: 100%;
}

.date_format {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #667085;

}

.cibil_table thead th {
    color: #667085 !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0px !important;
    line-height: 22px;
    background-color: #FAFAFA;

}

.cibil_table thead td {
    color: #667085 !important;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

.cibil_table thead tbody tr td {

    text-align: left;

}

.cibil_table thead tr th {
    text-align: left;
    background-color: #FAFAFA;
}

.ant-table-wrapper.css-dev-only-do-not-override-kn4mev .ant-table-thead>tr>th,
.ant-table-wrapper.css-dev-only-do-not-override-kn4mev .ant-table-thead>tr>td {
    background-color: #FAFAFA
}

.cibil_table tbody tr td {
    text-align: left;
    /* transition: background 0.2s, border-color 0.2s; */
    /* border-bottom: 1px solid #f0f0f0; */
}



.first_div {
    width: 60%;
    margin: 10px 0px 10px 10px;
    /* text-align: left; */
    display: flex;
    flex-direction: column;

}

.first_div_consumer {

    width: 80%;
    margin: 10px 0px 10px 10px;
    /* text-align: left; */

}

.second_div_cosumer {

    width: 80%;
    margin: 10px 0px 10px 10px;
    /* text-align: left; */
}

.second_div {

    width: 50%;
    margin: 10px 0px 10px 1px;
    display: flex;
    flex-direction: column;
    /* text-align: left; */
}

.second_div_employment {

    width: 100%;
}

/* .div2{
    display: flex;
    flex-direction: column;
    border: "1px solid red"; 
    margin-left: 10px;
} */
.heading1 {
    font-family: Roboto;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    /* border: 1px solid red; */
    border-right: 1px solid #F0F2F5;
    /* gap: 8px; */

}

.span_lable {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: 80%;
    /* border: 1px solid red; */

}

.lable_first {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    /* border: 1px solid rgb(17, 14, 14); */
    color: #667085;
    text-align: right;
    display: flex;
    justify-content: space-between;
    /* flex-direction: row; */



}

.span_data {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 2px;
    width: 100%;
    /* border: 1px solid green; */
    color: #000000B2;

    /* flex-grow: 1; */
    /* text-align: right; */

}

.div2 {
    display: flex;
    border: 1px solid #F0F2F5;
    border-radius: 5px;

}

.Adddress_conaintener {
    margin-top: 10px;
}

.third_div_account {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}


.credti_btn {
    border-radius: 8px;
    border: 1px solid #2F54EB;
    color: #2F54EB;
    background-color: #F0F5FE;

    margin-left: 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    /* text-align: left; */
    width: 10%;


}

.main_div_cibil_second th {
    background-color: #FAFAFA;
    color: #667085;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding: 3px;
    border-right: 1px solid #F0F2F5;
}

.main_div_cibil_second td {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding: 5px;
}

.main_div_cibil_second {
    width: 97%;
}

.main_div_cibil_atble {
    width: 100%;
    margin: 10px;
}

.payment_hist_th {
    color: #667085;
}

.payment_hist_td {
    background-color: #FAFAFA;
    color: #667085;

}

.Payment_table_cibil {
    width: 99%;
}

.separator {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    /* Adjust the width of the separator */
    height: 80%;
    /* Adjust the height of the separator */
    background-color: black;
    /* Adjust the color of the separator */
    transform: translate(-50%, -50%) rotate(-45deg);
    /* Rotate the separator */
}

.memder_id_div {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    width: 100%;

}

.member_text {
    font-family: Roboto;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 24px;
    color: #667085;
}

.table_data {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}