.monthlyexpenses_container{
    margin-top: 30px;
    width: 100%;
}

.business_table {
    /* width: 700px; */
    flex: 1;
    height: auto;
    left: 17px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    /* opacity: 0px; */
}

.business_expenses{
    display: flex;
}
.personal_table {
    /* width: 715px; */
}

.p_div{
  width: Fixed (353px)px;
  height: 47px;
  top: 101px;
  left: 742px;
  padding: 12px 0px 0px 10px;
  gap: 4px;
  opacity: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  background: #E1F2FF;

  
}
.p_div {
    border-top: 1px solid #000; /* Add your desired border style */
  }


.chart{
    background: #FFFF;
}

.total_row {
    color: #FFA726; 
    font-weight: bold;
  }