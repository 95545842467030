.bank-row td {
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;
  background: #E6F7FF;
  border-right: none;
  font-weight: 600;
}

.bank_account_deatils_heading {
  display: flex;
  justify-content: flex-end;

}

.verify_img_bank {
  margin-left: 5px;
}

.manual_enter_block {
  color: rgba(39, 101, 143, 1);
  font-style: italic;
}

/* bank account aggsregator form css */

.bank_country_code_block {
  width: 20% !important;
  text-align: center !important;
  background: #f1f1f1 !important;
}

.bank_mobile_number_field {
  width: 80% !important;
}

.bank_initiated_message {
  color: red;
  margin-bottom: 16px;
}

.bank_account_get_block{
  margin-bottom: 16px;
}