.tarsil_container {
    border: 1px solid #e4e7ec;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    height: 50%;
    /* margin: 15px; */
    border-bottom: none;
  }
    .insurance_table td{
        color: #6E6E6E;
    }
    .insurance_table thead th{
        color: #667085 !important;
        font-size: 14px;
        border-radius: 0px !important;
        /* line-height: 22px; */
    }
    .trail_table{
        overflow-x: auto;
        border-top: 1px solid #e4e7ec;
     } 

     .technical_trail_status{
        font-size: 16px;
     }

     