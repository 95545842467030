@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.6s;
  /* Duration of the animation */
  animation-timing-function: ease-in;
  /* Speed curve of the animation */
  animation-delay: 0s;
  /* Delay before the animation starts */
  animation-fill-mode: forwards;
  /* Keeps the final state of the animation */
}