.fi_Views_main_container {
    margin: 20px 0px 20px 0px;
    border: 1px solid #e4e7ec;
}

.fi_views_description_heading {
    background: #E6F7FF;
    /* border-bottom: 1px solid #E4E7EC; */
    height: 46px;
    display: flex;
    justify-content: space-between;
}

.fi_views_overall_status_heading {
    background: #E6F7FF;
  
  
    height: 46px;
    display: flex;
    justify-content: space-between;
}
.overall_status_div{
  border: 1px solid #E4E7EC;
}


.select_field_rv {
    width: 100%;
}

.edit_icon_rv {
    color: #4880FF;
}

.delete_icon_rv {
    color: #E5233DB2;
}

.indentification_container {
    border: 1px solid #E4E7EC;
    width: 60%;
    margin-right: 44px;
    border-bottom: none;

}

.residenace_container {
    width: 60%;
      border: 1px solid #E4E7EC;
      border-bottom: none;
}


.vehicle_rv_continer {
    margin-top: 20px;
    border: 1px solid #E4E7EC;
}
.remark_seletc_rv{
    width: 300px !important;
 }
 .Upload_seletc_rv{
    width: 400px !important; 
 }
 .vehicle_fi_input_select{
    width: 150px !important;
    flex-shrink: 0 !important;
 }
 .add_vehicle_fi{
    margin-top: 20px;
    position: fixed;
 }
 .add_vehicle_button_ok{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
    position: fixed;
    bottom: 100px;
    right: 20px;
    left: 20px;
 }

.rv_button_submit_cancel {
    display: flex;
    flex-direction: row-reverse;
}
.more_dpocumnet_rv_form{
    margin-top: 20px;
}

.rv_input_button_submit_cancel {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
}

.fi_views_heading_name {
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

.fi_views_button_space {
    display: flex;
}

.fi_views_edit_button {
    display: flex;
    align-items: center;
    margin-right: 10px;

}

.fi_views_collapse_icon {
    margin: 12px 10px 10px 10px;
    cursor: pointer;


}

.legal_input_add_button {
    width: 130px;
    height: 30px;
    margin: 7px 8px 0px 100px;
    padding: 4px 15px 4px 15px;
    gap: 8px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background: #F0AD4E;
    color: #fff;
    cursor: pointer;
}

.person_detail_rv {
    /* margin: 20px; */
    margin-top: 20px;
    border: 1px solid #E4E7EC;
}

.overall_status_dot {
    width: 12px;
    margin: -4px 0px 0px 0px;

}
.fi_views_collapse_icon.no-border {
    border: none; /* Removes the border */
  }

.overall_status_dot.pos {
    color: #389E0D;
    /* Green for "Positive" status */
}

.overall_status_dot.neg {
    color: #FF4D4F;
    /* Red for other statuses */
}

.document_images_rv {
    flex: 0 0 11.1%;
    box-sizing: border-box;
    padding: 10px;

}

.pdf_rv_view {
    border: none;
    cursor: pointer;

}

.thumb_document_name {
    text-align: center;
    width: 100px;
    margin-top: 10px;
    
}

.thummb_rv_view {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.select_upload_fi_input {
    width: 250%;
}

.documne_ov_view {
    flex-wrap: 1;
    margin-right: 8px;
    width: 100%;
}
.fi_ovupload_input{

    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    
}

.fi_upload_input_form {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;

}

.rv_form_pdf_img {

    border: none;
    cursor: pointer;
}

.rv_form_img {
    width: 100px;
    height: 50px;
    cursor: pointer;

}

.ov_view_documnet_text {
    text-align: center;
    width: 100px;
    margin-top: 10px;
}

.uplad_buttom_rv_form {
    width: 176%;
}

.images_rv_form {
    display: flex;
    flex-wrap: wrap;
}

.images_rv_form_two {
    margin: 10px;
    position: relative;
    height: 100px;
}

.heading_rv_form {
    text-align: center;
    color: #1890FF;
}

.delet_icon_rv_upload {
    top: 0;
    right: 0;
    cursor: pointer;
    color: #E5233DB2;
    text-align: center;
    margin-top: 10px;
}


.overall_status_ov {
    padding: 10px;

}

.img_rv_ipnut_form {
    height: 50px;
    width: 100px;
    display: flex;
    justify-content: center;
}

.overall_status_ov_two {
    border: 1px solid #e4e7ec
}

.field-text {
    margin-bottom: 25px;
}

.field-text.single-field {
    color: #ccc;
    /* Color for single field */
}

.field-text.multiple-fields {
    color: #E5233DB2;
    /* Color for multiple fields */
}
.ov_uplad_pdf{
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}