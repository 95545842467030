.asset-seen-container {
    margin: 20px 0;
    border:1px solid #e4e7ec;
  }
  
  .property_description_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .asset-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }
  
  .asset-card {
    border: 1px solid #ADC6FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: auto;
    margin: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 40px;

  }
  
  .asset-image img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
  
  .asset-name {
    font-weight: 600;
    color:#5c8af5;
    margin-top: 10px;
  }
  