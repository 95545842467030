.event-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.event-block {
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.event-time {
  font-weight: bold;
  margin-right: 5px;
}

.event-text {
  flex: 1;
}
