.rcu_initiate_input_form_main_container {
  margin-top: 15px;
  /* border-radius: 5px; */
  border: 1px solid #E4E7EC;
}

.rcu_initiate_input_form_loading_div {
  width: 100%;
}
.rcu_initiate_input_customer_form_heading {
  background-color: #F1F1F1;
}

.rcu_initiate_input_customer_table_dedupe_check {
  width: 100%;
  margin-top: 20px;
}

.rcu_initiate_input_form_rcu_status_image {
  margin-left: 40px;
}

.rcu_initiate_input_form_rcu_status_sr_no {
  color: #00000080;
}

.rcu_initiate_input_form_document_block {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rcu_initiate_input_form_document_lable {
  margin-top: 30px;
}

.rcu_initiate_input_form_document_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.rcu_initiate_input_form_document_dummy_img {
  margin-top: 15px;
  cursor: pointer;
}

.rcu_initiate_input_form_document_dummy_pdf {
  cursor: pointer;
}

.rcu_initiate_input_form_document_rcu_status_remark {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.rcu_initiate_input_form_manual_deciation_contenet {
  margin-top: 15px;
  /* border-radius: 5px; */
  border: 1px solid #E4E7EC
}

.rcu_initiate_input_form_item-border {
  border-bottom: 1px solid #E4E7EC;
}

.rcu_initiate_input_form_empty_div {
  margin-top: 35;
  margin-bottom: 35;
}

.rcu_initiate_block {
  /* border-radius: 5px; */
  margin-top: 15px;
  border: 1px solid #E4E7EC
}

.rcu_initiate_bloack_heading {
  border-radius: 10px;
}


.rcu_initiate_input_form_overall_status {
  border-radius: 10px;
  margin-top: 10px;
}


.rcu_initiate_input_up_icon {
  margin: 1rem;
  transition: transform 0.3s ease;
}

.rcu_initiate_input_up_icon-rotated {
  transform: rotate(0deg);
}

.rcu_initiate_input_up_icon-inverted {
  transform: rotate(180deg);
}

.rcu_initiate_input_form_rcu_status {
  margin-top: 15px;
  /* border-radius: 5px; */
  border: 1px solid #E4E7EC
}

.rcu_initiat_input_property_divider {
  margin: 10px 10px 10px 10px;
}

.rcu_initiat_input_form_property_div {
  display: flex;
  flex-wrap: wrap;
}

.rcu_initiat_input_form_property_uid {
  margin: 10px;
  position: relative;
}

.rcu_initiat_input_form_property_picture_path {
  width: 75px;
  height: 75px;
  cursor: pointer;
}

.rcu_initiat_input_form_property_picture_label {
  margin: 5px;
  text-align: center;
  font-size: 12px;
  word-wrap: break-word;
  width: 75px;
}

.rcu_initiat_input_form_property_picture_file_name {
  top: 0;
  right: 0;
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.rcu_initiate_input_form_complete_remark {
  margin-top: 15px;
  border: 1px solid #E4E7EC;
}


.rcu_initiate_input_form_space_button {
  display: flex;
  flex-direction: row-reverse;
}

.rcu_initiate_input_submit_form {
  margin-top: 10px;
}


.rcu_initiate_input_form_item_border-active {
  border-bottom: 1px solid rgb(217, 217, 217);
}

.rcu_initiate_input_form_item_border-last {
  border-bottom: none;
}



.rcu_value_property_deviation-active {
  border-bottom: 1px solid rgb(228, 231, 236);
}

.rcu_value_property_deviation-last {
  border-bottom: none;
}



.rcu_initiate_input_up_icon {
  margin: 1rem;
  transition: transform 0.3s ease;
}


.rcu_initiate_input_up_icon-rotated {
  transform: rotate(0deg);
}

.rcu_initiate_input_up_icon-inverted {
  transform: rotate(180deg);
}



.rcu_initiate_input_up_icon {
  margin: 1rem;
  transition: transform 0.3s ease;
}


.rcu_initiate_input_up_icon-rotated {
  transform: rotate(0deg);
}


.rcu_initiate_input_up_icon-inverted {
  transform: rotate(180deg);
}


.rcu_property_documents_up_icon {
  margin: 1rem;
  transition: transform 0.3s ease;
}

.rcu_property_documents_up_icon-rotated {
  transform: rotate(0deg);
}

.rcu_property_documents_up_icon-inverted {
  transform: rotate(180deg);
}

.rcu_complete_remark_up_icon {
  margin: 1rem;
  transition: transform 0.3s ease;
}

.rcu_complete_remark_up_icon-rotated {
  transform: rotate(0deg);
}

.rcu_complete_remark_up_icon-inverted {
  transform: rotate(180deg);
}

.rcu_div_image {
  margin: 10px;
  position: relative
}

.rcu_image {
  width: 75px;
  height: 75px;
  cursor: pointer
}

.rcu_image_second_div {
  top: 0;
  right: 0;
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px
}

.rcu_image_first_div {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  word-wrap: break-word;
  width: 75px,
}

.rcu_initiate_table_form_heading_box {
  border-top: 1px solid rgb(228, 231, 236);
}

.rcu_initiate_input_form_main_container .ant-table-cell {
  word-wrap: break-word;
  white-space: normal;
}

/* **************************** */
.rcu_input_table_heading_label{
  width: 260px;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: left !important;
  padding: 5px;
}
.rcu_ibput_table_blue_heading {
  background: rgba(230, 247, 255, 1) !important;
  display: flex;
  justify-content: space-between;
}