.loan_summary_rcu_valuation_report{
    font-family: Roboto;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
 }
.main_technical_report {
    display: flex;
    justify-content: space-between;
}

.technicle_data {
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
    border-bottom: 1px solid #E4E7EC;
    margin: 10px 0;
}

.second_div {
    display: flex;
    justify-content: space-between;
}

.seocnd_div_container {
    display: flex;
    justify-content: space-between;
}

.Basic_card_technical {
    width: 100%;
    margin: 0px !important;
    display: flex;
    justify-content: space-between;
}

.cards-spacing {
    margin-left: 40px;
    margin-right: 40px;
}

.basic_card-content_technical {
    gap: 8px;
    height: auto;
    margin-top: 9px;
}

.data_technical {
    color: #000000b2 !important;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
}

.label_technical_lable {
    color: #00000080;
    padding: 5px;
    margin-left: 10px;
    width: 50%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    line-height: 25px;
    background-color: #FAFAFA;
}

.details_contanier {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.details {
    height: 31px;
    margin-top: 10px;
    border-bottom: 1px solid #E4E7EC;
    width: 100%;
}

.details_p {
    margin: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

.details_content_data {
    font-family: Roboto;
    background-color: #FAFAFA;
    font-size: 14px;
    font-weight: 600;
}

.firts_details {
    width: 50%;
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    margin-top: 15px;
}

.second_details {
    width: 50%;
    border: 1px solid #E4E7EC;
    margin-top: 15px;
    border-radius: 5px;
}

.container_technical {
    border-radius: 10px;
}

.valuation_details_blue {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 10px;
    height: 20px;
    margin-bottom: 10px;
}

.rcu_views_details_heading{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 12px;
    height: 20px;
    margin-bottom: 10px;
}

.rcu_details_blue {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin: 10px;
    height: 20px;
    margin-bottom: 10px;
}

.rcu_basic_card_content {
    gap: 8px;
    height: auto;
    margin-top: 13px;
    padding-top: 3px;
}

.label_rcu {
    color: #00000080;
    margin-top: 0px !important;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.address_prporty {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}

.tachnical_table {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.red_text {
    color: #1890FF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-right: 10px;
    text-decoration: underline;

}

.address_text_first {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

.address_text {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #667085;

}

.highlighted_red {
    color: #E74C3C;
    text-align: end;
    margin-top: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

.address_top_heading {
    display: flex;
    justify-content: space-between;
}

.address_heading {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #667085;
}

.checkbox {
    font-family: Inter;
    font-size: 4px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #9CA7AC;
    margin-left: 15px;
}

.img img {
    margin: 10px;
}

.tab-container {
    margin-left: 14px;
    margin-right: 15px;
    border: 1px solid #E4E7EC;
}

.loan_details_heading {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
}

.fi_subsection {
    margin-top: 10px;
    margin-right: 10px;
    border-top: 1px solid #E4E7EC;
    border-bottom: 1px solid #E4E7EC;
    background: #E6F7FF;
    border-right: none;
    font-weight: 600;
    padding: 10px;
}

.fi_subsection_heading {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: rgba(241, 241, 241, 1);
    margin-right: 10px;
    padding: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.box-2 {
    margin-left: 40px;
}


.loan_details_headings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 40px;
    margin-right: 40px;
}

.loan_details_subheading {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 10px;
    background: #bfb4b4;
    margin-right: 10px;
    padding: 4px;
}

.loan_details_content {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
}

.assets-container {
    background: #E6F7FF;
    width: 140px;
    padding: 10px;
    padding-left: 15px;
    border-radius: 5px;
    border: 2px solid #E6F7FF;
    margin-top: 10px;
}

.loan_details_section {
    margin-left: 40px;
    margin-right: 40px;
}

.heading-div {
    margin-top: 30px;
    border:1px solid #E4E7EC;
    height: 45px;
    border-bottom: none;
}

.rcu_buttons_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.rcu_deviation_button {
    border: 1px solid rgb(39, 101, 143);
    /* color: #F0AD4E; */
    color: #fff;
    background: rgb(39, 101, 143);
    padding: 5px;
}

.rcu_customer_edit_section {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
}

.rcu_customer_save {
    color: #4880FF;
    font-size: 14px;
}

.rcu_customer_cancel {
    color: #E74C3C;
    font-size: 14px;
}

.rcu_status_green {
    color: #389E0D;
    font-size: 14px;
}

.rcu_status_medium {
    color: #FA8C16;
    font-size: 14px;
}

.rcu_status_image {
    height: 50px;
    width: 50px;
}

.vendor_stage_initiated {
    color: #175CD3;
    font-size: 14px;
}


.vendor_stage_completed {
    color: #5925DC;
    font-size: 14px;
}

.rcu_img {
    display: flex;
    flex-direction: row;
}

.rcu_img_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.rcu-inside-container {
    margin-left: 10px;
    margin-right: 10px;
    /* padding-top: 10px; */
    padding-bottom: 10px;
}

.para {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 10px !important;
    margin-top: 6px !important ;
    
}

.second_para {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #AEAEAE;
    margin: 0px !important;
}


.label_technical {
    color: #00000080;
    margin-top: 0px !important;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.data_technical {
    color: #000000;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
}

.label_technical_lable {
    color: #00000080;
    padding: 5px;
    margin-left: 10px;
    width: 50%;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    line-height: 25px;
    background-color: #FAFAFA;
}

.container_technical {
    border-radius: 10px;
}

.Basic_card_content {
    margin-left: 40px;
    display: flex;
    flex-direction: row;
}

.rcu_value_property {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.rcu_value_property_heading {
    width: 260px;
    margin-top: 10px;
    margin-left: 15px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
}

.rcu_edit {
    gap: 3px;
    width: 60px;
    display: flex;
    flex-direction: row;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 1;
    padding-bottom: 2px;
    border-radius: 10px;
    border: unset;
    font-size: 12px;
}

.document_details_second_heading {
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 10px;
}

.label_technical-document {
    color: #00000080;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.rcu_value_property_deviation {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
}

.rcu_value_property_status {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
}

.customer_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 8px;
    background: rgba(241, 241, 241, 1);
    color: rgba(0, 0, 0, 0.5);
}

.valuation_details_blue_heading {
    background: #e1e4e9 !important;
    display: flex;
    justify-content: space-between;
}

.rcu_value_property_status-border {
    border-bottom: 1px solid #d9d9d9;
  }

  .rcu_value_property_deviation-border {
    border-bottom: 1px solid #d9d9d9;
  }
/* **************************************** */
.rcu_input_view_loading {
    margin: 0 15px 1 15px;
    border: 1px solid #ddd;

}

.rcu_input_view_loading_size {
    width: 100%;
}

.rcu_input_view_details_heading_name {
    flex: 1;
}

.rcu_input_view_details_heading_name_items {
    flex:2;
}

.rcu_input_view_details_heading_check {
    flex: 1;
    text-align: center;
}

.rcu_input_view_details_dedup_deading {
    text-align: center;
}

.rcu_input_view_details_select_option {
    width: 90% !important;
}

.rcu_input_view_status {
    color: rgba(0, 0, 0, 0.85);
}

.rcu_input_view_status_image {
    cursor: pointer;
    margin-bottom: 10px;
   
}

.rcu_input_view_file_name {
    font-size: 25px;
    margin-top: 15px;
    cursor: pointer;
}

.rcu_input_view_red_color {
    color: red;
}

.rcu_input_view_edit {
    color: #1890FF;
}

.rcu_input_view_empty_data {
    margin-top: 35px;
    margin-bottom: 35px;
}

.rcu_input_view_overall_status {
    color: #52C41A;

}

.rcu_input_view_remark {
    color: #4D4D4D;
}

.rcu_input_view_document {
    padding: 10px;
}


.rcu_input_view_document_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
}

.rcu_input_view_document_file {
    height: 75px;
    width: 75px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.rcu_input_view_file_name {
    margin-top: 5px;
    text-align: center;
}

.rcu_view_button_edit img {
    width: 25px;
    cursor: pointer;
}

.rcu_filename{
    margin-top: 25px;
    text-align: center;
}
/* collapse css */
.up-outlined {
    margin: 1rem;
    transition: transform 0.3s ease; 
  }
  
  .up-outlined-visible {
    transform: rotate(0deg);
  }
  
  .up-outlined-hidden {
    transform: rotate(180deg);
  }
  
  .rcu_value_property {
    padding-left: 8px;
  }
  /* ************************************* */
  

/* rcu input form */
/* manual Deviation Form */

.rcu_input_form_row{
  margin-bottom: 16px;
}

.rcu_form_butoon{
    display: flex;
    flex-direction: row-reverse;
}

.rcu_property_input_form{
    display: flex;
    flex-wrap: wrap;
  

}

.rcu_property_input_form_document_path{
   display: flex; 
   flex-direction: column;
   align-items: center;
   position: relative;
   margin-bottom: 20px;
   text-align: center;
   margin-left: 10px;
}

.rcu_property_input_form_document_image{
    width: 75px;
    height: 75px;
}

.rcu_property_input_form_document_delete_button{
    margin-top: 5px;
}

.wrap-text {
    word-wrap: break-word;
    white-space: normal;
  }

.valuation_details_heading .ant-table-thead th {
    height: 30px;  
    line-height: 30px;
  }



.rcu_details_heading_grya{
    border-top: 1px solid rgba(228, 231, 236, 1);
    display: flex;
    justify-content: space-between;
    background: rgba(241, 241, 241, 1);
    padding-bottom: 30px;
}


@media screen and (max-width: 692px) {
    .details_contanier {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        flex-direction: column;
    }

    .firts_details {
        width: 100%;
    }

    .second_details {
        width: 100%;
    }
}



/* ***************************************** */


.rcu_views_main_container{
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 50%;
    margin: 15px 0px 15px 0px;
}

.rcu_view_details_form_tables{
    border-bottom: none !important;
}

.rcu_view_deviation_button{
    background-color: #276690 !important;
    color: white !important;
    border: 1px solid #276690 !important  ;
  }

.rcu_view_gray_table_heading{
    display: flex;
    justify-content: space-between;
    background: rgba(241, 241, 241, 1);
    height: 55px;
    padding-bottom:10px;
}
  .rcu_view_table_heading_label{
    width: 260px;
    margin-top: 18px;
    margin-left: 15px;
    text-align: left;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    text-align: start;
}

.rcu_upload_document_container {
    display: "flex"; 
    flex-direction: "column"; 
    align-items: "center"
}

