.overall_main_container {
  max-width: 100%;
  border: 1px solid #ddd;
  /* overflow-x: scroll; */
  /* margin: 15px; */
}
.overall{
  margin: 10px;
}
.overall_status {
  /* width: 1159px; */
  border: 1px solid #ddd;
  border-radius: 6px;
  margin: 10px;
}
.dedupe_overall_status {
  margin: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #389E0D;
}

.positive {
  /* margin: 0px 0px 0px 15px; */
  /* height: 30px; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #52c41a;
}
