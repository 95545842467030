.income_calculations_container {
    /* margin-top: 20px; */
    /* border: 1px solid #f0f0f0; */
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
}

.income_title {
    background-color: #EFE0FF66;
    padding: 10px;
    /* border-bottom: 1px solid #f0f0f0; */
    color: #00000099;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;

}

.income_table {
    margin: 10px;
    border: 1px solid #f0f0f0;
    border-bottom: none;
    border-radius: 4px;
}


.ltv-content {
    padding: 10px;
}

.ltv-row {
    margin: 0px 0px 10px 10px;
}

.income_table {
    overflow-x: auto; 
  }
  
  .table-responsive {
    overflow-x: auto; /* Enables horizontal scrolling */
}

.heading_text_cam_income_details {
    background-color: #e6f7ff;
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4e7ec;
}
.heading_text_cam_income_details.no-border{
    border-bottom: none;
}

.ltv_calculation_heading_text{
    background-color: #e6f7ff;
    font-weight: 600;
    padding:  10px;
    display: flex;
    /* border-radius: 5px; */
    justify-content: space-between;
}

.credit_transaction_details_add_img img{
    cursor: pointer;
    height: 25px;
}