
.property-matching-container{
  max-width: 100%;
  /* overflow-x: hidden; */
}

.property_custom_table{
  border: 1px solid  #ddd;
  width:100% ;
  /* margin: 0px 15px 0px 15px ; */
  padding: 10px;
  border-collapse: collapse;
}

.customer_name_column{
  width: 300px; 
}
.customer_type_column{
  width: 285px;
}
.rule_name_column{
  width: 285px;
}
.match_percentage_column{
  width: 288px;
}

.first-row {
  background-color: #E1F2FF;
  color: blue; 
  padding: 10px;
  font-weight: 600px;
}

.property-table-data{
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 18px;
}



.no_match_found{
 /* width: 1170px;   */
 height: 129px; 
 font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: #000000;
margin: 5px 0px 0px 10px;

}