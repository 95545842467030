.approval_details_tabs_form{
    margin-top: 5%;
}

.approval_details_tabs_form_row{
    margin-bottom: 16px;
}

.approval_details_tabs_form_button{
    float: right;
}