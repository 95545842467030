body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Add this CSS to your stylesheet */
.dataTables-container {
  position: relative;
  /* overflow-x: auto; Enable horizontal scrolling if needed */
}

.ant-pagination-options {
  position: absolute;
  bottom: 0;
  left: -16px;
  transform: translate(0, 0);
  z-index: 1000;
  /* Ensure the pagination controls appear on top */
}

.ant-pagination-options-size-changer {
  display: flex;
  flex-direction: row-reverse;
  /* Display the page size selector on the right */
  align-items: center;
}

.ant-pagination-options-size-changer .ant-select-selector {
  border-radius: 2px;

  /* Add some spacing between the page size selector and other controls */
}



/* Optional: To hide horizontal scrollbar if it's not needed */
.dataTables-container::-webkit-scrollbar {
  display: none;
}