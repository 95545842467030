.deviation_heading_text {
    background-color: #E6F7FF;
    font-weight: 600;
    padding: 5px 10px; 
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    
}

.deviation_main_container{
 display: flex;
 justify-content: space-between;
 background-color: #E6F7FF;   
}
.deviation_main_container.with-border {
    border-bottom: 1px solid #e4e7ec;
}

.deviation_main_container.no-border {
    border-bottom: none;
}


