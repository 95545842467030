/* react-mentions.css */
.mentions-input__control {
  font-size: 14px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.text-base {
color:black !important
}
.chat_title {
  display: flex;
  justify-content: space-between; /* Aligns items with space between them */
  align-items: center; 
  height:7vh;
}

.chat-header {
  background-color:rgb(98, 116, 137);
  border: none;
}

.mentions-input__highlighter {
  overflow: hidden;
}

.mentions-input__input {
  overflow: auto;
}

.mentions-input__mention {
  background-color: #eceff0;
}


.badge-color {
  color: #ffff;
}

.custom-control {
  font-size: 14px;
  line-height: 1.5;
  padding: 0px 0px;
  width: 269px;
  max-height: 150px;
  margin-top: 10px;
  overflow-y: auto;
  background-color: rgba(98, 116, 137, 1);
  transition: border-color 0.3s ease;
}

.custom-control__input {
  border:none !important
}

.custom-control:hover {
  border-color: #4096ff;
}

.custom-control:focus {
  outline: none;
}

.custom-control textarea::placeholder, 
.custom-control input::placeholder {
  color: white !important;
  font-size: 16px;
  text-align: left;
  margin-top: 10px !important;
}

.suggestions-list {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  max-height: 250px;
  overflow: auto;
  margin-top: 8px;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 600px;
  background-color: #550808;
  z-index: 1000;
}

.suggestions-item {
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.suggestions-item:last-child {
  border-bottom: none;
}

/* Explicit scrollbar styles for cross-browser compatibility */
.custom-chat-scrollbar::-webkit-scrollbar {
  width: 10px; /* Slightly wider for better visibility */
}

.custom-chat-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-chat-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(98, 116, 137, 0.7);
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Creates a border around the thumb */
}

.custom-chat-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(98, 116, 137, 0.9);
}

/* Fallback for Firefox */
.custom-chat-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(98, 116, 137, 0.7) #f1f1f1;
}
