.technical_valuation_first_container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.technical_valuation_second_container {
  display: flex;
  justify-content: space-between;
  width: 1100px;
}

.technical_heading {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.technical_valuation_status {
  /* width: 216px; */
  padding: 0px 10px 0px 0px;
  color: #389e0d;
  margin-left: 15px;
  display: flex;
  background: #f6ffed;
  border-radius: 16px;
  align-items: center;
  font-family: "Roboto";
  font-weight: "500";
  word-wrap: "break-word";
}

.technical_valuation_second_container {
  margin-top: 15px;
}

.technical_valuation_second_container_heading_two {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000b2;
}

.technical_tables_container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}

.technical_table_one {
  border: 1px solid #b8b3b373;
  border-radius: 8px;
  width: 442px;
  /* height: 377px; */
  top: 214px;
  left: 59px;
  gap: 0px;
}

.technical_table_one_heading {
  border-bottom: 1px solid #b8b3b373;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin: 10px 0px 0px 0px;
}

.technical_table_data {
  border-bottom: 1px solid #b8b3b373;
  margin: 10px 0px 10px 0px;
}

.technical_table_data_heading {
  /* border: 1px solid red; */
  width: 350px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #00000080;
  margin-left: 20px;
}

.technical_table_data_value {
  width: 350px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000b2;
  margin-left: 100px;
}

.technical_table_two {
  border: 1px solid #b8b3b373;
  border-radius: 8px;
  width: 700px;
  /* height: 377px; */
  top: 214px;
  left: 59px;
  gap: 0px;
}

.technical_valuation_details_tabs{
  margin: 20px;
}
.valuation_details_blue {
  margin-left: 15px !important;
}


.rcu_details {
  background: rgba(230, 247, 255, 1);
  display: flex;
  justify-content: space-between;
}

 