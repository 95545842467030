.history_icon {
    margin: 0px 5px;
    cursor: pointer;
    height: 27px;
}

.history_modal_close_button {
    margin: 20px 0px 0px 92.3%
}

.field_name{
    margin: 35px 0px 15px 10px !important;
}