.applicant-detail-form-content{
    margin-bottom: 24px;
}

.applicant-detail-date-field{
  width: 100%;
}

.confirm-and-save-button{
    display: flex;
    justify-content: flex-end;
    
}