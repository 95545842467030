.input_document_main_container {
  border: 1px solid #e4e7ec;
}
.document_details_table_vehicle {
  border: 1px solid #e4e7ec;
  border-radius: 5px;
}

.document_details_table {

  /* margin: 0px 10px 10px 10px ; */
  padding: 10px 10px 10px 10px;
}

.document_detail_heading {
  background: #E6F7FF;
  /* border: 1px solid #E4E7EC; */
  height: 46px;
  display: flex;
  justify-content: space-between;
}

.legal_input_form{
  border-bottom: none;
}
.legal_input_form_top_block{
  border-top: none;
}

.legal_input_document_box{
  background: #E6F7FF;
    height: 46px;
    display: flex;
    justify-content: space-between;
}

.legal_input_document_Essential{
  border-right: 1px solid #E4E7EC;
  border-top:  1px solid #E4E7EC;
  border-left: 1px solid #E4E7EC;
}



.property_description_data_button {
  width: 132px;
  height: 30px;
  margin: 7px 0px 0px 100px;
  padding: 4px 15px 4px 15px;
  gap: 8px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  background: rgb(39, 101, 143);
  color: #fff;
  cursor: pointer;
}

.property_Identification_data_button {
  width: 215 !important;
  height: 30px;
  margin: 7px 0px 0px 100px;
  padding: 4px 15px 4px 15px;
  gap: 8px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  background: rgb(39, 101, 143) !important;
  color: #fff !important;
  cursor: pointer;
}





.valuation_Proerty_images_container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 50%;
  margin-top: 15px;
}


.document_images {
  display: flex;
  flex-wrap: wrap;

}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
}


.image-container {
  flex: 0 0 14%;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;

}

.document_image {
  width: 75% !important;
  height: 50% !important;
  margin-left: 15px;
}

.document_picture_label {
  text-align: center;
}

.delete-icon {
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

.edit_icon {
  color: #4880FF;
}

.input_document_table {
  border: 1px solid #E4E7EC
}

.legal_document_table_views{
  border: 1px solid #E4E7EC; 
  border-bottom: none;
}

.input_document_property_image {
  flex: 0 0 11.1%;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
}

.input_document_property_type {
  width: 65% !important;
  height: 80% !important;
  margin-left: 23px;
  cursor: pointer;
}

.input_document_property_path {
  width: 65% !important;
  height: 65% !important;
  margin-left: 23px;
  cursor: pointer;
}

.input_document_property_picture_name {
  text-align: center !important;
  margin: 5px;

}

.document_image_label_name {
  text-align: center;
}


/* Document form */
.document_date {
  width: 100%;
}

/* General styles for the delete icon */
.delete-icon {
  color: #e5233db2;
  margin-bottom: 25px;
  cursor: pointer;
}

.delete-icon-disabled {
  color: #ccc;
  margin-bottom: 25px;
  cursor: not-allowed;
}

.slecte_more_document {
  margin-top: 20px;
}

.document_space_button {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.document_disbursement_table {
  overflow-x: auto;
}


.legal_valuation_document_property_picture_container {
  border: 1px solid #E4E7EC;
  /* border-radius: 4px; */
  border-top: none;
}




.legal_views_document_container{
  margin: 0px 0px 15px 0px;
  border: 1px solid #E4E7EC;
  border-radius: 5px;
}

.legal_manual_deviation_container{
  margin: 0px 0px 15px 0px;
  /* border: 1px solid #E4E7EC; */
  border-radius: 5px;
}

.legal_view_document_button{
  margin-top: 6px;
  margin-right: 10px;
  background-color: #276690 !important;
  color: white !important;
  border: 1px solid #276690 !important  ;
}

.legal_document_view_form_data {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}


.ant-table-cell {
  text-align: left; 
  white-space: normal; 
  word-wrap: break-word;
  overflow-wrap: break-word; 
}
.ant-table-tbody > tr > td {
  max-width: 300px; 
  word-wrap: break-word;
  overflow-wrap: break-word;
}


.legal_document_view_heading_name{
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.legal_document_view_heading_name {
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

@media (max-width: 768px) {
  .legal_document_view_heading_name {
    font-size: 14px; 
    line-height: 18px;
    margin: 5px 8px; 
  }
}

@media (max-width: 480px) {
  .legal_document_view_heading_name {
    font-size: 14px; 
    line-height: 16px;
    margin: 1px 6px;
  }
}
