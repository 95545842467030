@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto+Condensed&family=Roboto:ital,wght@1,100&display=swap');



* {

    font-family: 'Roboto';
    /* font-family: 'Roboto Condensed'; */

}

.ant-typography {
    font-family: 'Roboto';

}
