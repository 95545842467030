.heading_Second{
  
    width: 230px;
  }
  .data_table_second{
    width: 350px;
  }
  
    .existing_row td {
  
      border-top: 1px solid #E4E7EC;
      border-bottom: 1px solid #E4E7EC;
      background: #E6F7FF;
      border-right: none;
      font-weight: 600;
    
    }
    
    .bank-pd-row td {
      background-color: #F1F1F1;
      border-right: none;
      font-weight: 600;
    }

    .existiing_deatils_heading{
      display: flex;
      justify-content: flex-end;
    
    }
    .verify_img_existing{
      margin-left: 5px;
    }
    .select_esutsting{
      width: 100%;
    }
  
  
  
    