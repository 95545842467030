.full_container {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.section1 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box_content {
    width: 592px;
    height: 288px;
    margin: 48px;
    padding: 40px 14px 30px 70px;
}

.heading_section {
    width: 560px;
    height: 172px;
}

.headings h3 {
    width: 560px;
    height: 24px;
    color: #1890ff;
    text-decoration: none;
}

.headings h1 {
    margin-top: 10px;
    width: 660px;
    height: 53px;
    font-weight: bold;
}

.paragraph {
    margin-top: 10px;
    width: 450px;
    height: 60px;
}

.refresh_section {
    width: 295px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

}

.go_back_btn {

    width: 131px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
}
.go_back_btn img{

    width: 17px;
    height: 17px;
    margin-top: 4px;
}

.take_home_btn {
    font-size: 16px;
    font-weight: 500;
    width: 152px;
    height: 48px;
    border-radius: 8px;
}
.section2 {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
}

.section2 .img1 {
    width: 700px;
    height: 500px;
}

.section2 .img2 {
    position: absolute;
    top: 70px;
    left: 90px;
    width: 828.63px;
    height: 440px;
}
