
.otc_pdd_payment_heading {
    background: #e6f7ff;
    border-bottom: 1px solid #e4e7ec;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .otc_pdd_payment_heading.no-border {
    border-bottom: none;
  }
  

.otc_pdd_payment_description_data {
    font-weight: 600;
    flex: 1;
    /* white-space: nowrap;  */
  }

  .otc_pdd_stage {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 10px;
   
  }
  .edit_icon_pdc{
    color: blue;
  }
  .add_bank_account_div{
    cursor: pointer;
    
  }
  .pdc_div_container{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 10px;
  }
  .bank_button_pdc{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 10px;
  }
  .delete_icon_pdc{
    color: red;
  }

  .go_button{
    margin: 3px;
  }
  .export_button{
    color: green;
    margin: 3px;
  }
  .otc_pdd_select_stage {
    min-width: 150px;
    margin-right: 5px;
  }
  
  .otc_pdd_go_button {
    margin-right: 10px;
  }
  
  
  @media (max-width: 768px) {
    .otc_pdd_payment_heading {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      /* padding: 10px 0; */
    }
  
    .property_description_data {
      margin-bottom: 10px;
    }
  
    .otc_pdd_stage {
      justify-content: flex-start;
      margin-top: 15px;
      width: 100%;
    }
  
    .otc_pdd_select_stage,
    .otc_pdd_go_button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .otc_pdd_stage {
      flex-direction: column;
      align-items: flex-start;
    }
  
  }