.hdfc-insured-customer-id{
    display: none;
}

.hdfc-insured-date-picker{
    width: 100%;
}

.hdfc-occupation-ntionality-customer{
    margin-bottom: 16px;
}

.hdfc-contact-91{
    width: 20% !important;
    text-align: center !important;
    background: #f1f1f1 !important;
}

.hdfc-contact-number{
    width: 80% !important;
}

.hdfc-email-applicanttype{
    margin-bottom: 16px;
}