.loan_fiinition_heading {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000B2;


}


.loan_fiinition {
  display: flex;
  justify-content: space-between;
  background-color: #E6F7FF;
  height: 45px;
  padding: 10px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 1px solid #0000000F;
}

/* customTableStyles.css */
.no-radius-table .ant-table {
  border-radius: 0 !important;
}

.no-radius-table .ant-table-container {
  border-radius: 0 !important;
}

.no-radius-table .ant-table-thead > tr > th {
  border-radius: 0 !important;
}

.no-radius-table .ant-table-tbody > tr > td {
  border-radius: 0 !important;
}



.scrollable-table-container {
  overflow-x: auto;
}

.scrollable-table {
  min-width: 1000px; /* Adjust this width based on your table columns' total width */
}