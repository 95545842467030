.heading_Second {
  width: 230px;
}
.data_table_second {
  width: 350px;
}

.investment-row td {
  border-top: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
  background: #E6F7FF;
  border-right: none;
  font-weight: 600;
}

.investment-pd-row td {
  background-color: #f1f1f1;
  border-right: none;
  font-weight: 600;
}
.investment_deatils_heading{
  display: flex;
  justify-content: flex-end;


}
.verify_img_investment{
  margin-left: 5px;
}
.verify_img_vehicle{
  margin-left: 5px;
}
.select_investment{
  width: 100%;

}
.all_icon_vehicle{
  display: flex;
  justify-content: flex-end;
}

.investment-form-container {
  overflow: visible !important;
}

.ant-picker-dropdown {
  position: fixed !important;
}  
 
.select_investment {
  width: 100% !important;
}

 
.ant-tabs-tabpane {
  overflow: visible !important;
}