.employment-row td {

  background: #E6F7FF;
  border-right: none;
  font-weight: 600;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;

}
.employment_tab{
  display: flex;
  justify-content: flex-end;
}
.employement_button{
  display: flex;
  flex-direction: row-reverse;
}

.employmwnt_form_row{
  margin-bottom: 16px;

}
.select_filed{

  width: 100%;
}
.employment-pd-row td {

  background: #F9FAFB;
  border-right: none;
  font-weight: 600;
}

.selfemployment-row td {

  background: #E6F7FF;
  border-right: none;
  font-weight: 600;
  border-top: 1px solid #E4E7EC;
  border-bottom: 1px solid #E4E7EC;

}

.selfemployment_heading {
  background-color: #E6F7FF;
}

.selfemployment_top_heading {
  background-color: #E6F7FF;
}

.selfemployment-pd-row td {

  background: #F9FAFB;
  border-right: none;
  font-weight: 600;
}

.second_employment_div {
  width: 360px;
}

.second_employmet_heading {
  position: sticky;
  left: 0;
  border-bottom: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06));
  background: #FAFAFA;
  color: rgba(0, 0, 0, 0.70);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 220px;
}

.employment_highlighted_header {
  background-color: #FFFFEE;
}

/* .employment_scroll_container {
  overflow-x: auto;
  max-width: 100%;
  margin-bottom: 20px;
} */
.employement_row_form{
  margin-bottom: 16px
}
.employment_salaried_input{

  margin-top: 20px;
}

.employment_detail_address_section{
  display: flex;
}
