.message-bubble {
  position: relative;
  max-width: 70%;
  font-size: 28px;
  overflow: visible; /* No scrollbars */
  white-space: normal; /* Allow text wrapping */
  word-wrap: break-word; /* Break words if necessary */
  word-break: break-word; /* Handle long words */
}

.message-bubble-short {
  width: 80px;
}

.message-bubble-current-user {
  margin-left: 15px;
  margin-right: 10px;
}

.message-bubble-other-user {
  margin-left: 5px;
  margin-right: 10px;
}

.message-bubble-tail {
  position: absolute;
  left: -8px;
  top: 18px;
  width: 12px;
  height: 12px;
  clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
  background-color: rgba(98, 116, 137, 1);
}

.message-content {
  padding: 3px;
  border-radius: 4px;
  text-align: left;
  display: flex;
  flex-wrap: wrap; /* Ensure content wraps */
  font-size: 10px;
  font-family: "Open Sans";
}

.message-content-action {
  background-color: white;
  color: black;
}

.message-content-current-user {
  background-color: #e7e6e6;
  color: black;
}

.message-content-other-user {
  background-color: rgba(98, 116, 137, 1);
  border: 0px solid #252568;
  color: white;
}

