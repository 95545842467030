.miscellanious_details_basic_card {
  margin-bottom: 30px;
}

.miscellaneous_details_basic_card_container {
  border: 1px solid #E4E7EC;
}

.miscellaneous_details_content_block{
  border-top: none;
}

.miscellaneous_details_vendor_table{
  border-bottom: none;
}

.miscellaneous_provision_block{
  border-bottom: none;
}
.provision_table_one {
  margin-top: 40px;
  width: 350px;
  background-color: #FAFAFA;
  /* top: 214px; */
  /* left: 59px; */
  gap: 0px;
  margin-left: 10px;

}

.provision_table_data_value {
  width: 350px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000B2;
}

.miscellaneous_status {
  width: 73px;
  margin-left: 15px;
  display: flex;
  background: #F6FFED;
  border-radius: 16px;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.miscellaneous_positive {
  color: #389E0D;
}

.miscellaneous_negative {
  color: #FF4D4F;
}

.miscellaneous_checkbox{
  margin-right: 8px;
}

.miscellaneous_vendor_table{
  border:1px solid #E4E7EC;
 border-bottom: none;
  
}

.miscellaneous_vendor_table_fixed{
 border-bottom: none;
}
_.miscellaneous_positive_status{
  margin: 2px 5px;
}
.miscellaneous_positive_status img {
  display: inline-block;
}


/* **************************************** */

/* .Miscellaneous_details_Forms */

/* Styles for the provisionQuillEditor */
.provision_quill_editor{
  height: 130px !important;
  border: 1px solid  #d9d9d9;
  border-radius: 2px;
}

.provision_space_button{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.valuer_certification_remark{
  margin-top: 5px;
}

.valuer_certification_remark_label{
color: #00000080;
}

.valuer_certification_checkbox{
  margin-right: 8px;
}

.valuer_certification_space_button{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 300px;
}


.decision_question_heading_text{
  background-color: #e6f7ff;
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}



/* ************************** */
.legal_miscellaneous_details_views_container{
  margin: 0px 0px 15px 0px;
  border: 1px solid #E4E7EC;
  border-radius: 5px;
}


.legal_miscellaneous_details_blue_heading{
  background: #E6F7FF;
  height: 46px;
  display: flex;
  justify-content: space-between;
}