.consumer_btn {
    color: #FA8C16;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-radius: 8px;
    border: none;
    border: 1px solid #FFD591;
    background: #ffedd5;
    margin-left: 10px;
    padding: 0px 5px 0px 5px;
}

.personal_btn {
    color: #13C2C2;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-radius: 8px;
    border: none;
    border: 1px solid #87E8DE;
    background: #e6f5f5;
    margin-left: 10px;
    padding: 0px 5px 0px 5px;
}

.commercial_btn {
    color: #722ED1;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-radius: 8px;
    border: none;
    border: 1px solid #D3ADF7;
    background: #F9F0FF;
    margin-left: 10px;
    padding: 0px 5px 0px 5px;
}

.highmark_table thead th {
    color: #667085 !important;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0px !important;
    line-height: 22px;
    background-color: #FAFAFA;

}

.highmark_table thead td {
    color: #667085 !important;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

.highmark_table thead tbody tr td {

    text-align: left;

}

.highmark_table thead tr th {
    text-align: left;
    background-color: #FAFAFA;
}

.ant-table-wrapper.css-dev-only-do-not-override-kn4mev .ant-table-thead>tr>th,
.ant-table-wrapper.css-dev-only-do-not-override-kn4mev .ant-table-thead>tr>td {
    background-color: #FAFAFA
}

.highmark_table tbody tr td {
    text-align: left;
    /* transition: background 0.2s, border-color 0.2s; */
    /* border-bottom: 1px solid #f0f0f0; */
}

.disclaimer_div {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: #00000080;
    margin: 10px;
}

.summary_para {
    width: 200px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    color: #00000080;
    margin-left: 10px;
}

.Column_group_title {
    color: #667085;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;

}
.second_div_employment {

    width: 100%;
}
.Sanction_div_highmark_second {
    width: 450px;
    display: flex;
    justify-content: space-between;
    margin: 12px 12px 12px 12px;
   

}
.amt_hiighmark{
    margin: 8px;

}
.new_acc_higmark{
    display: flex;
    justify-content: space-between;
}

.Amt_higmark{

    margin: 8px;
}

.italicfont_div_coniantenr {
    border: 1px solid #F0F2F5;
    margin-top: 10px;
    border-radius: 5px,

}

.Appendix_div {
    width: 43%;
    border-right: 1px solid #F0F2F5;
    padding-left: 10px;
}

.italicfont_div {

    display: flex;

    justify-content: space-between;

    margin: 16px 12px 16px 12px;


}

.italicfont {
    color: #00000080;
    font-style: italic;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 550;
}

.last_paragraph {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: #00000080;
}

.para_highmark {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #101828;
    margin: 10px;


}

.div2_container {
    margin-top: 10px;
}


.variation_key {
    color: #04366F;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;

}



.reported_div {
    border-bottom: 0.5px solid #0000000F;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #667085;
    text-align: right;
    display: flex;
    justify-content: space-between;
    padding: 12px;

}
.amt_hiighmark_text{
   color: #667085; 
}
.amt_hiighmark_text_two{
    color: #000000B2;
}
.value_lable {
    color: #000000B2;
    width: 500px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}

.reported_data {
    color: #000000B2;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
}


.customer_div {
    margin: 10px 10px 10px 0px;
}

.customer_lable {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #667085;
    margin-left: 10px;

}

.customer_address {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000B2;
    margin-left: 60px;
   
}
