
.id-wrapper{
  background-color:#fff;
  max-width: 400px;
  margin:  auto;
}
.id-wrapper-header{
  height: 150px;
  background-image:url("../assets/header.png") ;
  background-size: cover;
}
.id-wrapper-body{
  height: 386px;
  background-image:url("../assets/id-wrapper-body.png") ;
  background-size: cover;

}
.id-wrapper-footer{
  height: 85px;
  background:#00467a;
  background-size: cover;
}
.id-wrapper-footer-green{
  height: 18px;
  background:#009268;
  background-size: cover;
}
h1{
  font-size: 1.5rem;
  font-weight: bold;
}
h3{
  font-size: 1rem;
  font-weight: bold;
}

hr {
margin-top: 0.5rem;
margin-bottom: 0.5rem;
border: 0;
border-top-width: 0px;
border-top-style: none;
border-top-color: currentcolor;
border-top: 2px solid #009268;
}

label{
font-size: 18px;
}


.profile-card__img {
width: 125px;
height: 125px;
margin-left: auto;
margin-right: auto;
transform: translateY(-50%);
border-radius: 50%;
overflow: hidden;
position: relative;
z-index: 4;
}

.profile-card__img img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
border-radius: 50%;
}


.details{
/*  background:lightblue;*/
transform: translateY(-12%);
width: 350px;
margin: auto;
}

h5{
color:#46e8b6;
font-weight: bold;
font-size: 16px;
}

.id-wrapper-footer p {
  color: #fff; 
  font-size: 11px;
}

.id-wrapper-footer-green p {
  color: #fff;  
  font-size: 11px;
}

.id-wrapper-footer-center {
text-align: center;
}

/* .id-wrapper-footer-heading {
margin-left: 2rem;
} */

@media print {
.no_print {
  display: none !important;
}
}