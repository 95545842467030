.document_details {
    background: rgba(230, 247, 255, 1);
    display: flex;
    justify-content: space-between;
}

.document_details_blue {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 10px;
    line-height: 22px;

}
.upload_documnet_technical{
    margin-right: 8px;
    width: 100%;
    flex: 1;
 
}

.selct_field_observation {
    width: 100%;
}
.marketabilit_selcte{
    margin-top: 65px;
    width: 100%;
}
.commercial_selcte{
    margin-top: 45px;
    width: 100%; 
}
.house_select_field_observation{
    width: 135% !important;
 
}
.risk_slect_filed{
    width: 100%;
    margin-top: 22px;
}
.slect_road_widening{
   margin-top: 15px;
   width: 100%; 
}
.selct_field_technical_road {
    width: 100%;
    margin-top: 22px;
}

.basic_button {
    margin-right: 30px;
    margin-top: 5px;
}

.basic {
    margin-bottom: 10px;
}


.documnet_edit {

    margin-top: 10px;
}

.documnet_images {
    margin: 10px;
}
.documnet_images_technical{
    display: flex;
    flex-wrap: wrap;
}
.documnet_images_technical_images{
    margin: 10px;
    position: relative;
}
.documnet_images_technical_file{
    width: 100px;
    height: auto;
}
.address_property_save_cancle{
    display: flex;
    flex-direction: row-reverse;
}
.documnet_images_technical_file_delete{
    top: 0;
    right: 0;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}
.technical_picture{
    width: 100px;
    height: 100px;
}
.technical_picture_heading{
    text-align: center;
    color: #1890FF;
}
.document_details_data_td {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    width: 299px;
    color: rgba(0, 0, 0, 0.7);
    /* border: 1px solid rgba(228, 231, 236, 1); */

}


.document_details_data_td_heading {
    width: 450px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 10px;

}


.document_details_data_td_heading_first {
    width: 360px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    /* margin-top: 10px; */
    margin-top: 10px;
    padding-top: 10px;
    /* margin-left: 10 px; */
    /* padding: 10px; */

}

.document_details_second_heading {
    display: flex;
    flex-direction: column;
}

.document_details_second_data {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #505050;
    margin-left: 0px;
}

.document_details_data_text_td {
    width: 360px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 10px;
    margin-left: 10px;
}

.valuation_details_data_last {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(250, 173, 20, 1);
    margin: 10px 0px 10px 10px;
}

.valuation_content {
    margin-left: 14px;
    margin-right: 14px;
}

.document_images {
    display: flex;
    flex-wrap: wrap;
}
.remarks_addres_technical{
    margin-left: 10px;
    margin-top: 10px;
}

.valuation_Proerty_images_container {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 50%;
    margin-top: 15px;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
}

.image-container {
    flex: 0 0 14%;
    box-sizing: border-box;
    padding: 5px;
}

.image {
    width: 100%;
    height: 50%;
}

.delete-icon {
    color: #E5233DB2;
    cursor: pointer;
    text-align: center;
}

.technical_documentation_descreption {
    background-color: #FAFAFA;
}

.technical_documentation_descreption_data {
    width: 450px !important;
    padding: 5px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    width: 299px;
    color: rgba(0, 0, 0, 0.7);
}

.save_cancle_button_observation {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
}


.technical_document_image {
    width: 100px;
    height: 100px;
    cursor: pointer;
}

.technical_document_image {
    text-align: center;
    width: 100px;
    margin-top: 10px;
}

.remark_star_mark {
    color: red;
}

.documents_provided_technical{
    margin-bottom: 10px;
    background-color:#F1F1F1 ;
}
.documents_provided_technical_text{
  padding: 10px;  
}
.documents_provided_technical_table{
    margin-bottom: 16px;
    margin-top: 20px;
}
.documents_provided_technical_table_select{
    width: 100%;
}
.observation_text_area {
    resize: none 
}

.document_details_document_image {
    flex: 0 0 11.1%;
    box-sizing: border-box;
    padding: 5px
}

.overall_status_positive {
    color:#52C41A
}

.overall_status_nagative {
    color:red
}
.custom-body-style {
    padding-bottom: 80px;
  }
  


  .technical_views_document_table{
    border-top: 1px solid #e4e7ec;
  }