/* .burea_main_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cibil_main_container {
    width: 48%;
   
}

.cibil_heading{
font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: rgba(0, 0, 0, 1);
}

.application_cibil{
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #e8e8e8; 
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.25) inset;
    box-shadow: -1px -1px 0px 0px rgba(0, 0, 0, 0.25) inset;
}
.cibil{
 font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: left;
}
.light_name{
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: rgba(0, 0, 0, 0.5);
}
.dark_name{
    font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: rgba(0, 0, 0, 1);
}
.burea_number{
    height: 110px; 
    width: 50%;
  
}
.score{
    font-family: Roboto;
font-size: 62px;
font-weight: 600;
line-height: 72.66px;
letter-spacing: 0.02em;
display: flex;
margin-top: 17px;
margin-left: 50px;
color:#87A426

}

.report_button{
    border: 1px solid rgba(24, 144, 255, 1);
    background: rgba(24, 144, 255, 1);
    color: rgba(255, 255, 255, 1);

    width: 50%;

}

.highmark_main_container {
    width: 50%;
}


.vertical_line {
    height: 50%; 
    width: 4px;
    background: linear-gradient(to bottom, #ff0000, #0000ff); 
    margin: 0 auto;} */



.burea_main_container {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.cibil_main_container {
    width: 48%;
}

.bureau_main_block {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #e8e8e8;
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.25) inset;
    box-shadow: -1px -1px 0px 0px rgba(0, 0, 0, 0.25) inset;

}

.cibil_heading {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.firstBlock {
    width: 70%;
}

.cibil {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.light_name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
}

.dark_name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.burea_number {
    height: 110px;
    width: 50%;
}

.score {
    font-family: Roboto;
    font-size: 62px;
    font-weight: 600;
    line-height: 72.66px;
    letter-spacing: 0.02em;
    display: flex;
    margin-top: 17px;
    margin-left: 50px;
    color: #87A426
}

.report_button {
    border: 1px solid rgba(24, 144, 255, 1);
    background: rgba(24, 144, 255, 1);
    color: rgba(255, 255, 255, 1);
    width: 70%;
}

.report_button:hover {
    border: 1px solid rgba(24, 144, 255, 1) !important; 
    background: rgba(24, 144, 255, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    width: 70%;
}


.secondBlock {
    width: 30%;
}
.vertical_line {
    height: 50%;
    width: 4px;
    background: linear-gradient(180deg, #40891A 0%, #87A426 14.48%, #E9C026 34.79%, #D77021 55.1%, #D2260E 100%);
    border: none;
    margin: 0 auto;
    width: 52px;
    height: 292px;
    top: 113px;
    left: 426px;
    gap: 0px;
    border-radius: 2px 0px 0px 0px;
    opacity: 0px;
    margin-left: 80px;
}

.label_container {
    position: absolute;
    left: 370px; /* Adjust to align with the left of the vertical line */
    top: 0;
    width: 100px; /* Width to accommodate the text */
}

.label {
    position: absolute;
    left: 0;
    font-size: 14px;
    color: black;
}



.highmark_main_container {
    width: 50%;

}
 