.televerification_card {
    width: 481px;
    height: 180px;
    top: 12px;
    left: 12px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-bottom: 10px;
}

.tab_televarifican {
    display: flex;
    justify-content: space-between;
}

.image_verify_tele {
    margin-left: 5px;
}

.tele_country_code_block {
    width: 20% !important;
    text-align: center !important;
    background: #f1f1f1 !important;
}

.tele_mobile_number_field {
    width: 80% !important;
}

.tele_button_submit {
    display: flex;
    flex-direction: row-reverse;
}

.tele_div_icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tele_col {
    margin-top: 30px;
}

.tele_div_icon_two {
    font-size: 14px;
    margin-right: 8px;
    font-weight: 600;
}

.edit_televefication {
    background-color: #fff;
    width: 50px;
}

.ioCallOutline_icon {
    margin-right: 3px;
    font-size: 14px;
}

.tele_td {
    vertical-align: top;
}

.badge_complet {
    color: #52C41A;
}

.televerification_card_two {
    display: flex;
    flex-direction: row;
    width: 350px;
}

.televerification_response_box {
    width: 102.01px;
    height: 22px;
    top: 24px;
    left: 25.31px;
    margin: 15px 0px 0px 13px;
}

.televerification_response {
    width:max-content;
    padding: 2px 8px 2px 9px;
    gap: 6px;
    border-radius: 16px;
    /* background: #F6FFED; */
    margin-left: 3px;
}

.more_icon {
    width: 40px;
    height: 29px;
    top: 12px;
    left: 290px;
    margin-left: 310px;
}

.ellipsisoutlined_icon {
    width: 30px;
    height: 16.26px;
    padding-top: 9.17px;
    padding-left: 10px;
    font-size: 30px;
}

.televerification_number {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 22px;
    margin-top: 15px;
    margin-left: -32px;
    margin-right: -32px;
}

.number_first {
    display: flex;
    gap: 10px;
}

.number_second {
    display: flex;
    gap: 10px;
}

.audio_package {
    width: 324px;
    /* height: 49px; */
    left: 13px;
    padding: 0px 14px 0px 0px;
    gap: 12px;
    margin: -34px 0px -21px 0px;
}

.details_of_card {
    width: 298px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.react-audio-player {
    width: 155%;
    max-width: 478px;
    background-color: #ffffff;
    color: #ffffff;
    border-radius: 5px;
    margin-top: 32px !important;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
    background-color: #fff;
}

.call_icon {
    box-sizing: content-box;
    padding: 4px;
    border: 1px solid #e4e7ec;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    color: #1788AF;
    font-size: 13px;
    cursor: pointer;
}

.tele-pd-secondary {
   border-top: 1px solid var(--Conditional-divider, rgba(0, 0, 0, 0.06))
}

.call-time {
    margin-top: 30px;
    margin-left: 20px;
}