.main_container {
  width: 100%;
  height:10%;
  display: flex;
 
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

.case_health_main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
}

.main_container > div {
  margin: 0;
  padding: 0;
}

.costumer_details_sidebar_header {
  margin: 26px 0px 0px 24px;
  border:'1px solid  #e4e7ec'
}


.property_description_sales_container{
  /* border: 1px solid  #e4e7ec; */
  border-top: none;
}

.costumer_details_sidebar_container {
  width: 23%;
  border-top: 1px solid  #e4e7ec;
  border-right: 1px solid  #e4e7ec !important;
   /* border-top: none; */
  margin: 20px 0px 0px 20px;
  /* border-radius: 8px 0px 0px 8px; */
}

.costumer_details_sidebar_header h3 {
  /* border: 1px solid red; */
  color: var(--gray-900, #101828);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0px 0px 47px;
}
.stepper {
  width: 260px;
  height: 683px;
  flex-shrink: 0;
  align-self: stretch;
  /* border: 1px solid red; */
  margin-top: 10px;
  margin: 0px 22px 0px 24px;
}

.document_header {
  /* border: 1px solid  #e4e7ec; */
/* border-top: none; */
  margin: 20px 0px 0px 20px;
  width: 78%;
  /* border-radius: 0px 8px 8px 0px; */
}

.document_header_items {
  /* border: 1px solid red; */
  /* margin:  0px -20px; */
  /* margin-bottom: 20px; */
}
.document_header_items h3 {
  /* border: 1px solid red; */
  color: var(--gray-900, #101828);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 0px 28px 0px 20px;
}

.document_header_items p {
  color: var(--gray-500, #667085);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 20px;
}

/* .ant-tabs-tab .anticon-close {
  display: none;
} */

/* Style the tab container */
.tab-container {
  /* border: 1px solid var(--gray-300, #D0D5DD); */
  width: 225px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-right: -8px;
  border-radius: 4px;
}

/* Style the individual tabs to look like buttons */
.ant-tabs-tab {
  color: #344054;
  border: 1px solid  #e4e7ec;
  padding: 10px 12px 9px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tab-container .ant-tabs-tab:first-child {
  border-radius: 8px 0px 0px 8px;
}

.add-tab-button {
  color: #667085;
  border: 1px solid  #e4e7ec;
  background: var(--White, #fff);
  border-radius: 0px 8px 8px 0px;
  padding: 10px 12px; /* Padding inside the button */
  cursor: pointer;
  transition: background-color 0.2s; /* Smooth transition on hover */
}

.active-tab {
  background-color: #e6f7ff; /* Change the background color to highlight the active tab */
  color: #1890ff; /* Change the text color for better visibility */
  cursor: pointer; /* Add a pointer cursor for active tabs */
}

.property_description_sales_heading {
  border: 1px solid  #e4e7ec;
  background: #e6eff3;
  height: 46px;
  display: flex;
  justify-content: space-between;
}

.property_description_sales_data {
  margin: 8px 16px 16px 16px;
  color: black;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.ant-steps-item-container{
  cursor: pointer !important;
}

.ant-tabs-nav-wrap {
  border-bottom: 1px solid  #e4e7ec;
}

.ant-divider .css-dev-only-do-not-override-3fbh0a .ant-divider-horizontal {
  border-bottom: 1px solid  #e4e7ec !important;
}

:where(.css-dev-only-do-not-override-3fbh0a).ant-divider-horizontal {
  border-bottom: 1px solid  #e4e7ec !important;
}

@media (max-width: 992px) {
  .costumer_details_sidebar_container {
    width: 30%;
  }

  .document_header {
    width: 70%;
  }

  .stepper {
    width: 220px;
  }
}

@media (max-width: 768px) {
  .main_container {
    flex-direction: column;
  }

  .costumer_details_sidebar_container,
  .document_header {
    width: 100%;
    margin: 0;
  }

  .stepper {
    width: 100%;
  }

  .property_description_sales_heading {
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 576px) {
  .property_description_sales_data {
    font-size: 14px;
    font-family: bold;
  }

  .document_header_items h3 {
    font-size: 18px;
  }

  .document_header_items p {
    font-size: 12px;
  }

  .tab-container {
    width: 100%;
  }
}

.timeline_divider{
margin: 44px 0 !important;
}
.divider{
  margin: 0 !important;
}