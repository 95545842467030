@media (max-width: 768px) {
    .calendar-container {
      padding: 0 5px;
    }
  }
  

  /* .custom-table .ant-table-thead > tr > th {
    border-radius: 0 !important;
    width:'100% !important'
  } */