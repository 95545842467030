.pdc_detail_heading {
  background: #E6F7FF;
  border-bottom: 1px solid #E4E7EC;
  height: 46px;
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.heading_details_container_pdc {
  border: 1px solid #e4e7ec;
  border-radius: 5px;

}


.update-cheque-button.disabled {
  background: rgb(133, 132, 132) !important;
  cursor: not-allowed !important;
  color: white !important;
  /* Optional: indicate it's disabled */
}

.update-cheque-button.enabled {
  background: hsla(204, 57%, 36%, 1) !important;
  cursor: pointer !important;
  color: white !important;
}

.select_bank {
  width: 300px;
}

.pdc_details_table_second {
  margin-top: 20px;
}

.pdc_description_data {
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.table_content {
  border: 1px solid #E4E7EC;
  border-top: none;
  border-bottom: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
}

.table_content .ant-table {
  border-radius: 10px !important;
}

.pdc_container {
  margin-top: 15px;
}
.pdc_div{
  /* border: 1px solid #E4E7EC; */
  margin-top: 15px;
  border-bottom: none;
}
.pddc_div_two{
margin-top: 15px;
}

.add_chequ_button_icon {

  background-color: rgb(39, 102, 144) !important;
  color: white !important;

}

.table_content .ant-table-thead>tr>th {
  border-radius: 0 !important;
}

.no-radius-table .ant-table-container {
  border-radius: 10px !important;
}

.add_pdc_bank {
  background-color: rgb(39, 101, 143) !important;
  color: white !important;
}