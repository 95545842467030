.flextabs {
  margin: 0px 15px;
  margin-top: 1.3rem;
  margin-left: 0.9rem;
  display: flex;
  height: var(--Size-Height-controlHeightLG, 34px);
  background-color: #f9fafb;
}

.flextabs ul {
  list-style: none;
  display: flex;
  width: 100%;
}

.flextabs li {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  color: hsla(204, 57%, 36%, 1);
  padding: 8px 12px 8px 12px;
  cursor: pointer;
  text-align: center;
  transition: flex 0.6s ease;
  border-bottom: 1px solid hsla(220, 17%, 91%, 1);
  height: 34px;
  font-weight: 400;
  line-height: 18px;
  background: #fff;
  color: hsla(0, 0%, 32%, 1);
}

.flextabs li.selected {
  flex: 4;
  border-bottom: 1px solid hsla(204, 57%, 36%, 1);
  background: #fff;
  color: hsla(204, 57%, 36%, 1);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 34px;
  font-weight: 600;
  line-height: 18px;
}

.flextabs li:hover {
  flex: 4;
}

/* """""""""""""""""""" */

.loan_flextabs {
  margin: 0px 15px;
  margin-top: 1.3rem;
  margin-left: 0.9rem;
  display: flex;
  height: var(--Size-Height-controlHeightLG, 34px);
  background-color: #f9fafb;
}

.loan_flextabs ul {
  list-style: none;
  display: flex;
  width: 100%;
}

.loan_flextabs li {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  color: hsla(204, 57%, 36%, 1);
  padding: 8px 12px 8px 12px;
  cursor: pointer;
  text-align: center;
  transition: flex 0.6s ease;
  /* border-bottom: 1px solid hsla(220, 17%, 91%, 1); */
  height: 34px;
  font-weight: 400;
  line-height: 18px;
  background: #fff;
  color: hsla(0, 0%, 32%, 1);
}

.loan_flextabs li.selected {
  flex: 4;
  border-bottom: 1px solid hsla(204, 57%, 36%, 1);
  background: #fff;
  color: hsla(204, 57%, 36%, 1);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 34px;
  font-weight: 600;
  line-height: 18px;
}

.loan_flextabs li:hover {
  flex: 4;
}
