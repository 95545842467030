.icici-question-form {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Adds spacing between each question */
  }
  
  .main-question-container {
    margin-bottom: 24px;
  }
  
  .insurance-sub-question-heading {
    font-weight: 500;
    margin-bottom: 8px; /* Spacing between the question and the radio buttons */
    padding-left: 10px; /* Optional for better alignment */
  }
  
  .sub-question-container {
    margin-bottom: 10px; /* Spacing between sub-questions */
    padding: 10px 0; /* Adjusts top and bottom padding for sub-questions */
  }
  
  .remark-input {
    margin-top: 12px; /* Spacing between the radio buttons and the remark input */
    width: 100%; /* Makes input responsive */
    max-width: 400px; /* Limits maximum width */
    margin-bottom: 50px;
  }
  
  .female-customers-note {
    font-style: italic;
    color: #666; /* Gray text for emphasis */
    margin-bottom: 12px;
  }
  
  .details_of_icici{
    display: flex;
    justify-content: space-between;
gap:10%;
    
    
  }
  .non_medical_limit{
    width: 25%;
  }
  .non_medical_limit_two{
    border: 1px solid #D9D9D9;
    margin-top: 1rem;
  }