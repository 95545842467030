
.main_container{
    /* display: flex; */
    width: 100%;
    /* border: 1px solid red; */
    /* overflow-x: auto; */
  }
  
  .credit_box{
    display: flex;
  }
  .credit_table{
    /* width: 590px; */
    flex: 1;
    height: auto;
    left: 17px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
  }
  
  .credit_table .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0px;
  }
  
  .credit_table .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0px;
  }
  
  
  .debit_box{
    display: flex;
  }
  
  .debit_table{
    /* width: 590px; */
    flex: 1;
    height: auto;
    top: 154px;
    left: 745px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    
  }
  
  .debit_table .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
    border-start-end-radius: 0px;
  }
  
  .debit_table .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0px;
  }
  
  .main_credit p{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: 0px;
  text-decoration: underline;
  }
  
  .main_debit p{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 10px;
    text-decoration: underline;
    margin-left: 0px;
  }
  
  .cheque_div{
    width: Fixed (353px)px;
    height: 47px;
    top: 101px;
    left: 742px;
    padding: 12px 0px 0px 0px;
    gap: 4px;
    border-bottom: 1px solid rgb(238, 233, 233);
    opacity: 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    background: #F1F1F1;
    }

  
  .ant-table-thead th {
    background-color: #F1F1F1; /* Your desired background color */
    font-weight: bold; /* Optional: Set the font weight */
    color: #27658F; /* Optional: Set the text color */
  }

  .credit-label {
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .total-row {
    color: #FFA726; 
    font-weight: bold;
  }
  
  
  
  