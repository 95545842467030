
.heading_details_container_otc_div {
  border: 1px solid #e4e7ec;
  border-radius: 5px;
  /* margin: 10px; */
}
.second_heading_details_container {
  background-color:#E6F7FF;
  font-weight: 600;
  padding: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.heading_otc {
  font-size: 18px;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2666666666666666;
  margin-left: 10px;
}

.add_otc_button {
  display: flex;
  justify-content: flex-end;

}

.heading_details_container_otc {
  border: 1px solid #e4e7ec;
  border-radius: 5px;

}

.add_otc_button_icon {

  /* margin-bottom: 10px; */
  background-color: rgb(39, 102, 144) !important;
  color: white !important;

}

.fethc_otc_button_icon {

  background-color: rgb(39, 102, 144);
  color: white;

}
.heading_otc_secnd{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.application_search{
  margin-right: 10px;
}
.OTCPDD_div{
margin-top: 20px;
}
.date_pick{
  width: 100%;
}

.otc_document_path {
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.document_div_otc {

  display: flex;
  flex-wrap: wrap;
  gap: 9px;

}

.edit_button_otc {
  color: #4880FF;
}

.otc_img_add_form {
  margin: 10px;
}

.otc_img_add_form_second_div {
  display: flex;
  flex-wrap: wrap;
}

.otc_form_row {

  margin-bottom: 16px;

}

.fileupload_otc {
  font-size: 48px;
  color: #ff0000;
}

.delete_button_otc {
  color: #E5233DB2;

}

.history_button_otc {
  color: #FF7C03;
}

.document_div_otc_second {
  width: 50px;
  height: 60px;
  overflow: hidden;

}

.otc_pdf_documnet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}



.otc_pdd_table_container {
  width: 100%;
  overflow-x: auto;

 



}

.add_otc_button_submit {

  display: flex;
  flex-direction: row-reverse;
}

.delete_otc_img {
  top: 0;
  right: 0;
  color: #E5233DB2;
  text-align: center;

}

.otc_pdf_contianer {
  /* border: 1px solid red; */
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;

}

.pdf_anchor_tag_otc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border-radius: 4px;
  text-align: center;
  line-height: 100px;
}

.otc_pdf_contianer_second {
  margin: 10px;
  position: relative;
}

.thumbnail__img_otc {

  height: 100px;
  width: 100px;
  object-fit: cover;
}

.pdf_content {
  font-size: 12px;
  color: #ff0000;
}

.filepdoutline_icon {
  font-size: 48px;
  color: #ff0000;
}

.add_otc_image_text {
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.otc_img_container {
  margin-right: 15px;
  position: relative;
}

.otc_image_div_add {
  height: 100px;
  width: 100px;
}