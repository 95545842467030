
.fi_rv_container {
  margin: 20px;
}


.person_Contacted_rv {
  padding: 0px 0px 14px 0px;
  border: 1px solid #e4e7ec;
}
.ov_office{
  margin: 20px;
}
.property_description_heading {
  background-color: rgb(230, 247, 255) !important;
  height: 46px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #e4e7ec;
  border-radius: 5px;

}
.heading_rv_identification_document {
  /* border: 1px solid #E4E7EC; */
 
  /* background: #e6eff3; */
  background-color: rgb(230, 247, 255) !important;

  display: flex;
  justify-content: space-between;

}

.input_fiald_rv {
  margin: 0px 20px 0px 20px;
}

.property_description_heading_two {
  background: #e1e4e9;
  height: 46px;
  display: flex;
  justify-content: space-between;
 
}

.property_description_heading__rv {
  background: #e6eff3;

}

.fi_input_rv_conatiner {
  margin: 20px
}

.property_description_data {
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.property_residenace_description_data {
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.document_resisdence_detail_heading {
  background: #E6F7FF;
  /* border: 1px solid #E4E7EC; */
  height: 46px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1140px) {
  .document_resisdence_detail_heading {
   height: 60px;
    /* border: 1px solid red; */
  }
}

@media (max-width: 530px) {
  .document_resisdence_detail_heading {
   height: 80px;
  
  }

  .property_Identification_data_button{

  
    font-size: 10px;
 
    margin: 7px !important;
  }

}
@media (max-width: 530px) {
  


  .property_description_data_button{
    margin: 7px !important;
  }

}
@media (max-width: 500px) {
  .identification_documnent_add {
flex-direction: column;
  
  }



}

.property_description_data_two {
  margin: 12px 11px 12px 11px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}

.neighbour_button_sumbit {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-bottom: 20px;

}

.slect_rv_asset {
  width: 65% !important;

}

.field-color.single {
  color: #ccc; /* Color for a single field */
}

.field-color.multiple {
  color: #E5233DB2; /* Color for multiple fields */
}

.property_description_basic_card_container {
  display: flex;
  /* border: 1px solid #e4e7ec; */
}

.property_description_basic_card {
  width: 30%;
  margin-bottom: 30px;
}

.property_description_basic_card_label {
  color: #00000080;
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}


.property_description_basic_card_data {
  margin-left: 12px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
}

.fi_description_basic_card_container {
  display: flex;
  /* border: 1px solid #e4e7ec; */
}

.document_details_table {
  /* border: 1px solid red; */
  /* margin: 0px 10px 10px 10px ; */
  padding: 10px 0px 10px 0px;
}

@media (max-width: 850px) {
  .document_container {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.document_container {
  display: flex;
  margin-top: 20px;

}

.document_detail_heading {
  background: #E6F7FF;
  /* border: 1px solid #E4E7EC; */
  height: 46px;
  display: flex;
  justify-content: space-between;
}

.property_description_data_button {
  width: 130px;
  height: 30px;
  margin: 7px 0px 0px 100px;
  padding: 4px 15px 4px 15px;
  gap: 8px;
  border-radius: 4px;
  border: 1px 0px 0px 0px;
  background: rgb(39, 101, 143) !important;
  color: #fff !important;
}



.valuation_Proerty_images_container {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 50%;
  margin-top: 15px;
}


.document_images {
  display: flex;
  flex-wrap: wrap;

}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
}


.image-container {
  flex: 0 0 14%;
  box-sizing: border-box;
  padding: 5px;

}

.image {
  width: 100%;
  /* height: auto; */
  height: 50%;


}

.selct_rv_fomr {
  width: 100%;
}

.delete-icon {

  color: #E5233DB2;
  cursor: pointer;
  text-align: center;

}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  border-top-left-radius: 0px;
  /* border-start-end-radius: 0px; */
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-start-end-radius: 0px;
}

.table_document_rv {
  /* border: 1px solid #E4E7EC; */
border-bottom: none;
}

.identification_toggle {
  cursor: pointer;
  margin: 10px 10px 10px 10px;
}