.valuation_container {
    /* padding-left: 14px;
    padding-right: 14px; */
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    border-radius: 0px !important;

}

.valuation_details_heading {
    /* border: 1px solid gray; */
    border: 1px solid rgba(228, 231, 236, 1) ;
    display: flex;
    justify-content: space-between;
    background: rgba(241, 241, 241, 1);
    /* height: 64px; */
    padding-bottom: 10px;
}


.valuation_property_blue_heading{
    border-bottom:none ;
}
.custom-row-height {
    height: 50px !important; /* Adjust height as needed */
    line-height: 50px !important; /* Center text vertically */
    border: 1px solid pink;
  }

.valuation_details {
    /* border: 1px solid rgba(228, 231, 236, 1); */
    background: rgba(230, 247, 255, 1);
    display: flex;
    justify-content: space-between;
}

.valuation_edit {
    margin-bottom: 10px;
}

.valuation_details_blue {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 10px;
    height: 5px;
}

.document_details_heading {
    /* border: 1px solid gray; */
    /* border: 1px solid rgba(228, 231, 236, 1); */
    display: flex;
    justify-content: space-between;
    background: rgba(241, 241, 241, 1);
    height: 64px;
}

.valuation_details_data_td {
    /* border:1px solid red; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    width: 299px;
    color: rgba(0, 0, 0, 0.7);
    /* border: 1px solid rgba(228, 231, 236, 1); */
}

.valuation_details_data_td_red {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    width: 299px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.7);
}

.valuation_details_data_red {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 1);
    border-top: none;
    /* height: 99px; */
    padding-bottom: 10px;
}

.valuation_data_red {
    color: red;
}

.valuation_details_data_td_heading {
    width: 727px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    color: #667085;
}


.valuation_details_data_td_heading_first {
    width: 400px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 10px;
    margin-left: 10px;
    color: #667085;
}

.valuation_details_data_text_td {
    width: 149px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.7);
   margin: 10px;

}

.valuation_details_data_last {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(250, 173, 20, 1);
    margin: 10px 0px 10px 10px;
}

.value_property_technical {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;

  
}
.value_property {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    /* justify-content: space-between; */
    
}

.value_property_heading {
    width: 300px;
    /* height: 40px; */
   
    margin-top: 10px;
    margin-left: 15px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
}

.value {
    margin-top: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 20px;
}

.value_of_property_data{
    margin-top: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);  
}

.value_of_property_data_remark{
    margin-top: 27px; 
}

.value_second {
    margin-top: 28px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
}


.editbutton {
    height: 15px !important;
    width: 15px !important;
}

.editbutton img {
    height: 15px !important;
    width: 15px !important;
}


/* **************************************** */

.technical_view_button_space{
    display: flex;
    justify-items: center;
}
.technical_edit_button{
    display: flex;
    align-items: left;
    height: 25px;
    margin-top: 8px;
    cursor: pointer;
}

.collapse_icon{
    margin: 12px 16px 16px 10px;
}
.technical_details_blue {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
    height: 20px;
    margin-bottom: 10px;
   
}

.Valuer_certification_checkbox{
    margin-left: 0.5%;
}

.Valuer_certification_checkbox_option{
margin-left: 35px;
color: #000000B2;
}

.Valuer_certification_checkbox_option_first{
    margin-left: 10px;
    color: #000000B2;
    }

.Valuer_certification_declaration{
    margin-bottom: 10px;
}

.technical_property_card{
    margin-bottom: 3rem;
}
.technical_key_table_column{
    color:#E98C17B2 ;
}

.valuation_property_detail_input {
    width:150px;
}

.valuation_property_detail_space {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 50px;
}

.valuation_property_detail_table_div {
overflow-y: auto;
}

.amenities_input {
    width: 100%;
    z-index: 999;
    /* border: 1px solid #000 !important */
}

.valuation_certification_datepicker {
    width: 100%
}
.valuation_certification_row {
    margin-bottom: 16px 
}

.valuation_certification_space {
    display: flex;
    flex-direction: row-reverse
}
.value_property_detail_input {
     margin-top: 1.3rem
}

.value_property_detail_input_second {
    width:80% !important;
}
.value_property_detail_space {
    display: flex;
    flex-direction: row-reverse
}
.valuation_certification_row_input {
    width: 20% !important;
    text-align: center;
    background: #f1f1f1
}

.technical_valuation_of_property_heading{
        display: flex;
        justify-content: space-between;
        background: rgba(241, 241, 241, 1);
        padding-bottom: 10px;
  
}


.technical_views_form_main_container{
    /* padding-left: 14px;
    padding-right: 14px; */
    border-radius: 5px;
    border: 1px solid  #f1f1f1;
   margin-bottom: 15px;
}
