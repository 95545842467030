.hdfc-insured-customer-data {
    margin: 20px;
}

.cover-another-customer {
    margin: 30px !important;
}

.hdfc-select-insured-customer {
    font-size: 14px;
}

.hdfc-select-insured-customer-astrick {
    color: red;
    margin-right: 4px;
}

.hdfc-select-insured-customer {
    width: 100%;
}

.hdfc-cancel-save-button {
    display: flex;
    flex-direction: row-reverse;
    padding: 25px;
}

.row_hdfcinsurance_input {
    margin-bottom: 16px;
}

.select_filed_datepicker_hfdc {
    width: 100% !important;
}

.hdfc_country_code_block {
    width: 20% !important;
    text-align: center !important;
    background: #f1f1f1 !important;
}
.hdfc_country_code_input{
    width: 80% !important;
}
.seletc_hdfc{
    width: 176% !important;
}
.hdfccoberage_image{
    margin: 10px;
}
.hdfccoberage_image_firts{
    display: flex;
    flex-wrap: wrap;
}
.hdfccoberage_row_input{
    margin-bottom: 20px;
}
.hdfccoberage_picture_div{
    margin-right: 15px;
    position: relative;
}
.hdfccoberage_delete{
    color: #E5233DB2;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}
.hdfccoberage_aplicatin_pdf{
    border: none;
}
.hdfccoberage_aplicatin_thumb{
    width: 100px;
    height: 100px;
    cursor: pointer;
}