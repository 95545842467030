.data {
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
    /* border: 1px solid red; */

}
.miss_bold_heading {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 520;
    line-height: 22px;
    text-align: left;
    color: black;
    margin-top: 5px;
}

.miscellaneous_label {
    color: #00000080;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
  }
  
  .miscellaneous_data {
    color: #000000b3;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 0;
  }
  
  .missclannious_container {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #E4E7EC; */
    /* margin: 0 16px 8px; */
    border-top: none;
  
  }
  
  .misscellanious_container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
.misscellanious_card {

    width: 30%;
    margin-bottom: 20px;
  }
  
  .misscellanious_basic_card_content {
    gap: 8px;
    height: auto;
    margin-top: 13px;
    /* border: 1px solid red; */
    padding-top: 3px;
  }
  
  .misscellanious_Basic_card {
    margin: 10px;
    flex: 1 1 20%;
    /* Flex-grow: 1, Flex-shrink: 1, Basis: 30% */
    margin-bottom: 20px;
  
    box-sizing: border-box;
    /* Ensure padding/margin is included in width */
    min-width: 220px;
    /* Set a minimum width to prevent cards from getting too small */
  }
  
  .miscellaneous-data{
    margin-bottom: 430px;
  }

  .sales-recommendation-comment{
    display: block;
  }

  .miscellaneous_main_container{
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #e4e7ec;
  }

  .overall-comments{
    margin: 10px;
    display: block;
  }

  .residence-observations-heading-text{
    background-color: #e6f7ff;
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #e4e7ec;  */
    /* border-top: 1px solid #e4e7ec;Strengths of the Case */
  }
  

  .sales-recommendation-table{
    border-radius: none !important;
    overflow: auto;
  }