.loan_insurance_container {
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    margin: 10px;

}

.Basic_card {
    width: 30%;
    margin-bottom: 20px;

}

.loan_insurance_card {
    /* border: 1px solid red; */
    width: 30%;
    margin-bottom: 20px;
}

.loan_details_heading {
    color: #000000B2;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

}

.nominee_details {

    display: flex;
    flex-direction: row;

}


.basic_card-content {
    gap: 8px;
    height: auto;
    margin-top: 9px;


}

.basic_edit_container {
    display: flex;
    justify-content: flex-end;
    margin-right: 7px;
}

.loan_details_heading {
    border-top: 1px solid #E4E7EC;
    border-bottom: 1px solid #E4E7EC;
    background: #E6F7FF;
    border-right: none;
    font-weight: 600;

    /* height: 46px; */

    padding: 10px;
}

.insurance_table {
    border-radius: none !important;
}

.insurance_table td {
    color: #6E6E6E;
}

.insurance_table thead th {
    color: #667085 !important;
    font-size: 14px;
    border-radius: 0px !important;
    /* line-height: 22px; */
}

.insurance_btn {
    border: 1px solid #1677FF;
    background-color: white;
    color: #1677FF;
}

.tab_form_charges_main_data_table {
    width: 360px;
    padding: 12px;
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    background: #FFF;
    border-bottom: 1px solid #E4E7EC;

}




.loan_details_second_heading {
    display: flex;
    justify-content: space-between;
    /* border:1px solid black; */
    /* width:360px; */
    height: 55px;
    top: 47px;
    left: 1px;
    border: 0px, 0px, 1px, 0px;
    background: rgba(241, 241, 241, 1);


}

.no-border {
    border: none !important;

}


.credit {
    text-align: center;
    font-family: Roboto;
    color: #389E0D;
    /* font-size: 16px; */
    /* font-weight: 500; */
    /* margin: 22px 10px 0 15px; */
    /* padding: 21px 10px 2px 10px; */
    background: #F6FFED;
    border-radius: 16px;
    width: 100px;
    height: 22px;
    top: 12px;
    left: 25px;
}

.credit_red {
    text-align: center;
    font-family: Roboto;
    /* color: rgba(180, 35, 24, 1); */
    color: #B32318;
    background-color: #FEF3F2;

    /* font-size: 16px; */
    /* font-weight: 500; */
    /* margin: 22px 10px 0 15px; */
    /* padding: 21px 10px 2px 10px; */
    background: #F6FFED;
    border-radius: 16px;
    width: 100px;
    height: 22px;
    top: 12px;
    left: 25px;
}

.credit_form_sub_edit {
    display: flex;
    width: 57px;
    padding: 2px var(--Size-Padding-paddingXS, 8px) 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 16px;

    background: rgba(241, 241, 241, 1);

    cursor: pointer;
}

.blue-background {
    background: rgba(230, 247, 255, 1);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    text-align: left;

}


.blue-background td {
    text-align: center;
    margin-bottom: 10px;
}

.details {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 10px;
}










.main_case {
    margin: 10px;
    border: 1px solid #E4E7EC;
}

.psl_details {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.main_heading {
    margin: 20px;
    color: rgba(0, 0, 0, 0.5);
}


.main_data_container {
    display: flex;
    justify-content: space-between;
}
.miss_detail_heading {
    background: #E6F7FF;
    border-top: 1px solid #e4e7ec;
    height: 46px;
    display: flex;
    justify-content: space-between;

  }
  .miss_description_data {
    margin: 12px 11px 12px 11px;
    color: #000000b2;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
   
  }