.form_header span {

    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4880ff;

}

.ant-drawer-header {
    padding: 20px 16px !important;
}

.form_title {
    margin-top: 15px;
    color: var(--gray-900, #101828);
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 146.667% */
    letter-spacing: -0.6px;
}