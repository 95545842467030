.heading_details_container {
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    margin-bottom: 10px;
}

.heading_details_container_tab_operation {
    border: 1px solid #e4e7ec;
    border-radius: 5px;

}

.chequ_conatiner {
    border: 1px solid #e4e7ec;
    border-radius: 5px;
}

.heading_details_container_cheque {
    border-radius: 5px;
}

.cheque_Transaction {
    margin-top: 15px;
    border: 1px solid #e4e7ec;
    border-radius: 5px;
}

.payment_conteianr {
    border: 1px solid #e4e7ec;
}

.heading_text {
    background-color: #e6f7ff;
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    /* border-bottom: 1px solid #e4e7ec; */
}

.heading_text_ov {
    background-color: #e6f7ff;
    font-weight: 600;
    padding: 10px;
    display: flex;
    margin: 10px;
    justify-content: space-between;
    border: 1px solid #e4e7ec;
}

.heading_text.no-border {
    border-bottom: none;

}

.second_heading_details_container {
    background-color: #E6F7FF;
    font-weight: 600;
    padding: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

