.loan_distance_container {
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    margin-bottom: 10px;
}

.credit {
    text-align: center;
    font-family: Roboto;
    color: #389E0D;
    border-radius: 16px;
    width: 100px;
    height: 22px;
    top: 12px;
    left: 25px;
}

.streght_div {

    display: flex;

}

.add_button_strenght {

    margin-top: 5px;
    margin-left: 5px;
}

.godotfill {
    width: 12px;
    margin: -4px 5px 0px 0px;
}

.credit_red {
    text-align: center;
    font-family: Roboto;
    background: #FEF3F2;
    border-radius: 16px;
    width: 100px;
    height: 22px;
    top: 12px;
    left: 25px;
}

.waived_status_div {
    text-align: center;
    font-family: Roboto;
    border-radius: 16px;
    color: #FFA500;
    background: #FFFACD;
    width: 100px;
    height: 22px;
    top: 12px;
    left: 25px;
}

.credit_form_sub_edit {
    display: flex;
    width: 57px;
    padding: 2px var(--Size-Padding-paddingXS, 8px) 2px 6px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border-radius: 16px;

    background: rgba(241, 241, 241, 1);

    cursor: pointer;
}

.blue-background {
    background: rgba(230, 247, 255, 1);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    text-align: left;

}


.blue-background td {
    text-align: center;
    /* margin-top: 2px; */
    margin-bottom: 10px;
}

.distance_details_headings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E1E4E9;
    background-color: #F1F1F1;
    Height: 55px;
    Padding: 8px 20px 8px 20px;
}

.distance_details_heading {
    color: #667085;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    text-align: center;

}

.distance_left_headings {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    width: 100%;
}

.distance_left_heading {
    Padding: 12px 20px 10px 20px;
    height: 47px;
    border-bottom: 1px solid #E1E4E9;
}

.distance_left_heading_last_row {
    Padding: 12px 20px 10px 20px;
    height: 47px;
    /* border-bottom: 1px solid #E1E4E9; */
}


.distance_left_heading_form {
    Padding: 14px 20px 10px 20px;
    height: 73px;
    border-bottom: 1px solid #E1E4E9;
}

.distance_left_heading_form_current_business {
    Padding: 14px 20px 60px 20px;
    height: 54px;
}

.required_asterisk {
    color: red;
}

.sanction_detail_heading {
    background: #E6F7FF;
    color: #000000b2;
    height: 46px;
    display: flex;
    justify-content: space-between;

}

.sanction_description_data {
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #000000b2;

}

:where(.css-dev-only-do-not-override-9gsoip).ant-table-wrapper .ant-table-tbody>tr>td {
    border-bottom: 0px solid #E1E4E9;

}

.credit_heading_text {
    background-color: #E6F7FF;
    font-weight: 600;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.credit_heading_text_decision_questions {
    background-color: #E6F7FF;
    font-weight: 600;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.credit_heading_text.with-border {
    border-bottom: 1px solid #e4e7ec;
}

.credit_heading_text.no-border {
    border-bottom: none;
}

.sanction_condition_content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex: 1 1 auto;
}



.internal-section-edit-button {
    color: #4880FF;
}

.internal-section-delete-button {
    color: #E5233D
}

.decision-questions-edit-button {
    cursor: pointer;
}

.internal-sanction-content {
    display: flex;
    justify-content: space-between;
    background-color: #E6F7FF;
    /* border-bottom: 0px solid #E6F7FF; */
}

.add-sanction-condition-button {
    background-color: #276690 !important;

    margin: 5px 10px !important;
    margin-right: 10px !important;
    color: white !important;
    border: 1px solid #276690 !important;
}

.creditanalysis-table-container {
    overflow-x: auto;
}

.add-sanction-button {
    margin-right: 10px;
}

.external-sanction-content {
    display: flex;
    justify-content: space-between;
    background-color: #E6F7FF;
}

.strength-of-the-case-content {
    background-color: #E6F7FE;
    /* display: flex;
    justify-content: space-between; */
    
}

.strenght_div_conatiner {
    display: flex;
    justify-content: space-between;
}

.strength_div {
    display: flex;
}


.strength-of-the-case-content.no-border {
    border-bottom: none;
}

.toggle-button {
    margin: 10px;
}

.weakness-of-the-case-content {
    background-color: #E6F7FE;
    display: flex;
    justify-content: space-between;
}


.weakness-of-the-case-content.no-border {
    border-bottom: none;
}

.distance-details-content {
    background-color: #E6F7FE;
    display: flex;
    justify-content: space-between;
}

.distance-details-content.with-border {
    border-bottom: 1px solid #e4e7ec;
}

.distance-details-content.no-border {
    border-bottom: none;
}

.distance-details-buttons {
    display: flex;
    gap: 10px;
    margin-right: 10px;

}

.distance-detail-table {
    display: flex;
    min-width: 800px;
    /* Ensures the table has a minimum width */
}

.distance-detail-table-wrapper {
    overflow-x: auto;
    width: 100%;
    /* Ensures the container spans full width */
    /* margin: 10px 0;  */
}

.decision-questions-content {
    background-color: #E6F7FE;
    display: flex;
    justify-content: space-between;
}



.decision-questions-content.no-border {
    border-bottom: none;
}

.decision-question-button {
    margin-right: 10px;
}

.credit-analysis-details-container {
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: auto;
}

.credit_analysis_strength_table {
    margin-bottom: 20px;
    border-bottom: none !important;
}

.button-credit-container {
    display: flex;
    justify-content: space-between;
    width: 234px;
}

.button-credit-container-secondary {
    display: flex;
    justify-content: space-between;
/*    width: 245px;*/
}

.weakness_description_column {
    white-space: normal !important; 
    word-wrap: break-word !important; 
    word-break: break-word !important;
    max-width: 1050px; 
  }
  
  .strength_and_weakness_container{
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .strength_and_weakness_content{
     border: 1px solid #e4e7ec;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: auto;
    margin: 16px;
}