.main_card_first_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -8px;
  padding-top: 10px;
}


.box {
  width: 90px;
  height: 90px;
  margin: 5px;
  border-radius: 1px;
  background: #D9D9D9;

}

.boxs_div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e2e1e1;
  border-radius: 10px;
  width: 360px;
  margin-top: 5px;
}

.doc_main_data_table {
  width: 360px;
}

.second_left_heading {
  width: 219px;
  background: #FFE;

}

.second_main_data_table {
  width: 360px;
  height: 39px;
  background: #FFE;
}

.tab_form_gray_highlighted_row .second_left_heading {
  background: #FAFAFA;

}
.tab_form_main_data_table {
  padding-top: 10px !important;
}



.boxs_div:hover {
  background: #FAFAFA;
}

.documents-image-container {
  border-right: 1px solid #FAFAFA;
}

/* *********************************************** */
.customer_document_main_container {
  border-radius: 8px;
  border: 1px solid #e2e1e1;
 width: 355px !important;
  height: 82px;
  margin: 5px;
}

.customer_document_main_content {
  float: left;
  display: flex;
  height: 50px;
  min-width: 196px;
  width: auto;
  margin: 15px 0px 10px 15px;
}

.customer_document_file_extention {
  float: right;
  width: 70px;
  height: 22px;
  padding: 1px;
  padding: 0px 0px 0px 6px;
  border-radius: 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

}

.customer_document_file_extention.jpeg {
  background: rgba(230, 247, 255, 1);
  border: 1px solid rgba(145, 213, 255, 1);
  color: rgba(24, 144, 255, 1);
  border-radius: 0 6px 0px 6px;
  margin-top: -1px;
  margin-right: -1px;
}

.customer_document_file_extention.pdf {
  border: 1px solid rgba(255, 163, 158, 1);
  color: rgba(245, 34, 45, 1);
  background: rgba(255, 241, 240, 1);
  border-radius: 0 6px 0px 6px;
  margin-top: -1px;
  margin-right: -1px;
}

.customer_document_image {
  height: 50px;
  width: 50px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.05);

}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: rgba(217, 217, 217, 1);
  margin-left: 10px;
}

.customer_document_file_image {
  height: 40px !important;
  width: 40px !important;
}


.customer_document_file_size {
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 16.41px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 5px;
}

.customer_file_documnet_type_name {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-top: 5px;
  margin-left: 10px;
}

.customer_document_file_number{
  font-family: Roboto;
font-size: 14px;
font-style: italic;
font-weight: 500;
line-height: 16.41px;
text-align: left;
color: rgba(0, 0, 0, 0.7);
}

.more-icon {
  font-size: 18px; 
  color: rgba(0, 0, 0, 0.4);
  margin-left: 11px;
  margin-bottom: 5px;
  cursor: pointer;
}

.jpeg-more-icon {
  font-size: 18px; 
  color: rgba(0, 0, 0, 0.4);
  margin-left: 5px; 
  margin-bottom: 5px;
  cursor: pointer;
}

.more-icon:hover {
  color: #007bff;
}

.jpeg-more-icon:hover {
  color: #007bff;
}

.document_popof_main_container{
 width:118px;
height:104px;
padding: 4px 0px 4px 0px;
border-radius: 2px 0px 0px 0px;

box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.25);

box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.30);

box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.35);


}
.icon_content{
  display: flex;
  width: 118px;
height: 32px;
padding: 5px 12px 5px 12px;
}

.icon_content:hover {
  background: rgba(245, 245, 245, 1);
}

.action_icons_button{
  margin-bottom: 3px;
}

.action_button_name{
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
margin-left: 10px;
margin-bottom: 7px;
color: rgba(0, 0, 0, 0.85);

}

