.title{
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    }
    .second_title{
        color: var(--gray-500, #667085);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        margin-top: 10px;
    }
    .basic_form_card{
        border-radius: 8px;
        border: 1px solid #e4d7d757;
    background: var(--gray-25, #FCFCFD);
    margin-top: 10px;
    }
    .basic_form_card_header h3{
    color: var(--gray-900, #101828);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
    margin:20px 24px ;
    }
    .divider {
        border: none;
        border-top: 1px solid var(--gray-200, #E4E7EC); 
        margin: 20px 0;
      }
      .divider1 {
        border: none;
        border-top: 1px solid var(--gray-200, #E4E7EC);
        margin: 20px -25px 10px -25px;
      }
      .basic_form_card_content{
        margin:20px 24px ;
      }
      .red-asterisk {
        color: red;
        margin-left: 5px;
      }
     .padding-less-modal .ant-modal-content{
      padding: 0 !important;
      width: 507.791px;
    height: 267px;
    
     }

     .document_check_files {
      /* display: flex;
      flex-direction: row; */
      /* justify-content: space-between; */
      margin-top: 40px;
      border: 1px solid rgba(208, 213, 221, 1);
      padding: 20px;
     }

     .sales-stepper-margin {
      margin: 0px 22px 0px 24px;
     }

     .ant-steps-item:hover {
      color: rgba(0, 0, 0, 0.45) !important;
     }

     .ant-steps-item .ant-steps-item-wait:hover {
      color: rgba(0, 0, 0, 0.45) !important;
     }

     .ant-steps-item-title:hover {
      color: rgba(0, 0, 0, 0.45) !important;
     }

     div .ant-steps-icon:hover {
      color: rgba(0, 0, 0, 0.65) !important;
     }

     .ant-steps-item-container:hover {
      color: rgba(0, 0, 0, 0.65) !important;;
     }

     .stepper .ant-steps-item-finish .ant-steps-icon {
      display: none; /* Hides the checked icon */
    }

    .sales-tab-navigation {
      display: flex;
      flex-direction: row-reverse;
      height: 50px;
    }

    .document_footer_items {
      position: absolute;  
      bottom: 0;  
      height: 50px;
      padding: 10px;
      display: block;
      margin-top: 0; 
      margin-left: 0; 
      display: flex;
      flex-direction: row-reverse;
      width: 77.3%;
      border-top: 1px solid rgb(228, 231, 236);
    }

    .document_footer_loan_container {
      border-top: 1px solid rgb(228, 231, 236);
      margin-top: 13vw;
    }

    .document_footer_loan_items {
      display: flex;
      flex-direction: row-reverse; 
      position: relative; 
      margin-right: 1vw;
      margin-bottom: 1vw;
      padding-top: 1vw;
    }

    .sales-final-submit {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 30px;
      /* display: block; */
    }
    .property-img {
      display:'flex';
      justify-content: center;
      align-items: center;
     width:50%
    }
    .property_document {
      text-align: center;
      width: 100%;
      margin-left: 5px;
    }

    .delect_property_tab {
      text-align: center;
      width: 100%;
      margin-left: 35px;
    }


    .bottom-button {
      display: flex;
      justify-content: flex-end;
    }

    .sales-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 40px;
    }

    .sales-form-header {
      font-size: 30px;
    }