.customer-detail-address {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
}

.cersai-customer-detail-edit {
  margin-right: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.cersai-customer-detail-edit:hover {
  color: #1890ff;
}

.cersai-property-detail-edit {
  display: flex;
  align-items: center;
  gap: 8px;
}

.property-detail-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  position: relative; /* Added to maintain absolute positioning */
}

.add-cersai-button {
  background-color: #276690 !important;
  white-space: nowrap;
  position: absolute; /* Positioned absolutely within the container */
  right: 0; /* Aligned to the right */
  top: 50%; /* Centered vertically */
  transform: translateY(-50%); /* Precise vertical centering */
}

.customer-detail-table {
  margin-bottom: 24px;
  border: 1px solid #f0f0f0 !important;
  width: 100%;
  overflow-x: auto;
}

.update-address {
  color: red;
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
  .property-detail-heading {
      position: relative;
  }

  .add-cersai-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 150px;
  }
}

@media screen and (max-width: 992px) {
  /* .customer-detail-table {
      font-size: 12px;
  }

  .property-detail-heading h5 {
      font-size: 16px;
  } */

  .add-cersai-button {
      max-width: 120px;
      padding: 4px 8px;
      /* font-size: 12px; */
  }
}

@media screen and (max-width: 768px) {
  /* .customer-detail-address {
      font-size: 12px;
  } */

  .add-cersai-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100px;
      padding: 4px 8px;
      /* font-size: 11px; */
  }
}

@media screen and (max-width: 576px) {
  .add-cersai-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 90px;
      padding: 4px 8px;
      /* font-size: 10px; */
  }
}

/* Ensure horizontal scrolling for tables on small screens */
.ant-table-wrapper {
  overflow-x: auto;
  max-width: 100%;
}