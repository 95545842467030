.loan_details_main_container {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  background-color: #ffffff;
  top: 1017px;
  left: 58px;
  gap: 8px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.heading_loan_details {
  color: hsla(0, 0%, 9%, 1);
  font-family: Roboto;
  font-weight: 700;
  font-size: 24px;
  padding: 15px 0px 3px 20px;
  font-family: Roboto;
  letter-spacing: 0em;
  text-align: left;
}

.btn {
  margin-left: 5px;
  width: 140px;
  border-radius: 6px;
  gap: 8px;
  padding: 8px, 12px, 8px, 12px;
  background-color: #4880ff;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #3366cc;
  color: white;
}

.btn_under {
  margin-left: 0.5rem;
  border: none;
  background-color: #f2f4f7;
  width: 140px;
  transition: background-color 0.3s ease;
}

.btn_under:hover {
  background-color: #d4dce6;
}

.deatails_container {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 10px;
  border-top: none;
}

.btn.clicked {
  background-color: red;
}

.btn_under.clicked {
  background-color: blue;
}

.card-content {
  gap: 4px;
  height: auto;
  margin-top: 9px;
}

.card-content {
  gap: 8px;
  height: auto;
  margin-top: 9px;
}

.label {
  color: #00000080;
  padding: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.card {
  width: 30%;
  margin-bottom: 20px;
}

.data {
  color: black;
  padding-left: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.images {
  width: 100%;
}

.images img {
  width: 33%;
  padding: 4px;
}

.loan_block_heading {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
}

.loan_block_heading_collapse_buttons_block {
  display: flex;
}

.loan_block_heading_collapse_buttons_block_background {

  background-color: #1018281A !important;
  padding: 3px !important;
  border-radius: 6px !important;
  border: 1px solid #FFFFFF !important;
}

@media (max-width: 768px) {
  .loan_block_heading_collapse_buttons_block_background {
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch;
    width: 130px !important;
    text-align: center;
  
  }

  .loan_block_buttons {
    width: 60%;
    text-align: center;
  }
}
.loan_block_buttons {
  border: none;
  margin-right: 8px;
}


.loan_block_buttons.active_sales {
  background-color: hsla(204, 57%, 36%, 1) !important;
  color: #fff !important;
}

.loan_block_buttons.inactive_sales {
  background-color: #E4E7EC !important;
  color: #000 !important;
}

.loan_block_collapse_button {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 12px;
}

.loan_block_content {
  margin: 20px;
}

.loan_block_border_collapse {
  border-bottom: none;
}