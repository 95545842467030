.vehicle_highlighted_row td  {
    border-top: 1px solid #e4e7ec;
    border-bottom: 1px solid #e4e7ec;
    background: #E6F7FF;
    border-right: none;
    font-weight: 600;
}

.vehicle_pd_row td {
    background-color: #f1f1f1;
    border-right: none;
    font-weight: 600;
  }

  .verify_img_vehicle{
    margin-left: 5px;
  }
  .select_vehicle{
    width: 100%;
 
  }
  .all_icon_vehicle{
    display: flex;
    justify-content: flex-end;
  }
