.main_technical_report {
    display: flex;
    justify-content: space-between;
}

.para {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0px !important;
}

.second_para {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    /* text-align: left; */
    color: #AEAEAE;
    margin: 0px !important;


}

.second_para_span {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #00000073;


}
.technicle_data{
    display: flex;
    justify-content: space-between;
    background-color:#FAFAFA ;
  border-bottom: 1px solid #E4E7EC;
}

.second_div {
    display: flex;
    justify-content: flex-end;
}

.seocnd_div_container {
    display: flex;
    justify-content: space-between;
}

.Basic_card_technical {
    width: 100%;
    margin: 0px !important;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid rgb(17, 4, 4); */
}

.basic_card-content_technical {
    gap: 8px;
    height: auto;
    margin-top: 9px;

}

.label_technical {
    color: #00000080;

    margin-top: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;


}

.data_technical {
    color: #000000;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
}

.label_technical_lable {
    color: #00000080;
    padding: 5px;
    margin-left: 10px;
    width: 50%;
    /* margin-top: 10px; */
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    line-height: 25px;
    /* width: 100%; */
    /* border: 1px solid red; */
    background-color: #FAFAFA;
    /* border-bottom: 1px solid #E4E7EC; */
}

.details_contanier {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-left: 12px;
    margin-left: 12px;
}

.details {
    height: 31px;
    margin-top: 10px;
    border-bottom: 1px solid #E4E7EC;

}

.details_p {
    margin: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px; 
}

.details_content_data {
    font-family: Roboto;
    background-color: #FAFAFA;
    font-size: 14px;
    font-weight: 600;
}
.firts_details {
    width: 60%;
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    margin-top: 15px;
}
.second_details {
    width: 80%;
    border: 1px solid #E4E7EC;
    margin-top: 15px;
    border-radius: 5px;
}

.container_technical {
    border-radius: 10px;
}

.valuation_details_blue {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-left: 10px;
    height: 20px;
    margin-bottom: 10px;
}

.technical_valuation_details_blue_heading {
    display: flex;
    justify-content: space-between;
    /* background: rgba(230, 247, 255, 1)!important; */
    border-radius: 0px;
    background-color: rgba(230, 247, 255, 1) !important;

    height: 55px;
}


.address_prporty {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;




}

.tachnical_table {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color:rgba(230, 247, 255, 1);
}

.red_text {
    color: #1890FF;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
    margin-right: 10px;
    text-decoration: underline;

}

.address_text_first {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
}

.address_text {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #667085;

}

.highlighted_red {
    color: #E74C3C;
    text-align: end;
    margin-top: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;



}

.address_top_heading {
    display: flex;
    justify-content: space-between;
}

.address_heading {

    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #667085;

}

.checkbox {
    font-family: Inter;
    font-size: 4px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #9CA7AC;

    margin-left: 15px;
}

.img img {
    /* width: 20%; */
    margin: 10px;
}

.technical_property_container {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.technical_button_container {
    display: flex;
    flex-direction: row;
}

.editbutton {
    height: 15px !important;
    width: 15px !important;
}

.editbutton img {
    height: 15px !important;
    width: 15px !important;
}

.technical_valuation_report_div_width_full {
width: 100%;
margin-top: 20px;
}
.technical_valuation_report_date_width_full {
    width: 100%;

    }
.technical_valuation_report_structre_width_full {
    width: 100%;
    margin-top:10px;
    }
.technical_valuation_report_amentites {
    z-index: 999;
}
.technical_valuation_report_border_radius {
    border-radius: 10px
}


.technical_valuation_report_field_set{
    margin-top: 0px;
}
/* ********************************************* */

/* Technical Report Css */

.technical_report_block{
   border: 1px solid #E4E7EC; 
   margin-top: 15px;
   /* border-radius: 5px; */
}


.technical_report_valuation_property_box{
   border-bottom: none;
}

.technical_valuation_report_Value_of_property_field{
    width: 80% !important;
    
  }
  .trechinical_input_filed{
    margin-top: 20px;
  }
.technical_valuation_report_Value_of_property_amount_field{
width: 20% !important;
text-align: center !important;
background-color: #f1f1f1 !important;
/* margin-top: 21px !important; */
}
.land_value_input{
    margin-top: 20px;
}
.technical_submit_button {
    margin-top: 21px;
}


.technical_valuation_report_rate_land{
    width: 20% !important;
    text-align: center !important;
    background-color: #f1f1f1;
    border: 1px solid red;
}

.technical_report_field_box{
    height: auto !important;
    margin-bottom: 50px !important;
}
.technical_report_address_heading_gray{
    background-color:#F1F1F1 !important  ;
}
.technical_report_address_distance{
    margin-top: 10px !important;
}

.technical_report_address_heading_gray_text{
    display: flex;
    width: 100%;
    justify-content: space-between;
}


.technical_report_address_heading_red_text{
    color: #E74C3C;
    margin-right: 10px;
}
.technical_report_address_checkbox{
    margin-left: 10px !important;
}

.technical_report_location_block_field{
    margin-top: 20px;
    width: 100%;
}

.technical_location_and_property {
    width: 100%;
}

.technical_report_property_documnet_field{
    margin-right: 8px;
    width: 100% ;
    flex: 1;
}

.technical_report_property_documnet_divider{
    margin: 10px 10px 10px 10px;
}

.technical_report_property_documnet_image{
display: flex;
flex-wrap: wrap;

}

.technical_report_property_documnet_image_box{
    margin: 10px;
    position: relative;
}

.technical_report_document_image_path{
    width: 100px;
    height: auto;
}

.technical_report_document_image_delete_icon{
    top: 0;
    right: 0;
    color: #E5233DB2;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}

.technical_report_document_image_lable{
    margin-top: 5px;
    text-align: center;
}


.technical_report_submit_space_button{
    display: flex;
    flex-direction: row-reverse;
    
}

.up-outlined {
    margin: 1rem;
    transition: transform 0.3s ease-in-out; /* Optional for smooth animation */
  }
  
  .up-outlined.rotate {
    transform: rotate(180deg);
  }
  
  .up-outlined.default {
    transform: rotate(0deg);
  }




  /* ****************************** */


  .technical_report_propert_document_upload_field{
    margin-top: 1px;
  }

  .technical_report_boundries_box{
    /* border: 1px solid #E4E7EC !important;  */
    border-top:  1px solid #E4E7EC;
  }

/* ************************* */

.technical_input_form_main_container_block{
        border: 1px solid #E4E7EC; 
        margin-top: 15px;
       border-radius: 5px;
   
     
}



.technical_boundriers_details_blue_heading {
    display: flex;
    justify-content: space-between;
    /* background: rgba(230, 247, 255, 1)!important; */
    border-radius: 0px;
    background-color: rgba(230, 247, 255, 1);

    height: 50px;
}

.technical-input-text-field {
    margin-top: 0px;
  }


 @media only screen and (max-width: 1200px) and (min-width: 1600px) {
    .technical_report_location_block_field {
        margin-top: 0px;
    }

   
}

@media only screen and (max-width: 1200px) and (min-width: 1293px) {
    .technical_report_location_block_field {
        margin-top: 0px;
    }

   
}

@media only screen and (max-width: 1200px) {
    .technical_report_location_block_field {
        margin-top: 20px;
    } 
}


@media only screen and (min-width: 480px) and (max-width: 749px) {
   
    .valuation_details_blue_heading {
        height: 70px !important;
    }

    .technical_report_address_heading_gray_primary {
        height: 70px !important;
    }
}

@media only screen and (max-width: 479px) {
   
    .valuation_details_blue_heading {
        height: 110px !important;
    }

    .technical_report_address_heading_gray_primary {
        height: 110px !important;
    }


    .technical_boundriers_details_blue_heading_primary {
        height: 110px !important;
    } 
}

/* @media only screen and (min-width: 1600px) {
    .technical_location_and_property {
        margin-top: 20px;
    }
} */
 
@media only screen and (min-width: 1188px) and (max-width: 1600px) {
    .technical_location_and_property_tab {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
    .technical_location_and_property_medium {
        margin-top: 20px;
    }
  
}

@media only screen and (min-width: 768px) and (max-width: 883px) {
    .marketability-medium {
        margin-top: 20px;
    }
}
   
/* .technical_valuation_report_form {
    width: 100%;  
    border-radius: 8px;
  } */

  .valuation-input-field {
    width: 125px;
  }


  

  @media only screen and (min-width: 1198px) and (max-width: 1243px) {
    .government-property-tab {
        margin-top: 20px;
    }
}

/* @media only screen and (min-width: 1411px) and (max-width: 1547px) {
    .government-property-tab-mid {
        margin-top: 20px;
    }
} */

@media only screen and (min-width: 787px) and (max-width: 1411px) {
    .government-property-tab-mid-med {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 787px) and (max-width: 1465px) {
    .remark-medium {
        margin-top: 20px;
    }
}
