.label_magma {
    font-size: 14px !important;
    color: black !important;
  }

  .magma-button-container{
    display: flex;
  }
  .nominee_details_container{
    background-color: #F9FAFB;
    color: #565657;
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
  }
  .row_magmainsurance_input{
    margin-bottom: 16px;
  }
  .select_filed_datepicker_magma{
    width: 100% !important;
  }
  .magma_country_code_block {
    width: 20% !important;
    text-align: center !important;
    background: #f1f1f1 !important;
  }
  
  .magma_mobile_number_field {
    width: 80% !important;
  }
  .row_input_form_magma{
    margin-bottom: 16px;
  }
  .magma_picture_div{
    margin: 10px;

  }
  .magma_picture_div_two{
    display: flex;
    flex-wrap: wrap;
  }
  .magma_picture_div_third{
    margin-right: 15px;
    position: relative;
  }
  

  .magma-image-gallery{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .magma-image{
    width: 100px;
    height: 100px;
    border: none;
    cursor: pointer;
  }

  /* Magma Plan Data */

  .Nominee-details-plan-A{
    background-color: #f0f2f5;
  }
  .Nominee-details-plan-A-table{
    overflow-x: auto;
  }

  .Nominee-detail-convalescence-benefit-table{
    overflow-x: auto;
    /* border:2px solid red; */
  }

  .Magma-Insured-Customers-PlanA-Full-Address{
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: auto; 
    margin: 0;
    white-space: normal; /* Allows text to wrap naturally */
    word-break: break-word; /* Ensures long text wraps properly */
    line-height: 1.5; /* Adds spacing between lines */
  }

  
  .Magma-Insured-Customers-PlanA-Full-Address span {
    display: inline-block; /* Ensures each part is inline but can wrap */
    margin-right: 4px; /* Adds spacing between elements */
  }

  .Nominee-detail-whole-table{
    border: none;
  }

  .nominee-detail-PlanA-heading{
    color: #1890ff !important;
    font-size: 15px  !important;
  }

  .Magma-Insured-Customers-PlanA-heading{
    color: #1890ff !important;
    font-size: 18px  !important;
  }

  .Magma-Insured-Customers-PlanA-table{
    margin-top: 20px;
    border-width: 2px;
    border: none;

  }
  .magma_picture_div_views{
    height: 100px;
    width: 100px;
  }
.delete_icon_magma{
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.save_cancle_button_magma{
  display: flex;
  flex-direction: row-reverse;
  padding: 25px;
}
.hidden {
  display: none;
}
.select_uplad_documnet{
  width: 176% !important;
}