.forworad_container {
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
    width: 95%;
}

.button_forword_form {
    display: flex;
    flex-direction: row-reverse;
}

.queries_conatiner {
    position: relative;
    margin-bottom: 16px;
}

.delete_button_sendForm {
    color: red;
    position: absolute;
    right: 0;
    z-index: 1;
    margin-top:40px;
    margin-right: 15px;

}

.forward_form_select{
    width: 100% !important;
}