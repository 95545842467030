.main_vehicle_details_container {
    display: flex; 
    flex-direction: column;
  
    /* width: 97.3%; */
}
.vehicle_deatails_container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E4E7EC;
    /* margin: 0 15px 9px; */
     border-top: none;
     flex-wrap: wrap; 
    
     
}
.row_vehicle_fomr{
    margin-bottom: 16px;
 
}
.select_vehicle{
    width: 100%;
}
.vehicle_button{
    display: flex;
    flex-direction: row-reverse;
}
.vehicle_edit_container {
    display: flex;
    justify-content: flex-end;
    margin-right: 7px;
    margin-top: 8px;
}
.proprty_details_container {
    display: flex;
    justify-content: space-between;
    border-top: none;
    flex-wrap: wrap; /* Allows the cards to wrap to the next line */
 
}
.Vehicle_card {
    flex: 1 1 20%; /* Flex-grow: 1, Flex-shrink: 1, Basis: 30% */
    margin-bottom: 20px;
    box-sizing: border-box; /* Ensure padding/margin is included in width */
    min-width: 220px; /* Set a minimum width to prevent cards from getting too small */
}

.vehicle_card_content {
    gap: 8px;
    height: auto;
    margin-top: 9px;
}
.vehicle_details_label{
    color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.vehicle_details_data{
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
}
.property_images {
    display: flex;
    flex-wrap: wrap;


  }




.document{
    margin-top: 10px !important;
    
}
