.menu_list_container {
  width: 200px;
}

.menu_item svg {
  font-size: 1.2rem;
  padding: 0px;
}

.menu_item span {
  font-size: 1rem;
  padding: 2px 2px;
  font-weight: 400;
}

.menu_item_user {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 5px 10px;
  border-bottom: 1px solid rgba(228, 231, 236, 1);
}

.menu_item_user p {
  font-size: 1rem;
  font-weight: 500;
}

/* used notification logo */

.menu_notification_container {
  width: 23vw;
  height: 50vh;
}
.menu_notification_container {
  width: 23vw;
  height: 50vh;
  overflow-y: auto; /* Enables vertical scrolling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* For Firefox: scrollbar thumb color and track color */
}

/* Webkit-based browsers (Chrome, Safari, etc.) */
.menu_notification_container::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.menu_notification_container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.menu_notification_container::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 10px; /* Optional rounded corners */
}

.menu_notification_container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}


.notification_item {
  display: flex;
  flex-direction: row;
  padding: 10px 0px 5px 10px;
}

.main_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky; /* Ensures it stays at the top */
  top: 0; /* Keeps it stuck to the top */
  z-index: 10; /* Ensures it stays above other elements */
  background-color: #1890ff;
  color: #fff;
  padding: 8px 16px; /* Adds spacing inside the header */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds subtle shadow for distinction */
  width: 34vw; /* Matches the specified width */
}


h4 {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));

  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 5px;
}

.heading {
  color: #1890ff;
  cursor: pointer;
  /* Body/regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-right: 5px;
}

.icon_heading {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.center_paragraph {
  margin-left: 2rem;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.btn {
  margin-left: 2rem;
}

.icon_heading {
  margin-left: 5px;
}
.image {
  margin-top: 10px;
}

.accept_button {
  width: Fixed (68px);
  height: Hug (34px);
  top: -175px;
  left: 4890px;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
 
  background: #52c41a;
}

.declient_button {
  width: Fixed (68px);
  height: Hug (34px);
  top: -175px;
  left: 4890px;
  padding: 10px;
  border-radius: 4px;
 
  border: 1px solid #7b6f72;
}

/* -----------used in sidebarlogo---------- */
.uncollapsed-column {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  padding: 8px 10px;
  margin-left: 10px;

  border-right: 1px solid rgba(240, 240, 240, 1);
}
.collapsed-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px;

  border-right: 1px solid rgba(240, 240, 240, 1);
}



.layout-icons{
    width: 1.3rem;
    height: 1.3rem;
}
/* Example CSS */
.menu-item {
  display: flex;
  align-items: center;
}

.menu-item .ant-menu-item-link {
  flex: 1;
}

.collapsed .ant-menu-item {
  text-align: center;
}

.uncollapsed .ant-menu-item {
  display: flex;
  align-items: center;
}

.sidebar-logo {
  width: 24px;
  height: 24px;
  margin: 16px;
}

.sidebar-text {
  font-size: 12px;
  margin-left: 10px;
}

.collapsed-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.uncollapsed-column {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.header_first_div {
  width: 75%;
  display: flex;
  margin-right: 40px 
}

.header_first_div_img {
  margin-left: 25px;
  width: 25px;
  height: auto;
  margin-bottom: 10px;
}

.header_first_div_menu {
  border-radius: 0px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  min-width: 200px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0,
}

.drawer_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2.5rem;
}
.dropdown_img {
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 10px
}

.dropdown_div {
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #802C2C;
  display: flex;
  justify-content: center;
  align-items: center
}
.dropdown_span_first {
  font-weight: bold;
  font-size: 16px;
  color: #f0f0f0
}

.dropdown_span_second {
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap; 
}

@media (max-width: 598px) {
  .dropdown_span_second {
    font-size: 15px; /* Slightly smaller font size for smaller screens */
   
    font-weight: bold;
    word-break: break-word; 

  }
 
}
@media (max-width: 768px) {
  .dropdown_img {
    max-width: 50px; /* Set a smaller size for smaller screens */

    height: 50px; /* Maintain aspect ratio with a square container */
  }
}
@media (max-width: 450px) {
  .imf_div {
  flex-direction: column;
  margin-top: 15px;
font-size: 8px;

  }

  .dropdown_span_second{
   
    margin-top: -10px !important;
  }
}

@media (max-width:700px) {
  .dropdown_span_second {
    font-size: 12px; /* Slightly smaller font size for smaller screens */
   
    font-weight: bold;
    word-break: break-word; 

  }

}


/* Custom Scrollbar for the entire layout */
.layout_scroll {
  overflow: auto; /* Enable scrolling */
}

/* Scrollbar styling */
.layout_scroll::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.layout_scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded edges */
}

.layout_scroll::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7); /* Darker on hover */
}

.layout_scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Track background color */
  border-radius: 4px;
}
.dropdown_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.dropdown_div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.dropdown_span_first {
  color: #555;
}

.dropdown_span_second {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .dropdown_img {
    width: 30px;
    height: 30px;
  }

  .dropdown_div {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .dropdown_span_second {
    font-size: 14px;
    margin-left: 5px;
  }
}

@media (max-width: 480px) {
  .dropdown_span_second {
    display: none; /* Hide the name on smaller screens */
  }
}
