  .adhoc_content{
    padding: 10px 0px;
  }
  .adhoc_heading {
    height: 46px;
    display: flex;
    justify-content: space-between;
  }
  .adhoc_sub_heading {
    border: 1px solid #E4E7EC;
    background: #e6f7ff;
    height: 46px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .adhoc_data {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
  }
  

  .adhoc_sub_data{
    margin: 12px 11px 12px 11px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }
  .adhoc_data_button{
    width: 150px;
    height:30px;
    margin: 7px 10px 0px 100px;
    padding: 4px 15px 4px 15px;
    gap: 8px;
    border-radius: 4px;
    border: 1px 0px 0px 0px;
    background: #F0AD4E;
    color: #fff;
    }

/* ************************************ */


.adhoc_enquiry_details_box{
  display: flex;
}

.adhoc_cibil_details_form{
  margin-top: 20px;
}

.adhoc_consumer_details_date{
  width: 100%;
}

.adhoc_document_details_divider{
  margin: 10px 10px 10px 10px;
}

.adhoc_document_details_divider_line{
  display: flex;
  flex-wrap: wrap;
}

.adhoc_document_details_imgae{
margin-right: 15px !important;
position:relative !important;
}

.adhoc_document_details_image_path{
  width: 100px;
  height: 100px;
}

.adhoc_document_details_image_delete_content{
  color: #E5233DB2;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}

.adhoc_cibil_details_space_button{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

/* ***************************************** */

/* ScoreTable */

.score_table_container{
  padding: 20px 0px;
  margin-top: 10px;
  font-family: Arial, sans-serif ;
}

.score_table_heading{
  text-align: center;
  color: #27658F;
}

.score_table{
  width: 100%;
  border-collapse: collapse;
}

.score_table_heading{
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  color: #333;
  text-align: center;
}

.score_table_data{
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
