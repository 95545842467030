.Address_text {
    height: 39px;
    top: 313px;
    padding: 8px 16px 8px 15px;
    gap: 4px;
    /* border: 0px 0px 1px 0px; */
    background-color: "#F1F1F1";


}

.Address_text_rcu {
    height: 39px;
    top: 313px;
    padding: 8px 16px 8px 15px;
    /* border: 0px 0px 1px 0px; */
    background-color: #F1F1F1  !important;
    margin-top: 20px;
    border-bottom: 1px solid #D9D9D9;


}
.Address_text_rcu_star{
    color: #E74C3C;
}
.select_rcu_inition{
    width: 175px !important;
}
.rcu_image_pdf{
    cursor:  pointer !important
}
.intiate_cancle_button{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}
.deletc_rcu_initition{
    margin-top: 5px;
    margin-bottom: 5px;
}
.rcu_text_image{
    text-align: center;
  
}
.uploaded_files_rcu{
    display: flex;
    gap: 10px;
    margin-left: 10px;
    flex-wrap: wrap;
}
.rcu_inaitin_im{
    position: relative;
}
.file_uplaod_icon_rcu{
    font-size: 64px;
}
.rcu_initiotion_document_table{
    display: flex;
    flex-direction: row;
}
.rcu_contnet{
    margin-top: -20px ;
   
}

.content_rcu_block {
    display: flex;
    flex-direction: row;
  }
  .email_rcu_heading {
    width: 23% !important;
    padding: 10px;
    /* color: #000000B2; */
    border-right: 1px solid #D9D9D9;
  }

.rcu_inition_container {

    overflow-x: hidden !important;
}

.rcu_div_container {
    border: 1px solid #D9D9D9;
    border-radius: 9px;
    /* margin-top: 20px; */
    /* border: 1px solid red; */
    margin: 20px;
}

.Rcu_heading {

    color: #000000b2;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;

}

.rcu_inition_input_row {
    margin-top: 15px;
 
    width: 77%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}
.rcu_inition_first_input_row {
    margin-top: 15px;

}


.custom-table {
    /* border: 1px solid #ddd; */

    /* width:1159px ; */
    /* margin: 0px 15px 0px 15px; */
    border-collapse: collapse;
    margin: 0px 0px 0px 0px !important;
}

.source-column {
    /* width: 190px; */
    width: 240px;
    /* margin: 10px; */
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;

    line-height: 22px;
    margin: 0px !important;



}

.rcu_source_column {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
}

.rcu_source_column_two {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    margin-left: 15%;
}

.rcu_source_column_third {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    margin-right: 15%;

    margin-left: 3%;
}

.heading_rcu_table {
    display: flex;
    /* justify-content: space-between; */
}

.source_column_two {
    /* width: 190px; */
    width: 298px;
    margin: 10px;
    font-family: Roboto;

    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

}

.table_data {
    display: flex;
    border-top: 1px solid #0000000F;
    /* justify-content: space-between; */

}

.table_data_body {
    width: 30%;
    margin: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000B2;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table_data_body_two {
    width: 50%;
    /* margin: 10px; */
    margin-top: 10px;
    margin-left: 25px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #000000B2;



}




.rcu_table_heading {
    display: flex;
    background-color: #f2f2f2;
    color: #667085;


}

.document_sample_container {

    /* display: flex; */
    flex-direction: row;
    border-bottom: 1px solid #0000000F;
    margin-left: 13px;
    margin-right: 13px;
}

.documnet_first {

    /* width: 28%; */
    padding: 8px;
}

.documnet_second {

    /* width: 29%; */
    padding: 8px;
    margin-left: 9px;
}

.documnet_third {
    /* width: 45%; */
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1 calc(33.3333% - 10px);
    /* Adjust the width as needed */
}

.img_document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
    width: 130px;
    /* Adjust the gap between the image and dropdown as needed */
}
.img_document_two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
    width: 50px;
    /* Adjust the gap between the image and dropdown as needed */
}


.img_documnet img {
    max-width: 100%;
    /* Ensures the image fits within the container */
    height: auto;
    /* Maintains the aspect ratio of the image */
}

.img_documnet .ant-form-item {
    width: 100%;
    /* Ensures the dropdown spans the full width of the container */
}


.source_column_doc {
    /* width: 190px; */
    margin: 10px;
    padding-left: 10px;
    padding-right: 15px;
    width: 410px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.source-column-div {
    /* width: 190px; */
    /* width: 240px; */
    padding: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.document-image-container {
    display: flex;
    flex-direction: row;
}

.document-image-container {
    display: flex;
    flex-wrap: wrap;
}

.uploaded-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.files {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.loan_fiinition {
    display: flex;
    justify-content: space-between;
    background-color: #E6F7FF;
    height: 45px;
    /* padding: 6px; */
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: 1px solid #0000000F;
    padding-top: 7px;
    padding-left: 13px;
    padding-right: 13px;
}



:where(.css-dev-only-do-not-override-v42bz).ant-upload-wrapper.ant-upload-picture-card-wrapper,
:where(.css-dev-only-do-not-override-v42bz).ant-upload-wrapper.ant-upload-picture-circle-wrapper {
    width: 30%;
}

:where(.css-dev-only-do-not-override-v42bz).ant-drawer .ant-drawer-body {
    overflow-x: hidden;
}

.rcu-doc-title {
    margin-bottom: 0 !important;
}

.initiation_form_table_one .initiation_form_table_data:last-child {
    border-bottom: none;
}
.select_rcu_initin{
    width: 100% !important;
}
.rcu_files_two{
    margin-left: 10px;
    margin-bottom: 25px;
}