.partner_tab_key_paramert {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partner_tab_verify {
    margin-left: 5px;
}

.partner_heaidng {
    display: flex;
    justify-content: flex-end;
}

.partner_select_filed {
    width: 20%;
    text-align: center;
    background-color: #f1f1f1;
}

.partner_salect_feilad {
    width: 100%;
}