.fee_details_container{
    display: flex;
    justify-content: space-between;
    border-top: none;
    /* flex-wrap: wrap; */
   /* padding-left: 10%; */


   
 
}

.loan_insurance_details_container {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #E4E7EC; */
    /* margin: 0 16px 8px; */
    border-top: none;

}

.Basic_card {
    flex: 1 1 20%; 
    margin-bottom: 20px;
    box-sizing: border-box; 
    min-width: 220px; /* Set a minimum width to prevent cards from getting too small */
}

.basic_card_content {
    gap: 8px;
    height: auto;
    margin-top: 9px;
}
.basic_details_label{
    color: #00000080;
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}
.basic_details_data{
    color: rgba(0, 0, 0, 0.7);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-top: 5px;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
}


.basic_edit_container {
    display: flex;
    justify-content: flex-end;
    margin-right: 7px;
    margin-top: 8px;
}

.tab_from_heading_edit_btn {
    width: 60px;
    height: 22px;
    padding: 2px 8px;
    border: 1px solid rgb(187, 186, 186);
    border-top: none;
}

