.legal_initiate_input_main_form{
    padding: 10px 10px 10px 10px;
}

.legal_initiate_input_advocate_details{
    border:1px solid #E4E7EC;
    margin-top: 20px;
    /* border-bottom: none; */
    margin-bottom: 10px;
}


.legal_initiate_input_other_provisions{
  border:1px solid #E4E7EC;
  margin-top: 10px;
  /* border-bottom: none; */
  margin-bottom: 10px;
}

.legal_initiate_input_pdf{
    width: 100px;
    height: 50px;
}
.legal_initiate_input_pdf_path{
    width: 100px;
    height: 50%;
}


.legal_initiate_input_image_view{
    top: 0;
    right: 0;
    color: #E5233DB2;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
}
.scrutiny_Column_Edit_Button{
    color: #4880FF;
}

.legal_input_as_per_document{
    margin-top: 20px;
}
.legal_input_as_per_document_date{
    width: 100%;
}

.legal_initiat_input_custom_textarea {
    resize: none; 
    width: 100%; 
  }

.legal_initiat_input_delete_icon {
    color: #e5233db2;
    cursor: pointer; 
  }

  .legal_initiat_input_delete_icon-disabled {
    color: #ccc; 
    margin-bottom: 25px;
    cursor: not-allowed; 
  }


.scrutiny_Column_Delete_Button{
     color: #E5233DB2;
}

.scrutiny_main_container{
    margin-top: 20px;
    margin: 0px;
}

.scrutiny_add_button_space{
    display: flex;
}

.scrutiny_collapse_button{
    margin: 10px 10px 10px 10px;
    cursor: pointer;
}

.scrutiny_table{
border: 1px solid #E4E7EC;
border-bottom: none;
}

.legal_initiate_input_upload_form{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.legal_initiate_input_upload_form_image{
    margin: 10px;
    position: relative;
}

.Conclusion_main_container{
 border: 1px solid #E4E7EC;
 margin-top: 20px;
}

.Conclusion_content{
margin: 10px;
}

.legal_input_form_property_decription_layout{
  margin: 10px 10px 0px 10px;
}

.conclusions-quill-editor {
    height: 130px;
    border: 1px solid #d9d9d9;
    border-radius: 2px; 
  }
  
  .conclusion_Remark_label{
    color: #00000080 ;
  }
  .conclusion_Remark_checkbox{
    margin-right: 8px;
  }
  
  .conclusion_initiate_input_form_space{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }

.delete-icon {
    color: #e5233db2;
    margin-bottom: 25px;
    cursor: pointer; 
  }

  .delete-icon-disabled {
    color: #ccc;
    margin-bottom: 25px;
    cursor: not-allowed; 
  }

  .postDisbursmentDocumnet{
    resize: none;
    width: 100%;
  }
  

  .legal_input_as_per_document_heading{
    background: #e1e4e9;
    height: 46px;
    display: flex;
    justify-content: space-between;
    border:1px solid red !important;
  }

  .legal_input_table_heading_box{
    border-bottom: none !important;
    /* border: 1px solid green; */
  }



  
  .legal_input_block_gap_heading_gap{
    background-color: rgb(230, 247, 255) !important;
    height: 46px;
    display: flex;
    justify-content: space-between;
  }

  .legal_input_collapse_button{
    margin-right: 15px;
  }

 .legal-form-datepicker {
  width: 100%;
 }
 
 .technical-filesize {
  font-size: 50px;
  height: 50px;
  width: 100px;
  display: flex;
  justify-content: center;
 }
  