
.tabsContainer {
  gap: 1rem; /* Adjust this value as needed */
}

.meetingsContainer {
    max-height: 300px; /* Adjust height as needed */
    overflow-y: auto;
    padding-right: 10px; /* Prevent content cut-off if scrollbar is hidden */
  }
  
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .meetingsContainer::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .meetingsContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;     /* Firefox */
  }
  

.meetingCard {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #C2EECC;
    border-radius: 10px;
    background-color: #f0f9f0;
    width: 100%;
    font-family: Arial, sans-serif;
    margin-bottom: 18px;
  }
  
  .greenBackground {
    background-color: #C2EECC;
  }
  
  .blueBackground {
    background-color: rgb(191, 211, 236);
  }
  .meetingTitle {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .statusDot {
    width: 8px;
    height: 8px;
    background-color: green;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .meetingTime {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 0.9em;
  }
  
  .clockIcon {
    margin-right: 5px;
  }
  